import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Bridging.module.scss';
import { Button } from 'react-bootstrap';
import arrowDown from '../../assets/imgs/bridge/arrow.png';

import GoldTicketImg from '../../assets/imgs/ticket/gold_ticket_large.png';
import SilverTicketImg from '../../assets/imgs/ticket/silver_ticket_large.png';
import BronzeTicketImg from '../../assets/imgs/ticket/bronze_ticket_large.png';

import gold from '../../assets/imgs/bridge/gold.png';
import silver from '../../assets/imgs/bridge/silver.png';
import bronze from '../../assets/imgs/bridge/bronze.png';

import IconPolygon from '../../assets/imgs/bridge/polygon-matic-logo52x52.png';
import IconBinance from '../../assets/icons/BSC_network.png';

import ethereum from '../../assets/imgs/bridge/ethereum-logo52x52.png';
import bridge from '../../assets/imgs/bridge/bridge.png';
import infoicon from '../../assets/imgs/bridge/info-icon.png';
import { Link } from 'react-router-dom';
import backBtn from '../../assets/icons/arrow_back_ios_24px.svg';
import { Image } from 'react-bootstrap';

import { returnRarityImage, skinMap } from '../Skins/utils';

const Bridging = (props) => {
    const [errorMsg, setErrorMsg] = useState();
    const { t } = useTranslation();

    const {
        chests,
        maxChest,
        chestType,
        networkId,
        NetInfo,
        setBridgeConfirm,
        setChests,
        setTickets,
        tickets,
        maxTicket,
        ticketType,
        maxSkinCount,
        setSkinCount,
        skinCount,
        skinType,
        submitProcessing,
        switchNetwork
    } = props;

    const maxChestHandler = () => {
        setErrorMsg('')
        setChests(maxChest);
    }

    const maxTicketHandler = () => {
        setErrorMsg('')
        setTickets(maxTicket);
    }

    const maxSkinHandler = () => {
        setErrorMsg('')
        setSkinCount(maxSkinCount);
    }

    const handleSubmit = async () => {
        setBridgeConfirm(true);
        setErrorMsg('')
    }

    const handleChestChange = e => {
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?A-Za-z]+/;
        setChests(e.target.value)
        if(maxChest < e.target.value) {
            setErrorMsg(`No. of chest cannot exceed ${maxChest}`);
        }else if(format.test(e.target.value)) {
            setErrorMsg('Amount must be a positive integer greater than 0')
        }
         else {
            setErrorMsg('')
            setChests(e.target.value)
        }
    }

    const handleTicketChange = (e) => {
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?A-Za-z]+/;
        setTickets(e.target.value)
        if(maxTicket < e.target.value) {
            setErrorMsg(`No. of ticket cannot exceed ${maxTicket}`);
        } else if(format.test(e.target.value)) {
            setErrorMsg('Amount must be a positive integer greater than 0')
        }
         else {
            setErrorMsg('')
            setTickets(e.target.value)
        }
    }

    const handleSkinChange = (e) => {
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?A-Za-z]+/;
        setSkinCount(e.target.value)
        if(maxSkinCount < e.target.value) {
            setErrorMsg(`No. of skin cannot exceed ${maxSkinCount}`);
        } else if(format.test(e.target.value)) {
            setErrorMsg('Amount must be a positive integer greater than 0')
        }
         else {
            setErrorMsg('')
            setSkinCount(e.target.value)
        }
    }

    const renderTicketImageAndText = (ticketType) => {
        return (
            <>
                <div className={styles.ticket_image}>
                    {(ticketType === "gold") && <Image src={GoldTicketImg} width="128" />}
                    {(ticketType === "silver") && <Image src={SilverTicketImg} width="128" />}
                    {(ticketType === "bronze") && <Image src={BronzeTicketImg} width="128" />}
                </div>
                <div className={styles.ticket_text}>
                    {(ticketType === "gold") && 'Gold Ticket'}
                    {(ticketType === "silver") && 'Silver Ticket'}
                    {(ticketType === "bronze") && 'Bronze Ticket'}
                </div>
            </>
        );
    }

    const renderSkinImageAndText = (skinType) => {
        let skinImage;
        let skinName;
        let skinRarity;

        Object.keys(skinMap).forEach((keyName) => {
            if(keyName === skinType) {
                skinImage = skinMap[keyName]['img'];
                skinName = skinMap[keyName]['name'];
                skinRarity = skinMap[keyName]['rarity'];
            }
        });

        const rarityImage = returnRarityImage(skinRarity);

        return (
            <>
                <div className={styles.skin_image}>
                    <Image src={skinImage} width="128" />
                </div>
                <div className={styles.skin_text}>
                    {skinName}
                </div>
                <div className={styles.rarity_image}>
                    <Image src={rarityImage} />
                </div>
            </>
        );
        
    }

    const renderChestBridgingContent = () => {
        return (
            <div className={styles.content_wrapper}>
                <div className={styles.bridging_box}>
                    <div className={styles.title_section}>
                        <div className={styles.header_text}>{t('CHOOSE_AMOUNT')}</div>
                        <div className={styles.header_subtext}>{t('BRIDGE_TOWER_CHEST_INFO')}</div>
                    </div>
                    <div className={styles.body_section}>
                        <div>
                            <div className={styles.title_text}>{t('FROM_NETWORK')}</div>
                            <div className={styles.chest_wrapper}>
                                <div className={styles.chest_box}>
                                    {chestType === "gold" && (
                                        <img className={styles.gold_icon} src={gold} />
                                    )}
                                    {chestType === "silver" && (
                                        <img className={styles.gold_icon} src={silver} />
                                    )}
                                    {chestType === "bronze" && (
                                        <img className={styles.gold_icon} src={bronze} />
                                    )}
                                    <input type="number"
                                        className={styles.input_from}
                                        // placeholder={t('PLACEHOLDER_TO_TRANSFER')}
                                        placeholder="0"
                                        onChange={handleChestChange}
                                        value={chests ? chests : ''}
                                        onKeyDown={(evt) =>
                                            (evt.key === "e" || evt.key === "E" || evt.key === ".") &&
                                            evt.preventDefault()
                                            }
                                    />
                                    <div className={styles.max} onClick={maxChestHandler}>{t('BTN_MAX')}</div>
                                </div>
                                <div className={styles.polygon_box}>
                                    <div className={styles.network_details}>
                                        <div className={styles.network}>Network</div>
                                        <div className={styles.network_name}>Ethereum</div>
                                    </div>
                                    <div className={styles.ethereum_network_icon}>
                                        <img src={ethereum} alt="ethereum" />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <div className={styles.balance_text}>{t('AVAILABLE_BALANCE_LABEL')}: 
                                    <span className={styles.maxValue}>{maxChest}</span>
                                </div>
                                <div className={styles.errorMsg}>
                                    <span>{errorMsg}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.arrow}>
                            <img src={arrowDown} />
                        </div>
                        <div>
                            <div className={styles.title_text}>{t('TO_NETWORK')}</div>
                            <div className={styles.chest_wrapper}>
                                <div className={styles.chest_box_to}>
                                    {chestType === "gold" && (
                                        <img className={styles.gold_icon} src={gold} />
                                    )}
                                    {chestType === "silver" && (
                                        <img className={styles.gold_icon} src={silver} />
                                    )}
                                    {chestType === "bronze" && (
                                        <img className={styles.gold_icon} src={bronze} />
                                    )}
                                    {/* <input type="text" className={styles.to_value} value={chests ? parseInt(chests) : 0} disabled /> */}
                                    <div className={styles.to_value}>{chests ? parseInt(chests) : 0}</div>
                                </div>
                                <div className={styles.polygon_box}>
                                    <div className={styles.network_details}>
                                        <div className={styles.network}>{t('NETWORK')}</div>
                                        <div className={styles.network_name}>{t('POLYGON')}</div>
                                    </div>
                                    <div className={styles.polygon_network_icon}>
                                        <img src={IconPolygon} alt="polygon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {networkId !== 1 && networkId !== 5 &&
                            <div className={styles.switch_network}>
                                <span className={styles.switch_text}>{t('SWITCH_NETWORK_MSG')}</span>
                            </div>
                        }
                        {networkId !== 1 && networkId !== 5 ?
                            <Button className={styles.bridge_button} onClick={() => {switchNetwork(NetInfo)}}>{t('SWITCH_BTN')}</Button> :
                            (chests < 1) || errorMsg.length !== 0 ?
                                <Button className={styles.preview_button} disabled={chests < 1}>{t('PROCEED_TO_PREVIEW')}</Button>
                                : submitProcessing ?
                                    <Button className={styles.bridge_button} onClick={handleSubmit} disabled>
                                        <img src={bridge} className={styles.bridge_icon} />
                                        {t('PROCESSING')}
                                    </Button> :    
                                    <Button className={styles.bridge_button} onClick={handleSubmit}>
                                        <img src={bridge} className={styles.bridge_icon} />
                                        {t('BRIDGE_POLYGON_BTN')}
                                    </Button>
                        }
                    </div>
                    {chests > 0 && (
                        <div className={styles.info_transfer}>
                            <img src={infoicon} className={styles.info_icon} />
                            <div className={styles.info_text}>
                                {t('IT_WILL_TAKE')} <span className={styles.strongtext}>{t('7_15_MINS')}</span> {t('TO_PROCEED_TRANSFER')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const renderTicketBridgingContent = () => {
        return (
            <div className={styles.content_wrapper}>
                <div className={styles.bridging_box}>
                    <div className={styles.title_section}>
                        <div className={styles.header_text}>{t('BRIDGE_TICKETS')}</div>
                        <div className={styles.header_subtext}>{t('BRIDGE_TOWER_TICKETS_INFO')}</div>
                    </div>
                    <div className={styles.body_section}>
                        <div>
                            { renderTicketImageAndText(ticketType) }
                            <div className={styles.title_text}>{t('FROM_NETWORK')}</div>
                            <div className={styles.ticket_wrapper}>
                                <div className={styles.ticket_box}>
                                    <input type="number"
                                        className={styles.input_from}
                                        placeholder="0"
                                        onChange={handleTicketChange}
                                        value={tickets ? tickets : ''}
                                        onKeyDown={(evt) =>
                                            (evt.key === "e" || evt.key === "E" || evt.key === ".") &&
                                            evt.preventDefault()
                                        }
                                    />
                                    <div className={styles.max} onClick={maxTicketHandler}>{t('BTN_MAX')}</div>
                                </div>
                                <div className={styles.polygon_box}>
                                    <div className={styles.network_details}>
                                        <div className={styles.network}>{'Network'}</div>
                                        <div className={styles.network_name}>{'BNB'}</div>
                                    </div>
                                    <div className={styles.bsc_network_icon}>
                                        <img src={IconBinance} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <div className={styles.balance_text}>{`${t('AVAILABLE_BALANCE_LABEL')}:`} 
                                    <span className={styles.maxValue}> { maxTicket } </span>
                                </div>
                                <div className={styles.errorMsg}>
                                    <span>{errorMsg}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.arrow}>
                            <img src={arrowDown} />
                        </div>
                        <div>
                            <div className={styles.title_text}>{t('TO_NETWORK')}</div>
                            <div className={styles.ticket_wrapper}>
                                <div className={styles.ticket_box_to}>
                                    <div className={styles.to_value}>{tickets ? parseInt(tickets) : 0}</div>
                                </div>
                                <div className={styles.polygon_box}>
                                    <div className={styles.network_details}>
                                        <div className={styles.network}>{t('NETWORK')}</div>
                                        <div className={styles.network_name}>{t('POLYGON')}</div>
                                    </div>
                                    <div className={styles.polygon_network_icon}>
                                        <img src={IconPolygon} alt="polygon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {networkId !== 56 && networkId !== 97 &&
                            <div className={styles.switch_network}>
                                <span className={styles.switch_text}>{t('TICKET_SWITCH_NETWORK_MSG')}</span>
                            </div>
                        }

                        {networkId !== 56 && networkId !== 97 ?
                            <Button className={styles.bridge_button} onClick={() => {switchNetwork(NetInfo)}}>{t('SWITCH_BTN')}</Button> :
                            (tickets < 1) || errorMsg.length !== 0 ?
                                <Button className={styles.preview_button} disabled={tickets < 1}>{t('PROCEED_TO_PREVIEW')}</Button>
                                : submitProcessing ?
                                    <Button className={styles.bridge_button} onClick={handleSubmit} disabled>
                                        <img src={bridge} className={styles.bridge_icon} />
                                        {t('PROCESSING')}
                                    </Button> :    
                                    <Button className={styles.bridge_button} onClick={handleSubmit}>
                                        <img src={bridge} className={styles.bridge_icon} />
                                        {t('BRIDGE_POLYGON_BTN')}
                                    </Button>
                        }
                    </div>

                    {tickets > 0 && (
                        <div className={styles.info_transfer}>
                            <img src={infoicon} className={styles.info_icon} />
                            <div className={styles.info_text}>
                                {t('IT_WILL_TAKE')} <span className={styles.strongtext}>{t('7_15_MINS')}</span> {t('TO_PROCEED_TRANSFER')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const renderSkinBridgingContent = () => {
        return (
            <div className={styles.content_wrapper}>
                <div className={styles.bridging_box}>
                    <div className={styles.title_section}>
                        <div className={styles.header_text}>{t('BRIDGE_SKINS')}</div>
                        <div className={styles.header_subtext}>{t('BRIDGE_TOWER_SKINS_INFO')}</div>
                    </div>
                    <div className={styles.body_section}>
                        <div>
                            { renderSkinImageAndText(skinType) }
                            <div className={styles.title_text}>{t('FROM_NETWORK')}</div>
                            <div className={styles.skin_wrapper}>
                                <div className={styles.skin_box}>
                                    <input type="number"
                                        className={styles.input_from}
                                        placeholder="0"
                                        onChange={handleSkinChange}
                                        value={skinCount ? skinCount : ''}
                                        onKeyDown={(evt) =>
                                            (evt.key === "e" || evt.key === "E" || evt.key === ".") &&
                                            evt.preventDefault()
                                            }
                                    />
                                    <div className={styles.max} onClick={maxSkinHandler}>{t('BTN_MAX')}</div>
                                </div>
                                <div className={styles.polygon_box}>
                                    <div className={styles.network_details}>
                                        <div className={styles.network}>{'Network'}</div>
                                        <div className={styles.network_name}>{'BNB'}</div>
                                    </div>
                                    <div className={styles.bsc_network_icon}>
                                        <img src={IconBinance} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <div className={styles.balance_text}>{`${t('AVAILABLE_BALANCE_LABEL')}:`} 
                                    <span className={styles.maxValue}> { maxSkinCount } </span>
                                </div>
                                <div className={styles.errorMsg}>
                                    <span>{errorMsg}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.arrow}>
                            <img src={arrowDown} />
                        </div>
                        <div>
                            <div className={styles.title_text}>{t('TO_NETWORK')}</div>
                            <div className={styles.skin_wrapper}>
                                <div className={styles.skin_box_to}>
                                    <div className={styles.to_value}>{skinCount ? parseInt(skinCount) : 0}</div>
                                </div>
                                <div className={styles.polygon_box}>
                                    <div className={styles.network_details}>
                                        <div className={styles.network}>{t('NETWORK')}</div>
                                        <div className={styles.network_name}>{t('POLYGON')}</div>
                                    </div>
                                    <div className={styles.polygon_network_icon}>
                                        <img src={IconPolygon} alt="polygon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {networkId !== 56 && networkId !== 97 &&
                            <div className={styles.switch_network}>
                                <span className={styles.switch_text}>{t('TICKET_SWITCH_NETWORK_MSG')}</span>
                            </div>
                        }

                        {networkId !== 56 && networkId !== 97 ?
                            <Button className={styles.bridge_button} onClick={() => {switchNetwork(NetInfo)}}>{t('SWITCH_BTN')}</Button> :
                            (skinCount < 1) || errorMsg.length !== 0 ?
                                <Button className={styles.preview_button} disabled={skinCount < 1}>{t('PROCEED_TO_PREVIEW')}</Button>
                                : submitProcessing ?
                                    <Button className={styles.bridge_button} onClick={handleSubmit} disabled>
                                        <img src={bridge} className={styles.bridge_icon} />
                                        {t('PROCESSING')}
                                    </Button> :    
                                    <Button className={styles.bridge_button} onClick={handleSubmit}>
                                        <img src={bridge} className={styles.bridge_icon} />
                                        {t('BRIDGE_POLYGON_BTN')}
                                    </Button>
                        }
                    </div>

                    {skinCount > 0 && (
                        <div className={styles.info_transfer}>
                            <img src={infoicon} className={styles.info_icon} />
                            <div className={styles.info_text}>
                                {t('IT_WILL_TAKE')} <span className={styles.strongtext}>{t('7_15_MINS')}</span> {t('TO_PROCEED_TRANSFER')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={styles.container_background}>
                <div className={styles.bridge_container}>
                <div className={styles.back_button_wrapper}>
                    <Link to={((chestType !== undefined) && '/inventory/chests') || ((ticketType !== undefined) && '/inventory/tickets') || ((skinType !== undefined) && '/inventory/skins')}>
                        <button className={styles.backBtn}>
                        <img src={backBtn} alt='' />
                        {t('BACK_BTN')}
                        </button>
                    </Link>
                </div>
                    <div className={styles.bridging_text}>
                        {t('BRIDGING')}
                    </div>
                    { chestType && renderChestBridgingContent() }
                    { ticketType && renderTicketBridgingContent() }
                    { skinType && renderSkinBridgingContent() }
                </div>
            </div>
        </>
    )
}

export { Bridging };
