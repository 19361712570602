import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import GoldTicketImg from '../../assets/imgs/gold_ticket.png';
import SilverTicketImg from '../../assets/imgs/silver_ticket.png';
import BronzeTicketImg from '../../assets/imgs/bronze_ticket.png';

import gold from '../../assets/imgs/chest-gold-70x70-2x.png';
import silver from '../../assets/imgs/chest-silver-70x70-2x.png';
import bronze from '../../assets/imgs/chest-bronze-70x70-2x.png';

import ethereumIcon from '../../assets/imgs/bridge/ethereum-logo52x52.png';
import bscIcon from '../../assets/icons/BSC_network.png';
import polygonIcon from '../../assets/imgs/bridge/polygon-matic-logo52x52.png';
import arrowRight from '../../assets/imgs/bridge/blue-arrow-2x.png';
import infoicon from '../../assets/imgs/bridge/info-icon.png';
import { Modal, Button, Image } from 'react-bootstrap';
import styles from './BridgeConfirmation.module.scss';
import { useTranslation } from 'react-i18next';
import approveProgressBar from '../../assets/imgs/bridge/progress-bar-step1.png';
import waitingApprovalProgressBar from '../../assets/imgs/ticket/progress-bar-step0.png';
import { skinMap, returnRarityImage } from '../Skins/utils';


const BridgeConfirmation = (props) => {
    const { t } = useTranslation();

    const {
        bridgeConfirm,
        bridgeToPolygon,
        bridgeTicketToPolygon,
        bridgeSkinToPolygon,
        chestType,
        chests,
        ticketType,
        tickets,
        skinCount,
        skinType,
        setBridgeConfirm
    } = props;

    const renderChestBridgeConfirmation = () => {
        return (
            <>
                <div className={styles.amount}>
                    {t('AMOUNT')}
                </div>
                <div className={styles.chest_wrapper}>
                    {chestType == "gold" && (
                        <img src={gold} className={styles.mode_icon} />
                    )}
                    {chestType == "silver" && (
                        <img src={silver} className={styles.mode_icon} />
                    )}
                    {chestType == "bronze" && (
                        <img src={bronze} className={styles.mode_icon} />
                    )}
                    <div className={styles.chests}>
                        {`x${parseInt(chests)}`}
                    </div>
                </div>
                <div className={styles.transfer_wrapper}>
                    <div className={styles.polygon_wrapper}>
                        <div className={styles.modeText}>{t('FROM_NETWORK')}</div>
                        <img src={ethereumIcon} className={styles.ethereumIcon} />
                        <div className={styles.modeText}>{t('ETHEREUM')}</div>
                    </div>
                    <img src={arrowRight} className={styles.trasferArrow} />
                    <div className={styles.ethereum_wrapper}>
                        <div className={styles.modeText}>{t('TO_NETWORK')}</div>
                        <img src={polygonIcon} className={styles.polygonIcon} />
                        <div className={styles.modeText}>{t('POLYGON')}</div>
                    </div>
                </div>
                <Button className={styles.approve_button} onClick={() => {bridgeToPolygon(); setBridgeConfirm(false)}}>
                    {t('APPROVE')}
                </Button>
            </>
        )
    }

    const renderTicketBridgeConfirmation = () => {
        return (
            <>
                <div className={styles.bridge_text}>
                    {t('BRIDGE_TICKETS')}
                </div>
                { renderSelectedImageAndCount(ticketType, tickets) }
                <div className={styles.transfer_wrapper}>
                    <div className={styles.bsc_wrapper}>
                        <div className={styles.modeText}>{t('FROM_NETWORK')}</div>
                        <img src={bscIcon} className={`${styles.bscIcon} ${styles.bsc_icon_background}`} />
                        <div className={styles.modeText}>{'BNB'}</div>
                    </div>
                    <div>
                        <img src={arrowRight} className={styles.transferArrow} />
                    </div>
                    
                    <div className={styles.polygon_wrapper}>
                        <div className={styles.modeText}>{t('TO_NETWORK')}</div>
                        <img src={polygonIcon} className={`${styles.polygonIcon} ${styles.polygon_icon_background}`} />
                        <div className={styles.modeText}>{t('POLYGON')}</div>
                    </div>
                </div>
                <Button className={styles.approve_button} onClick={() => { bridgeTicketToPolygon(); setBridgeConfirm(false)}}>
                    {t('APPROVE')}
                </Button>
            </>
        )
    }

    const renderSelectedImageAndCount = (ticketType, tickets) => {
        return (
            <>
                <div className={styles.bridge_item_type}>
                    {(ticketType === "gold") && 'Gold'}
                    {(ticketType === "silver") && 'Silver'}
                    {(ticketType === "bronze") && 'Bronze'}
                </div>
                <div className={styles.ticket_wrapper}>
                    <div>
                        {(ticketType === "gold") && <Image src={GoldTicketImg} className={styles.ticket_image}/>}
                        {(ticketType === "silver") && <Image src={SilverTicketImg} className={styles.ticket_image}/>}
                        {(ticketType === "bronze") && <Image src={BronzeTicketImg} className={styles.ticket_image}/>}
                    </div>
                    <div className={styles.tickets}>
                        {`x${parseInt(tickets)}`}
                    </div>
                </div>
            </>
        )
    }

    const renderSelectedSkinImageAndCount = (skinType, skinCount) => {

        let skinName;
        let skinRarity;
        let skinImg;

        Object.keys(skinMap).forEach(keyName => {
            if(keyName == skinType) {
                skinName = skinMap[keyName]['name'];
                skinRarity = skinMap[keyName]['rarity'];
                skinImg = skinMap[keyName]['img'];
            }
        });

        const rarityImage = returnRarityImage(skinRarity);

        return (
            <>
                <>
                    <div className={styles.bridge_skin_type_rarity}>
                        <div className={styles.bridge_skin_type}>{skinName}</div>
                        <div>
                            <Image src={rarityImage} className={styles.bridge_skin_rarity} />
                        </div>
                        
                    </div>
                    <div className={styles.skin_wrapper}>
                        <div>
                            <Image src={skinImg} className={styles.skin_image}/>
                        </div>
                        <div className={styles.skin_count}>
                            {`x${parseInt(skinCount)}`}
                        </div>
                    </div>
                </>
            </>
        );

    }

    const renderSkinBridgeConfirmation = () => {
        return (
            <>
                <div className={styles.bridge_text}>
                    {t('BRIDGE_SKINS')}
                </div>
                { renderSelectedSkinImageAndCount(skinType, skinCount) }
                <div className={styles.transfer_wrapper}>
                    <div className={styles.bsc_wrapper}>
                        <div className={styles.modeText}>{t('FROM_NETWORK')}</div>
                        <img src={bscIcon} className={`${styles.bscIcon} ${styles.bsc_icon_background}`} />
                        <div className={styles.modeText}>{'BNB'}</div>
                    </div>
                    <div>
                        <img src={arrowRight} className={styles.transferArrow} />
                    </div>
                    
                    <div className={styles.polygon_wrapper}>
                        <div className={styles.modeText}>{t('TO_NETWORK')}</div>
                        <img src={polygonIcon} className={`${styles.polygonIcon} ${styles.polygon_icon_background}`} />
                        <div className={styles.modeText}>{t('POLYGON')}</div>
                    </div>
                </div>
                <Button className={styles.approve_button} onClick={() => { bridgeSkinToPolygon(); setBridgeConfirm(false)}}>
                    {t('APPROVE')}
                </Button>
            </>
        )
    }

    return (
        <Modal show={bridgeConfirm} className={styles.bridge_modal} centered>
            <Modal.Header>
                <div className={`${styles.close_btn} ${(chestType !== undefined) ? styles.chest : '' }`} onClick={()=> setBridgeConfirm(false)}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className={`${styles.bridge_modal_header} ${(chestType !== undefined) ? styles.bridge_modal_header_chest_bg : '' }`}>
                    <div className={`${styles.header_texts} ${(chestType !== undefined) ? styles.header_texts_chest : '' }`}>
                        {t('APPROVE_BRIDGE')}
                    </div>
                    <div className={styles.progress_bar}>
                        <img src={(chestType !== undefined) ? approveProgressBar : waitingApprovalProgressBar} alt="progress-bar" />
                    </div>
                </div>
            </Modal.Header>
            
            <Modal.Body>
                <div className={styles.bridge_modal_body}>
                    { (chestType !== undefined) && (chests) && renderChestBridgeConfirmation() }
                    { (ticketType !== undefined) && (tickets) && renderTicketBridgeConfirmation() }
                    { (skinType !== undefined) && (skinCount) && renderSkinBridgeConfirmation() }
                    
                    <div className={styles.info_transfer}>
                        <img src={infoicon} className={styles.info_icon} />
                        <div className={styles.info_text}>
                        {t('IT_WILL_TAKE')} <span className={styles.strongtext}>{t('7_15_MINS')}</span> {t('TO_PROCEED_TRANSFER')}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export {BridgeConfirmation}
