import Web3 from 'web3';


const historicalBlocks = 4;

const formatFeeHistory = (result, includePending) => {
    let blockNum = Number(result.oldestBlock);
    let index = 0;
    const blocks = [];
    while (blockNum < Number(result.oldestBlock) + historicalBlocks) {
        blocks.push({
            number: blockNum,
            baseFeePerGas: Number(result.baseFeePerGas[index]),
            gasUsedRatio: Number(result.gasUsedRatio[index]),
            priorityFeePerGas: result.reward[index].map(x => Number(x)),
        });
        blockNum += 1;
        index += 1;
    }
    if (includePending) {
        blocks.push({
            number: "pending",
            baseFeePerGas: Number(result.baseFeePerGas[historicalBlocks]),
            gasUsedRatio: NaN,
            priorityFeePerGas: [],
        });
    }
    return blocks;
}

export const estimatePriorityFee = async (web3) => {
    try {
        const feeHistories = await web3.eth.getFeeHistory(historicalBlocks, "pending", [10]);
        const blocks = formatFeeHistory(feeHistories, false);
        const firstPercentilePriorityFees = blocks.map(b => b.priorityFeePerGas[0]);
        const sum = firstPercentilePriorityFees.reduce((a, v) => a + v);
        const value = Math.ceil(sum / firstPercentilePriorityFees.length);
        return value;
    } catch(e) {
        console.log(e);
        throw(e)
    }
}

export const createContract = async (DEPLOYMENT_CONTEXT, CONTRACT_NAME, PROVIDER=null) => {
    try {
        let web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].abi, DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].address);
        if (PROVIDER) {
            contract.setProvider(PROVIDER);
        }
        return contract;
    } catch (e) {
        console.log(e);
    }
};
