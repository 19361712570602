const RSS_API_KEY = process.env.REACT_APP_RSS_API_KEY;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export async function getMediumArticles() {
    try {
        const prod_url = `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/tower-token&api_key=${RSS_API_KEY}&order_by=pubDate&order_dir=desc&count=3`
        const dev_url = `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/tower-token`;
        let url = CHAIN_ID == 4 ? dev_url : prod_url;

        const options = {
            method: 'GET',
            mode: 'cors',
        };
        const response = await fetch(url, options)
        const obj = await response.json();
        return obj;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}
