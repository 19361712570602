import React from 'react';
import styles from './HomeSectionTitle.module.scss';
import { useTranslation } from 'react-i18next';


const HomeSectionTitle = (props) => {
    const [t] = useTranslation();
    const { title } = props;

    return (
        <div className={styles.home_section_title}>
            <div className={styles.title_text}>
                {title}
            </div>
        </div>
    )
}

export {HomeSectionTitle}
