import CastingCost from '../assets/icons/attributeIcons/attribute_card_cost.png';
import DamageAverage from '../assets/icons/attributeIcons/attribute_ranged_normal.png';
import Reload from '../assets/icons/attributeIcons/attribute_atkrate.png';
import AttackRate from '../assets/icons/attributeIcons/attribute_atkrate.png';
import Attackrate from '../assets/icons/attributeIcons/attribute_atkrate.png';
import AttackMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import AttackMaximum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import Health from '../assets/icons/attributeIcons/attribute_heart.png';
import CoolDown from '../assets/icons/attributeIcons/attribute_cooldown.png';
import CastingCount from '../assets/icons/attributeIcons/attribute_card_uses.png';
import Radius from '../assets/icons/attributeIcons/attribute_blastradius.png';
import DamageMaximum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import DamageMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import MeleePhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import PhysicalDamageMaximum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import Blast from '../assets/icons/attributeIcons/attribute_blastradius.png';
import FirebombBlast from '../assets/icons/attributeIcons/attribute_blastradius.png';
import AuraRadius from '../assets/icons/attributeIcons/attribute_blastradius.png';
import RangeMaximum from '../assets/icons/attributeIcons/attribute_range.png';
import RangeMinimum from '../assets/icons/attributeIcons/attribute_range.png';
import RangeAverage from '../assets/icons/attributeIcons/attribute_range.png';
import Range from '../assets/icons/attributeIcons/attribute_range.png';
import CriticalsRanged from '../assets/icons/attributeIcons/attribute_critical.png';
import Duration from '../assets/icons/attributeIcons/attribute_time.png';
import DamageDuration from '../assets/icons/attributeIcons/attribute_hourglass.png';
import Velocity from '../assets/icons/attributeIcons/attribute_velocity.png';
import MagicalDefense from '../assets/icons/attributeIcons/attribute_resist_magic.png';
import MagicalDamage from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import ModDamageTaken from '../assets/icons/attributeIcons/attribute_dmgaura_normal.png';
import Attacks from '../assets/icons/attributeIcons/attribute_charges.png';
import RevealInvisibleRadius from '../assets/icons/attributeIcons/attribute_invisibility_dispel.png';
import SlowDuration from '../assets/icons/attributeIcons/attribute_slowduration.png';
import SlowValue from '../assets/icons/attributeIcons/attribute_spd_down.png';
import AuraModSpeed from '../assets/icons/attributeIcons/attribute_spd_down.png';
import ModSpeed from '../assets/icons/attributeIcons/attribute_velocity.png';
import CriticalsMelee from '../assets/icons/attributeIcons/attribute_critical.png';
import HealSingle from '../assets/icons/attributeIcons/attribute_healing.png';
import HealTotal from '../assets/icons/attributeIcons/attribute_healing.png';
import RangeAttackMinimum from '../assets/icons/attributeIcons/attribute_ranged_magic.png';
import RangeAttackMaximum from '../assets/icons/attributeIcons/attribute_ranged_magic.png';
import GenerateMana from '../assets/icons/attributeIcons/attribute_manaregen.png';
import ManaBonusonKill from '../assets/icons/attributeIcons/attribute_manabonus.png';
import Mana from '../assets/icons/attributeIcons/attribute_manabonus.png';
import TotalManaget from '../assets/icons/attributeIcons/attribute_manabonus.png';
import AuraMaximum from '../assets/icons/attributeIcons/attribute_dmgaura_magic.png';
import AuraMinimum from '../assets/icons/attributeIcons/attribute_dmgaura_magic.png';
import AuraDamage from '../assets/icons/attributeIcons/attribute_dmgaura_magic.png';
import Regeneratepersec from '../assets/icons/attributeIcons/attribute_hp_regen.png';
import Regenerate from '../assets/icons/attributeIcons/attribute_hp_regen.png';
import Respawn from '../assets/icons/attributeIcons/attribute_hp_regen.png';
import MagicResist from '../assets/icons/attributeIcons/attribute_resist_magic.png';
import Resist from '../assets/icons/attributeIcons/attribute_resist_magic.png';
import PoisonResist from '../assets/icons/attributeIcons/attribute_resist_poison.png';
import TeleportDistance from '../assets/icons/attributeIcons/attribute_teleport.png';
import DamageScaler from '../assets/icons/attributeIcons/attribute_ranged_normal.png';
import HealAmount from '../assets/icons/attributeIcons/attribute_healing.png';
import AttackInterval from '../assets/icons/attributeIcons/attribute_atk_up_aura.png';
import DamageBoostScore from '../assets/icons/attributeIcons/attribute_evasion.png';
import PoisonMinimum from '../assets/icons/attributeIcons/attribute_melee_poison.png';
import PoisonMaximum from '../assets/icons/attributeIcons/attribute_melee_poison.png';
import MagicAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import MagicAttackMaximum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import PhysicalDefense from '../assets/icons/attributeIcons/attribute_resist_normal.png';
import PoisonDefense from '../assets/icons/attributeIcons/attribute_resist_poison.png';
import Timetouseeverycastmin from '../assets/icons/attributeIcons/attribute_time.png';
import NumberofUnits from '../assets/icons/attributeIcons/attribute_quantity.png';
import RangeMagicDamageMinimum from '../assets/icons/attributeIcons/attribute_ranged_magic.png';
import RangePhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_ranged_normal.png';
import RangePoisonDamageMinimum from '../assets/icons/attributeIcons/attribute_ranged_poison.png';
import RangePureDamageMinimum from '../assets/icons/attributeIcons/attribute_ranged_pure.png';
import MeleeMagicDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import MeleePoisonDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_poison.png';
import MeleePureDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_pure.png';
import RangeMagicAttackMinimum from '../assets/icons/attributeIcons/attribute_ranged_magic.png';
import RangePhysicalAttackMinimum from '../assets/icons/attributeIcons/attribute_ranged_normal.png';
import RangePoisonAttackMinimum from '../assets/icons/attributeIcons/attribute_ranged_poison.png';
import RangePureAttackMinimum from '../assets/icons/attributeIcons/attribute_ranged_pure.png';
import MeleeMagicAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import MeleePoisonAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_poison.png';
import MeleePureAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_pure.png';
import MeleePhysicalAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import MeleeRangeMagicRangeAttackMinimum from '../assets/icons/attributeIcons/attribute_ranged_magic.png';
import MeleeRangeMagicMagicAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import MeleeRangePhysicalRangeAttackMinimum from '../assets/icons/attributeIcons/attribute_ranged_normal.png';
import MeleeRangePhysicalMagicAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import MeleeRangePhysicalAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import MeleeRangeMagicAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import MeleeRangePureAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_pure.png';
import MeleeRangePoisonAttackMinimum from '../assets/icons/attributeIcons/attribute_melee_poison.png';
import PoisonDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_poison.png';
import MagicDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import PureDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_pure.png';
import PhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import PoisonPhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_poison.png';
import MagicPhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';
import PurePhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_pure.png';
import PhysicalPhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import MeleeRangePhysicalDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_normal.png';
import MeleeRangeMagicDamageMinimum from '../assets/icons/attributeIcons/attribute_melee_magic.png';



const attributeIcons = {
    "CastingCost": CastingCost,
    "DamageAverage": DamageAverage,
    "Health": Health,
    "CoolDown": CoolDown,
    "CastingCount": CastingCount,
    "Radius": Radius,
    "Attackrate": Attackrate,
    "Reload": Reload,
    "Blast": Blast,
    "RangeMaximum": RangeMaximum,
    "RangeMinimum": RangeMinimum,
    "RangeAverage": RangeAverage,
    "Duration": Duration,
    "DamageDuration": DamageDuration,
    "Velocity": Velocity,
    "MagicalDefense": MagicalDefense,
    "MagicalDamage": MagicalDamage,
    "ModDamageTaken": ModDamageTaken,
    "Attacks": Attacks,
    "RevealInvisibleRadius": RevealInvisibleRadius,
    "SlowDuration": SlowDuration,
    "SlowValue": SlowValue,
    "AttackMinimum": AttackMinimum,
    "AttackMaximum": AttackMaximum,
    "AttackRate": AttackRate,
    "Range": Range,
    "CriticalsRanged": CriticalsRanged,
    "CriticalsMelee": CriticalsMelee,
    "HealSingle": HealSingle,
    "HealTotal": HealTotal,
    "RangeAttackMinimum": RangeAttackMinimum,
    "RangeAttackMaximum": RangeAttackMaximum,
    "GenerateMana": GenerateMana,
    "ManaBonusonKill": ManaBonusonKill,
    "AuraMaximum": AuraMaximum,
    "AuraMinimum": AuraMinimum,
    "Regeneratepersec": Regeneratepersec,
    "MagicResist": MagicResist,
    "PoisonResist": PoisonResist,
    "Mana": Mana,
    "TeleportDistance": TeleportDistance,
    "DamageScaler": DamageScaler,
    "HealAmount": HealAmount,
    "AttackInterval": AttackInterval,
    "TotalManaget": TotalManaget,
    "DamageBoostScore": DamageBoostScore,
    "MeleePhysicalDamageMinimum": MeleePhysicalDamageMinimum,
    "PhysicalDamageMaximum": PhysicalDamageMaximum,
    "Regenerate": Regenerate,
    "Resist": Resist,
    "AuraModSpeed": AuraModSpeed,
    "AuraRadius": AuraRadius,
    "AuraDamage": AuraDamage,
    "PoisonMinimum": PoisonMinimum,
    "PoisonMaximum": PoisonMaximum,
    "Respawn": Respawn,
    "FirebombBlast": FirebombBlast,
    "MagicAttackMinimum": MagicAttackMinimum,
    "MagicAttackMaximum": MagicAttackMaximum,
    "PhysicalDefense": PhysicalDefense,
    "PoisonDefense": PoisonDefense,
    "Timetouseeverycastmin": Timetouseeverycastmin,
    "NumberofUnits": NumberofUnits,
    "ModSpeed": ModSpeed,
    "RangeMagicDamageMinimum": RangeMagicDamageMinimum,
    "RangePureDamageMinimum": RangePureDamageMinimum,
    "RangePhysicalDamageMinimum": RangePhysicalDamageMinimum,
    "RangePoisonDamageMinimum": RangePoisonDamageMinimum,
    "MeleeMagicDamageMinimum": MeleeMagicDamageMinimum,
    "MeleePoisonDamageMinimum": MeleePoisonDamageMinimum,
    "MeleePureDamageMinimum": MeleePureDamageMinimum,
    "RangeMagicAttackMinimum": RangeMagicAttackMinimum,
    "RangePhysicalAttackMinimum":RangePhysicalAttackMinimum,
    "RangePoisonAttackMinimum": RangePoisonAttackMinimum,
    "RangePureAttackMinimum": RangePureAttackMinimum,
    "MeleeMagicAttackMinimum": MeleeMagicAttackMinimum,
    "MeleePoisonAttackMinimum": MeleePoisonAttackMinimum,
    "MeleePureAttackMinimum": MeleePureAttackMinimum,
    "MeleePhysicalAttackMinimum": MeleePhysicalAttackMinimum,
    "MeleeRangeMagicRangeAttackMinimum": MeleeRangeMagicRangeAttackMinimum,
    "MeleeRangeMagicMagicAttackMinimum": MeleeRangeMagicMagicAttackMinimum,
    "MeleeRangePhysicalRangeAttackMinimum": MeleeRangePhysicalRangeAttackMinimum,
    "MeleeRangePhysicalMagicAttackMinimum": MeleeRangePhysicalMagicAttackMinimum,
    "PoisonDamageMinimum": PoisonDamageMinimum,
    "MagicDamageMinimum": MagicDamageMinimum,
    "PureDamageMinimum": PureDamageMinimum,
    "PhysicalDamageMinimum": PhysicalDamageMinimum,
    "PoisonPhysicalDamageMinimum": PoisonPhysicalDamageMinimum,
    "MagicPhysicalDamageMinimum": MagicPhysicalDamageMinimum,
    "PurePhysicalDamageMinimum": PurePhysicalDamageMinimum,
    "PhysicalPhysicalDamageMinimum": PhysicalPhysicalDamageMinimum,
    "MeleeRangePhysicalAttackMinimum": MeleeRangePhysicalAttackMinimum,
    "MeleeRangeMagicAttackMinimum": MeleeRangeMagicAttackMinimum,
    "MeleeRangePureAttackMinimum": MeleeRangePureAttackMinimum,
    "MeleeRangePoisonAttackMinimum": MeleeRangePoisonAttackMinimum,
    "MeleeRangePhysicalDamageMinimum": MeleeRangePhysicalDamageMinimum,
    "MeleeRangePhysicalAttackMinimum": MeleeRangePhysicalAttackMinimum,
    "MeleeRangeMagicDamageMinimum": MeleeRangeMagicDamageMinimum,
    "DamageMinimum": DamageMinimum,
}

export default attributeIcons;
