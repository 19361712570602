import React, { createContext, useReducer } from 'react';
import { TYPES } from './ActionTypes';

export const ABIContext = createContext();

const initialState = {
  inventory: {
    inventoryAddress: null,
    inventoryContract: null,
    erc20Abi: {},
  },
  storeContract: null,
  skinAndTicket: {
    ticketAddress: null,
    ticketContract: null,
    polygonTicketAddress: null,
    polygonTicketContract: null,
    skinAddress: null,
    skinContract: null,
    polygonSkinAddress: null,
    polygonSkinContract: null,
    polygonTowerInventoryAddress: null,
    polygonTowerInventoryContract: null,
    nftReceiverContract: null,
    nftReceiverAddress: null,
  },
  ethereumProvider: null,
  polygonProvider: null,
  binanceProvider: null,
  polygonDeploymentContext: null,
  ethereumDeploymentContext: null,
  binanceDeploymentContext: null,
  routerDeploymentContext: null,
  isCompleted: true,
  payoutDistributor: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.INIT_INVENTORY_CONTRACT:
      const {
        inventoryAddress,
        inventoryContract,
        erc20Abi,
      } = action.payload;

      return {
        ...state,
        inventory: {
          inventoryAddress,
          inventoryContract,
          erc20Abi,
        },
      };

    case TYPES.INIT_TICKET_CONTRACT:
      const {
        ticketAddress,
        ticketContract,
      } = action.payload;

      return {
        ...state,
        skinAndTicket: {
          ...state.skinAndTicket,
          ticketAddress: ticketAddress,
          ticketContract: ticketContract,
        },
      };

    case TYPES.INIT_POLYGON_TICKET_CONTRACT:
      const {
        polygonTicketAddress,
        polygonTicketContract,
      } = action.payload;

      return {
        ...state,
        skinAndTicket: {
          ...state.skinAndTicket,
          polygonTicketAddress: polygonTicketAddress,
          polygonTicketContract: polygonTicketContract,
        },
      };

    case TYPES.INIT_POLYGON_TOWER_INVENTORY_CONTRACT:
      const {
        polygonTowerInventoryAddress,
        polygonTowerInventoryContract,
      } = action.payload;

      return {
        ...state,
        skinAndTicket: {
          ...state.skinAndTicket,
          polygonTowerInventoryAddress: polygonTowerInventoryAddress,
          polygonTowerInventoryContract: polygonTowerInventoryContract,
        },
      };

    case TYPES.INIT_SKIN_CONTRACT:
      const {
        skinAddress,
        skinContract,
      } = action.payload;

      return {
        ...state,
        skinAndTicket: {
          ...state.skinAndTicket,
          skinAddress: skinAddress,
          skinContract: skinContract,
        },
      };

    case TYPES.INIT_POLYGON_SKIN_CONTRACT:
      const {
        polygonSkinAddress,
        polygonSkinContract,
      } = action.payload;

      return {
        ...state,
        skinAndTicket: {
          ...state.skinAndTicket,
          polygonSkinAddress: polygonSkinAddress,
          polygonSkinContract: polygonSkinContract,
        },
      };

    case TYPES.INIT_NFT_RECEIVER_CONTRACT:
      const {
        nftReceiverAddress,
        nftReceiverContract,
      } = action.payload;

      return {
        ...state,
        skinAndTicket: {
          ...state.skinAndTicket,
          nftReceiverAddress: nftReceiverAddress,
          nftReceiverContract: nftReceiverContract,
        },
      };

    case TYPES.SET_PROVIDERS:
      const {
        ethereumProvider,
        polygonProvider,
        binanceProvider
      } = action.payload;

      return {
        ...state,
        ethereumProvider: ethereumProvider,
        polygonProvider: polygonProvider,
        binanceProvider: binanceProvider,
      }

    case TYPES.SET_DEPLOYMENT_CONTEXTS:
      const {
        ethereumDeploymentContext,
        polygonDeploymentContext,
        binanceDeploymentContext,
        routerDeploymentContext
      } = action.payload;

      return {
        ...state,
        ethereumDeploymentContext: ethereumDeploymentContext,
        polygonDeploymentContext: polygonDeploymentContext,
        binanceDeploymentContext: binanceDeploymentContext,
        routerDeploymentContext: routerDeploymentContext
      }

    case TYPES.SET_CLAIM_REWARDS_CONFIG:
      const {
        payoutDistributor
      } = action.payload;

      return {
        ...state,
        payoutDistributor
      }

    case TYPES.INIT_STORE_CONTRACT:
      const {
        storeContract,
      } = action.payload;

      return {
        ...state,
        storeContract,
      };

    default:
      return state;
  }
};

export const ABIContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ABIContext.Provider value={[state, dispatch]}>
      {props.children}
    </ABIContext.Provider>
  );
};
