
const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const ERC20_BACKEND_API = process.env.REACT_APP_ERC20_BACKEND;

export const fetchPayoutConfig = async () => {
    try {
        const url = ERC20_BACKEND_API + '/config';
        let res = await fetch(url);
        if (res.status == 500 || res.status == 401) {
            return null;
        }

        let response = await res.json();
        return response;
    } catch (err) {
        console.error('Error fetching data: ' + err);
    }
}

export const fetchPayoutMerkleRoot = async (walletAddress) => {
    try {
        const url = ERC20_BACKEND_API + '/merkle/proof?address=' + walletAddress;
        let res = await fetch(url);
        if (res.status == 500 || res.status == 401) {
            return null;
        }

        let response = await res.json();
        return response;
    } catch (err) {
        console.error('Error fetching data: ' + err);
    }
}

export const fetchPlayerStatus = async (walletAddress) => {
    try {
        const url = BACKEND_API + '/blockpass/get_player_status';
        const payload = {
            walletAddress: walletAddress
        };
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        };
        let res = await fetch(url, options);
        if (res.status == 500 || res.status == 401) {
            return null;
        }

        let response = await res.json();
        return response;
    } catch (err) {
        console.error('Error fetching data: ' + err);
    }
}
