import { faTimes, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IpContext, TokenContext, TYPES, UserContext } from '../Context';
import { SignInWithApple } from "../SignInWithApple";
import styles from './Login.module.scss';
import firebase from "firebase";
import * as firebaseui from 'firebaseui';
import { useTranslation } from "react-i18next";
import { Web3Context } from "../../web3";
import { useCookies } from "react-cookie";
import { getCdhUserDetails, verifySignInWithApple, getCdhUserIdFromSiwaAuth } from '../../services/cdhService';
import { getWallet, loginWithId } from '../../services/walletService';
import cannotViewNarratorImg from '../../assets/imgs/bind/img-cannot-view-narrator.png';
import { ClaimTower } from "../ClaimTower";
import cdhLogo from '../../assets/imgs/bind/img-logo-cdh.png';
import androidLogo from '../../assets/icons/icon-android-wht-circle.svg';
import appleLogo from '../../assets/icons/icon-apple-store-wht-circle.svg';
import Web3 from 'web3';

const waitingForRedirectReturn = 3000;
const cdhGpLink = "https://go.onelink.me/bUzl/9c836b67";
const cdhAppStoreLink = "https://go.onelink.me/VHLW/e01c1c3f";

const Login = ({ title, descriptions, loginCharacterImage, setShow, setLoggedIn, setUserDetails, setWalletInfo }) => {
    const [t] = useTranslation();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [login, setLogin] = useState(false);
    const { isInChina, ipReady, setEventEarningTokenBalance, setEarnedTowerLoggedIn } = useContext(IpContext);
    const [siwaCookies, setSiwaCookies, removeSiwaCookies] = useCookies(['SiwaAuthId']);
    const { web3, connect } = useContext(Web3Context);
    const [cdhUserId, setCdhUserId] = useState(null);
    const [, dispatch] = useContext(TokenContext);
    const [showLinkErrorModal, setShowLinkErrorModal] = useState(false);
    // const [siwaId, setSiwaId] = useState(null);
    const [showWalletLinkPage, setShowWalletLinkPage] = useState(false);
    const { updateOnLogin } = useContext(UserContext);

    useEffect(() => {
        const fetchRemoveValues = async () => {
            // setFetchingUserData(true);

            if (!isInChina) {
                var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
                if (!ui.isPendingRedirect()) {
                    // setFetchingUserData(false);
                }
            } else {
                // setFetchingUserData(false);
            }
        }

        if (ipReady) {
            fetchRemoveValues();
        }
    }, [ipReady]);

    // Better approach would be to move the firebase onAuthStateChanged action to a context file.
    useEffect(() => {
        initializeFirebase();

        if (ipReady && isInChina) {
            if (siwaCookies.SiwaAuthId != null) {
                // setFetchingUserData(true);
                loginSiwaUser(siwaCookies.SiwaAuthId);
            }
        }

        if (ipReady && !isInChina) {
            firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    setLogin(true);
                    handleSignedInUser(user);
                } else {
                    handleSignedOutUser();
                }
            });
        }
    }, [ipReady]);

    const initializeFirebase = () => {
        var config = GetFirebaseConfig();

        if (!isInChina) {
            var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
            // If method is redirect, we need to add a delay to make sure we catch the return
            // before running start, as start "completes" the redirect flow
            if (ui.isPendingRedirect()){
                // setFetchingUserData(true);

                // It seems cookies does not persist in metamask app
                // Hack to connect it first when redirected back my firebase
                if(web3 == null) {
                    connect();
                }
                setTimeout(function () {
                    ui.start('#firebaseui-auth-container', config);
                    // setFetchingUserData(false);
                }, waitingForRedirectReturn);
            } else {
                ui.start('#firebaseui-auth-container', config);
            }
        }
    }

    const commonFirebaseSignInOption = [
        'apple.com',
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            disableSignUp: {
                status: true
            },
            fullLabel: t('SIGN_IN_WITH_EMAIL')
        },
        {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            fullLabel: t('SIGN_IN_WITH_PHONE')
        }
    ]

    const commonFirebaseCallback = {
        'signInSuccessWithAuthResult': function(authResult, redirectUrl) {
            setShowLoginModal(false);
            return false;
        }
    }

    const uiConfigMobile = {
        signInSuccessUrl: '/',
        callbacks: commonFirebaseCallback,
        signInOptions: commonFirebaseSignInOption
    };

    const uiConfig = {
        signInFlow: 'popup',
        callbacks: commonFirebaseCallback,
        signInOptions: commonFirebaseSignInOption
    };

    const GetFirebaseConfig = () => {
        var config = uiConfig;
        if( /Android|webOS|iPhone|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            config = uiConfigMobile;
        }

        return config;
    }

    const HandleShowLoginModalOnShow = () => {
        if (!isInChina) {
            var config = GetFirebaseConfig();
            var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
            ui.start('#firebaseui-auth-container2', config);
        }
    }

    const postProcessAppleSignedIn = async (idToken) => {
        // setFetchingUserData(true);
        var response = await verifySignInWithApple(idToken, isInChina);
        if (response != null) {
            var authId = response.AuthId;
            // setSiwaId(authId);
            setSiwaCookies('SiwaAuthId', authId, { path: '/' });
            loginSiwaUser(authId);
            setShowLoginModal(false);
            // setFetchingUserData(false);
        } else {
            // setFetchingUserData(false);
        }
    }

    const handleSignedInUser = (user) => {
        // setFetchingUserData(true);
        getUserData(user.uid, "", "");
        setEarnedTowerLoggedIn(true);
    }

    const handleSignedOutUser = () => {
        setCdhUserId(null);
        setLogin(false);
        setEarnedTowerLoggedIn(false);
        setEventEarningTokenBalance('0.00');
        dispatch({
            type: TYPES.SET_USER_ID,
            payload: "",
        });
    }

    const loginSiwaUser = async (authId) => {
        var cdhFromAuth = await getCdhUserIdFromSiwaAuth(authId, isInChina);
        var cdhPlayerId = cdhFromAuth.Username;
        if (cdhPlayerId != "") {
            setCdhUserId(cdhPlayerId)

            dispatch({
                type: TYPES.SET_USER_ID,
                payload: cdhPlayerId,
            });
            getUserData("", authId, cdhPlayerId)
            setLogin(true);
        } else {
            // setFetchingUserData(false);
        }
    }

    const signOut = () => {
        removeSiwaCookies('SiwaAuthId', { path: '/' });
        if (!isInChina) {
            firebase.auth().signOut();
        }
        handleSignedOutUser();
    }

    const getUserData = async (firebaseuuid, siwaId, cdhPlayerId) => {
        var response = await loginWithId(firebaseuuid, siwaId, cdhPlayerId, isInChina);
        var userId = response == null || response.CdhPlayerId.length == 0 ? null : response.CdhPlayerId;
        if (userId != null) {
            setCdhUserId(userId);

            dispatch({
                type: TYPES.SET_USER_ID,
                payload: userId,
            });

            const userDetails = await getCdhUserDetails(userId, isInChina);

            let responseTowerPerson = await getWallet(firebaseuuid, siwaId, isInChina);
            if (!responseTowerPerson) {
                setShowLinkErrorModal(true);
            }

            if (responseTowerPerson?.WalletId) {
                let localWeb3 = new Web3();
                responseTowerPerson['WalletIdChecksumed'] = localWeb3.utils.toChecksumAddress(responseTowerPerson.WalletId);
            }

            if (setWalletInfo) setWalletInfo(responseTowerPerson);

            let userDetailsWithId = {
                ...userDetails,
                cdhUserId: userId,
            }
            if (setUserDetails) setUserDetails(userDetailsWithId);
            if (setLoggedIn) setLoggedIn(true);

            updateOnLogin(userDetailsWithId, responseTowerPerson);
            setShow(false);
        }

        // setFetchingUserData(false);
    }

    const boxFooter = (smallContainer) => {
        return <div className={`${styles.earn_tower_details} ${styles.bind_description} ${smallContainer ? styles.small_container : ""}`}>
            <img src={cdhLogo} className={styles.cdh_logo} alt="cdh-logo" />
            <div className={styles.right_panel}>
                {t('PRE_LINK_WANNA_EARN_TOWER')}
                <div className={styles.stores_logo}>
                    <a href={cdhGpLink} target="_blank" rel="noopener noreferrer"><img src={androidLogo} alt="android" /></a>
                    <a href={cdhAppStoreLink} target="_blank" rel="noopener noreferrer"><img src={appleLogo} alt="apple" /></a>
                </div>
            </div>
        </div>
    };

    const renderLogin = (
        <div className={styles.bind_login}>
            <div className={styles.claim_box_container}>
                <div className={styles.body}>
                    <div className={styles.bind_section}>
                        <div className={styles.bind_section_title}>{title}</div>
                        <div className={styles.bind_descriptions}>{descriptions}</div>
                        <div className={styles.steps_button_section}>
                            <div className={styles.bind_buttons}>
                                <div className={styles.step_button}>
                                    {login ? (
                                        firebase.auth().currentUser != null || siwaCookies.SiwaAuthId != null ?
                                            <div className={styles.step_mini_container}>
                                                {cdhUserId == null
                                                    ? <div>{t('NO_CDH_USER_FOUND')}</div>
                                                    : <div>
                                                        <div className={styles.bind_description}>{t('GAME_ID')}</div>
                                                        <div className={styles.step_text}>{cdhUserId}</div>
                                                    </div>
                                                }
                                                <div onClick={signOut}><FontAwesomeIcon icon={faSignOutAlt}/></div>
                                            </div> :
                                            <Button variant="cdh-general-blue-dbrown" onClick={() => setShowLoginModal(true)}>{t('LOGIN')}</Button>
                                        ) :
                                        <Button variant="cdh-general-blue-dbrown" onClick={() => setShowLoginModal(true)}>{t('LOGIN')}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { boxFooter(true) }
            </div>
            {loginCharacterImage && <div className={styles.avatar_image}><img src={loginCharacterImage} alt="login-avatar" /></div>}
        </div>
    );

    return (
        <div className={styles.login_page_container}>
            <div className={styles.login_page_backdrop} onClick={() => setShow(false)}></div>

            {showWalletLinkPage ?
                <ClaimTower /> :
                <div className={`${styles.login_page}`}>
                    <div className={styles.section_container}>
                        {renderLogin}
                        <div className={styles.displayNone} id="temp_firebase"><div id="firebaseui-auth-container"></div></div>
                    </div>

                    <Modal show={showLoginModal} className={styles.login_modal} centered onShow={HandleShowLoginModalOnShow} onHide={()=> setShowLoginModal(false)}>
                        <div className={styles.close_btn} onClick={()=> setShowLoginModal(false)}><FontAwesomeIcon icon={faTimes}/></div>
                        <Modal.Body>
                            <div className={styles.login_modal_body}>
                                <div className={styles.header_texts}>{t('LOGIN')}</div>
                                {
                                    isInChina
                                    ? <SignInWithApple content={{ callback: postProcessAppleSignedIn }} />
                                    : <div id="firebaseui-auth-container2"></div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showLinkErrorModal} className={styles.link_error_modal} centered onHide={() => setShowLinkErrorModal(false)}>
                        <div className={styles.close_btn} onClick={()=> setShowLinkErrorModal(false)}><FontAwesomeIcon icon={faTimes}/></div>
                        <Modal.Body>
                            <div className={styles.link_error_modal_body}>
                                <div>
                                    <img src={cannotViewNarratorImg} alt="cannot-view" />
                                </div>
                                <div className={styles.header_texts}>{t('ERROR_VIEW_TITLE')}</div>
                                <div className={styles.sub_texts}>{t('ERROR_VIEW_CONTENT')}</div>
                                <div className={styles.link_text}>{t('ERROR_VIEW_ACTION')}</div>
                                <div className={styles.submit_btn} onClick={() => setShowWalletLinkPage(true)}>{t('CONFIRM_BTN')}</div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }     
        </div>
    );
};

export { Login };
