import React, { useState, useEffect, useContext, useRef } from 'react';
import openSea from '../../assets/icons/icon-opensea-dark.svg';
import shareIcon from '../../assets/icons/share_24px.svg';
import styles from './CardDetail.module.scss';
import backBtn from '../../assets/icons/arrow_back_ios_24px.svg';
import transfer from '../../assets/icons/transfer_horiz_24px.svg';
import { Link } from 'react-router-dom';
import polygon from '../../assets/icons/polygon-matic-logo-purple-bg-24x24-1x.png';
import { ABIContext, TYPES } from '../../components/Context';
import { useParams } from 'react-router-dom';
import TransferCardModal from '../TrasnferCardModal/TransferCardModal';
import attributeIcons from '../../constants/attributeIcon';
import defaultIcon from '../../assets/icons/attributeIcons/transparent.png';
import { Web3Context } from '../../web3/Web3Context';
import Loading from '../Loading/Loading';
import { Dropdown, Overlay, Tooltip } from 'react-bootstrap';
import facebookIcon from '../../assets/icons/facebook1.png';
import telegramIcon from '../../assets/icons/telegramIcon.svg';
import twitterIcon from '../../assets/icons/twitterIcon.svg';
import sharelinkIcon from '../../assets/icons/sharelinkIcon.svg';
import { IpContext } from '../../components/Context';
import { GeneralModal } from '../GeneralModal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { createContract, estimatePriorityFee } from '../../services/contractService';
import { erc20Abi } from '../../abis/erc20Abi';
import { toBN } from 'web3-utils';


const CHAIN_ID = process.env.REACT_APP_POLYGON_CHAIN_ID
const CARD_NFTS_ENABLED = process.env.REACT_APP_CARD_NFTS_ENABLED;

const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;
const IPFS_URI_PREFIX = process.env.REACT_APP_IPFS_GATEWAY_PREFIX;

const copyToClipboard = (link) => {
  navigator.clipboard.writeText(link)
}

const CardDetail = () => {
  const [cardInfo, setCardInfo] = useState({});
  const params = useParams();
  const [abiConfig, abiDispatch] = useContext(ABIContext);
  const {
    inventory: { inventoryContract },
    polygonProvider,
    polygonDeploymentContext
  } = abiConfig;
  const [openModal, setOpenModal] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const { selectedAccount, web3, networkId, provider } = useContext(Web3Context);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef(null)
  const {isInChina} = useContext(IpContext);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();

  if (CARD_NFTS_ENABLED === "false") {
    history.push("/inventory/chests");
  }

  const getSocialLinks = (inventoryAddress) => {
    return [
      {
        icon: facebookIcon,
        name: 'Facebook',
        link: 'https://www.facebook.com/sharer/sharer.php?u=' +  OPENSEA_URL + inventoryAddress + "/" + params.id,
        disabled: isInChina
      },
      {
        icon: telegramIcon,
        name: 'Telegram',
        link: 'https://telegram.me/share/url?url=' + OPENSEA_URL + inventoryAddress + "/" + params.id,
        disabled: isInChina
      },
      {
        icon: twitterIcon,
        name: 'Twitter',
        link: 'https://twitter.com/intent/tweet?url=' + OPENSEA_URL + inventoryAddress + "/" + params.id,
        disabled: isInChina
      },
      {
        icon: sharelinkIcon,
        name: 'Copy Link',
        copyLink: OPENSEA_URL + inventoryAddress + "/" + params.id,
        disabled: isInChina
      },
    ];
  }

  useEffect(() => {
    if (polygonDeploymentContext?.contracts) {
      let socialLinksResult = getSocialLinks(polygonDeploymentContext.contracts['CDHInventory'].address);
      setSocialLinks([...socialLinksResult]);
    }
  }, [polygonDeploymentContext])

  const fetchCardDetails = async (inventoryContract) => {
    let decodedCard = await inventoryContract.methods.info(params.id).call();
    let decodedCardUri = IPFS_URI_PREFIX ? decodedCard._uri.replace("https://gateway.pinata.cloud/ipfs/", IPFS_URI_PREFIX) : decodedCard._uri;
    let data = await fetch(decodedCardUri);
    let jsonData = await data.json();
    jsonData.image = IPFS_URI_PREFIX ? jsonData.image.replace("https://gateway.pinata.cloud/ipfs/", IPFS_URI_PREFIX) : jsonData.image;
    setCardInfo(jsonData);
  };

  const onTransferTokenToUser = async (transferAddress, tokenId, transferAmount, data, setLoading, setTransfer, setTxHashLink, setSignTransfer, setShowMessageModal, setErrorMsg) => {
    if (transferAddress != "" && transferAmount != 0 && inventoryContract) {
      try {
        setLoading(true);
        setSignTransfer(true);

        let userAddress = selectedAccount;

        let gasPrice = await web3.eth.getGasPrice();
        let gasPriceBN = toBN(gasPrice);
        let gasEstimate = await inventoryContract.methods
          .safeTransferFrom(userAddress, transferAddress, tokenId, transferAmount, data)
          .estimateGas({from: selectedAccount});
        let finalGasPrice = gasPriceBN.add(toBN(parseInt(0.1 * gasPriceBN))).toString();
        let estimatedPriorityFee = await estimatePriorityFee(web3);
        estimatedPriorityFee = parseInt(estimatedPriorityFee + 0.2 * estimatedPriorityFee);
        let finalMaxFeePerGas = toBN(estimatedPriorityFee).add(toBN(parseInt(0.2 * gasPriceBN))).toString();

        let receipt = await inventoryContract.methods.safeTransferFrom(userAddress, transferAddress, tokenId, transferAmount, data).send({
          from: selectedAccount,
          gasLimit: gasEstimate,
          gasPrice: finalGasPrice,
          maxFeePerGas: finalMaxFeePerGas,
          maxPriorityFeePerGas: estimatedPriorityFee
        })
          .on('transactionHash', (txHash) => {
            console.log(`Transaction hash obtained: ${txHash}`);
            setTxHashLink(txHash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
          })
          .on('error', err => {
            if (err.code !== 4001) {
              console.log(err);
              throw new Error("Token transfer failed");
            }
          });

        if (receipt.status === true) {
          setLoading(false);
          setTransfer(true);
          return true;
        }
      } catch (e) {
          console.log(e);
          setLoading(false);
          setSignTransfer(false);
          setTransfer(false);

          if (e.code !== 4001) {
            setErrorMsg((prevState) => {
              return { ...prevState, transferError: 'Please provide valid wallet address' }
            });
            setShowMessageModal(true);
          }
      }
    } else {
      console.log("Please enter the correct arguments");
    }
  }

  const initializeInventoryContract = async () => {
    let contract = await createContract(polygonDeploymentContext, 'CDHInventory', provider);

    if (contract) {
      abiDispatch({
        type: TYPES.INIT_INVENTORY_CONTRACT,
        payload: {
          inventoryAddress: contract.options.address,
          inventoryContract: contract,
          erc20Abi: erc20Abi
        },
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!inventoryContract && polygonProvider && polygonDeploymentContext) {
      initializeInventoryContract();
    }
  }, [polygonProvider, polygonDeploymentContext]);

  useEffect(() => {
    if (params.id && inventoryContract && CHAIN_ID == networkId) {
      fetchCardDetails(inventoryContract);
    } else {
      setCardInfo({});
    }
  }, [params, inventoryContract, networkId]);

  let attack_value, damage_value;

  const getSrc = (trait, value) => {
    let noDots = trait.replace(/[\s.()._*-/]/g, '');
    if(noDots === 'AttackType') {
      attack_value = value.replace(/[\s.()._*-/]/g, '')
    }
    if(noDots === 'Damagetype' || noDots === 'DamageType') {
      damage_value = value.replace(/[\s.()._*-/]/g, '')
      
    }
    if(noDots.includes('DamageMinimum') || noDots.includes('AttackMinimum')) {
      let iconName = (attack_value ?? '') + (damage_value ?? '') + noDots
      return attributeIcons[iconName]
    } else {
      return attributeIcons[noDots];
    }
  };

  const displayPropertiesItems = () => {
    let propValue = cardInfo?.attributes?.filter((item) => {
      return (
        item?.trait_type === 'Affinity' ||
        item?.trait_type === 'Level' ||
        item?.trait_type === 'Rank' ||
        item?.trait_type === 'Rarity' ||
        item?.trait_type === 'Sub Type'
      );
    });

    return propValue?.map((item, index) => (
      <div className={styles.property} key={index}>
        <span>{item?.trait_type}</span>
        <h6>{item?.value}</h6>
      </div>
    ));
  };

  const showRangeValues = () => {
    let rangeInfo = cardInfo?.attributes
      ?.map((item, idx) => {
        let obj = {};
        if (
          item?.trait_type.includes('Minimum') ||
          item?.trait_type.includes('Maximum')
        ) {
          obj.type = item?.trait_type;
          obj.value = item?.value;
          return obj;
        }
      })
      ?.filter((item) => {
        return item !== undefined;
      });
    let formattedData = [];
    let rangeFormatData = rangeInfo?.map((item, idx) => {
      let obj = {
        type: item.type,
        value: `${item?.value}-${rangeInfo[idx + 1]?.value}`,
      };
      return obj;
    });

    for (let i = 0; i < rangeFormatData?.length; i += 2) {
      formattedData.push(rangeFormatData[i]);
    }

    return formattedData?.map((attribute, idx) => (
      <div className={styles.property} key={idx}>
        <div className={styles.property__item}>
          <img src={getSrc(attribute?.type, attribute?.value) ?? defaultIcon} alt='' />
          <div className={styles.property__value}>
            <span>
              {attribute?.type?.split(' ').length > 2
                ? attribute?.type?.split(' ').slice(0, 2).join(' ')
                : attribute?.type?.split(' ')[0]}
            </span>
            <h6>{attribute?.value}</h6>
          </div>
        </div>
      </div>
    ));
  };


  if(Object.keys(cardInfo).length === 0) {
    return <Loading />
  }

  const handleTooltip = (link) => {
    if (window.location.protocol !== "https:") {
      setShowMessageModal(true);
    } else {
      setShowTooltip(true);
      copyToClipboard(link?.toLowerCase())
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500)
    }
    
  }

  const closeMessageModal = () => {
    setShowMessageModal(false);
  }
  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <Link to='/inventory/cards'>
            <button className={styles.backBtn}>
              <img src={backBtn} alt='' />
              Back
            </button>
          </Link>
          <div className={styles.headerRight}>
            <button
              className={styles.transferBtn}
              onClick={() => setOpenModal(true)}
            >
              <img src={transfer} alt='' /> Transfer
            </button>
            <a href={OPENSEA_URL + polygonDeploymentContext?.contracts['CDHInventory'].address.toLowerCase() + "/" + params.id} target='_blank' className={styles.openSeaIcon}>
              <img src={openSea} alt='' />
            </a>
            <Dropdown>
              <Dropdown.Toggle bsPrefix={styles.share_btn} id="dropdown-basic">
                <img src={shareIcon} alt='' />
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.dropdown_menu}>
                <ul className={styles.shareLinks}>
                {socialLinks.map((item, idx) => {
                   if (!item.disabled && !item.copyLink) {
                     return (
                       <li key={idx}>
                         <a href={item.link?.toLowerCase()} target="_blank"><img src={item.icon} alt='' />{item.name}</a>
                       </li>
                     );
                   } else if(!item.disabled) {
                     return (
                       <li key={idx}>
                         <span className={styles.copyToClipboard} ref={tooltipTarget} onClick={() => handleTooltip(item?.copyLink)}><img src={item.icon} alt='' />{item.name}</span>
                         <Overlay target={tooltipTarget.current} show={showTooltip} placement="top">
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            Copied
                          </Tooltip>
                        )}
                      </Overlay>
                       </li>
                     );
                   }
                })}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
        </div>
        </header>
        <div className={styles.card__container}>
          <div className={styles.card__img}>
            <img className={styles.img} src={cardInfo?.image} alt='cardImage' />
            <img className={styles.polygonIcon} src={polygon} alt='Polygon' />
          </div>
          <div className={styles.card__details}>
            <header className={styles.head}>
              <h3>{cardInfo?.name}</h3>
              <p>{cardInfo?.description}</p>
            </header>
            <div className={styles.properties}>
              <h5 className={styles.detail__title}>Properties</h5>
              <div className={styles.properties__list}>
                {displayPropertiesItems()}
              </div>
            </div>
            <div className={styles.properties}>
              <h5 className={styles.detail__title}>Boosts</h5>
              <div className={styles.properties__list}>
                {cardInfo &&
                  cardInfo?.attributes?.map(
                    (attribute, index) =>
                      attribute.trait_type !== 'Affinity' &&
                      attribute.trait_type !== 'Sub Type' &&
                      attribute.trait_type !== 'Rank' &&
                      attribute.trait_type !== 'Level' &&
                      attribute.trait_type !== 'Rarity' &&
                      attribute.trait_type !== 'O' &&
                      attribute.trait_type !== 'Card' &&
                      !attribute.trait_type.includes('Minimum') &&
                      !attribute.trait_type.includes('Maximum') &&
                      !attribute.trait_type.includes('Average') && (
                        <div className={styles.property} key={index}>
                          <div className={styles.property__item}>
                            <img
                              src={getSrc(attribute.trait_type, attribute.value) ?? defaultIcon}
                              alt=''
                            />
                            <div className={styles.property__value}>
                              <span>{attribute.trait_type}</span>
                              <h6>{attribute.value}</h6>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                {showRangeValues()}
              </div>
            </div>
            {/* <div className={styles.properties}>
              <h5 className={styles.detail__title}>Attacks</h5>
              <div className={styles.properties__list}>
                <div className={styles.property}>
                  <div className={styles.attacks}>
                    <img src={iconOverchanrge} alt='' />
                    <div className={styles.attacks__value}>
                      <h6>Overcharge</h6>
                      <span>Improved Damage</span>
                    </div>
                  </div>
                </div>
                <div className={styles.property}>
                  <div className={styles.attacks}>
                    <img src={iconLight} alt='' />
                    <div className={styles.attacks__value}>
                      <h6>Chain Lightning</h6>
                      <span>Strikes Multiple Enemies</span>
                    </div>
                  </div>
                </div>
                <div className={styles.property}>
                  <div className={styles.attacks}>
                    <img src={iconDamage} alt='' />
                    <div className={styles.attacks__value}>
                      <h6>Damage Bonus</h6>
                      <span>Level 256</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.token_id}>
              <h4>Token Id:</h4>
              <span>{params?.id}</span>
            </div>
          </div>
        </div>
      </div>
      <TransferCardModal
        isCard={true}
        isChest={false}
        openModal={openModal}
        setOpenModal={setOpenModal}
        onTransferTokenToUser={onTransferTokenToUser}
        web3={web3}
        selectedTransferCard={params.id}
        itemImg={cardInfo?.image}
      />
      <GeneralModal content={{
        show: showMessageModal,
        showSetter: setShowMessageModal,
        title: t('ERROR'),
        description: t('COPY_ERROR'),
        buttonText: t('OK'),
        buttonCallback: closeMessageModal
        }} 
      />

    </>
  );
};

export default CardDetail;
