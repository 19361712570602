import React from 'react';
import { useTranslation } from 'react-i18next';
import './TopRibbon.scss';

const TopRibbon = () => {
    const {t} = useTranslation();

    return (
        <div className="TopRibbon">
            {t('RIBBON_TEXTKEY')}
        </div>
    );
};

export { TopRibbon };
