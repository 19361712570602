import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Modal } from 'react-bootstrap';
import styles from './AddTowerModal.module.scss';
import SwapIcon from '../../assets/icons/swap-icon.png';
import { useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const AddTowerModal = (props) => {
    const [t] = useTranslation();
    const history = useHistory()
    const { showAddTowerModal, setShowAddTowerModal } = props;

    const handleClose = () => setShowAddTowerModal(false);

    const goToEarnTower = () => {
        handleClose();
        history.push('/earn-tower');
    }

    const goToSwapTower = () => {
        handleClose();
        history.push('/buy-tower/matic');
    }
    
    return (
        (showAddTowerModal)
        ?
            (
                <Modal show={showAddTowerModal} className={styles.addtower_modal} onHide={handleClose} centered>
                    <div className={styles.close_btn} onClick={() => setShowAddTowerModal(false)}><FontAwesomeIcon icon={faTimes}/></div>
                    <Modal.Header className={styles.addtower_modal_header}>
                        <Modal.Title>
                            <div className={styles.addtower_modal_title}>Add Tower</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={styles.addtower_modal_body} onClick={() => goToSwapTower()}>
                        <div className={styles.addtower_modal_body_icon}>
                            <img src={SwapIcon} />
                        </div>
                        <div className={styles.addtower_modal_body_text}>
                            <span>Swap TOWER</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={styles.addtower_modal_footer}>
                        <div className={styles.addtower_modal_footer_earntower_text}>
                            <span>Want to play game and earn TOWER?</span>
                        </div>
                        <div className={styles.addtower_modal_footer_earntower_link} onClick={() => goToEarnTower()}>
                            <span>Earn TOWER</span>
                        </div>
                    </Modal.Footer>
                </Modal>  
            )
        : <></>
    );

}

export { AddTowerModal };
