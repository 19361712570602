const IPFS_URI_PREFIX = process.env.REACT_APP_IPFS_GATEWAY_PREFIX;
const IPFS_URI_IMAGE_PREFIX = process.env.REACT_APP_IPFS_GATEWAY_IMAGE_PREFIX;

export const fetchCards = async (contract, account) => {
    try {
        let itemsOwnedByAddress = await contract.methods.getAllTokens(
            account,
        ).call();
        let ownedItems = [];

        itemsOwnedByAddress.map((item) => {
            ownedItems.push(item.toString());
        })

        return ownedItems;
    } catch (e) {
        console.log(e);
    }
    return [];
}

const getCardInfo = async (contract, nftList, successfulCardList, failedCardList) => {
    await Promise.all(nftList.map(async (item, key) => {
        let decodedCard;
        await contract.methods.info(item).call()
            .then((result) => {
                decodedCard = result;

                if (failedCardList.includes(item)) {
                    failedCardList.splice(failedCardList.indexOf(item), 1);
                }
            }).catch((err) => {
                failedCardList.push(item);
            });

        if (decodedCard) {
            let blockNum = decodedCard.blockNum.toString();
            let uri = decodedCard._uri;
            let token = {
                blockNum,
                uri,
                id: item
            };

            successfulCardList.push(token);
        }
    }));
}

export const getCardsInOrder = async (contract, nftList, sorting = 'desc') => {
    try {
        let cardList = [];
        let failedList = [];
        const loopLimit = 20;
        let loopCount = 1;

        await getCardInfo(contract, nftList, cardList, failedList);

        while (failedList.length > 0 && loopCount <= loopLimit) {
            await getCardInfo(contract, failedList, cardList, failedList);
            loopCount += 1;
        }

        let returnFirst;
        let returnSecond;
        if (sorting === 'desc') {
            returnFirst = -1;
            returnSecond = 1;
        } else {
            returnFirst = 1;
            returnSecond = -1;
        }

        cardList.sort((first, second) => {
            if (first.blockNum == second.blockNum && first.id > second.id) {
                return returnFirst;
            } else if (first.blockNum == second.blockNum && first.id < second.id) {
                return returnSecond;
            } else if (first.blockNum > second.blockNum) {
                return returnFirst;
            } else if (first.blockNum < second.blockNum) {
                return returnSecond;
            } else {
                return 0;
            }
        });

        return cardList;
    } catch (err) {
        console.log(err);
    }
}

export const fetchCardDetails = async (nftList) => {
    // storing cards in an object to maintain order
    let newCardList = {};

    await Promise.all(nftList.map(async (item, key) => {
        try {
            let itemUri = IPFS_URI_PREFIX ? item.uri.replace("https://gateway.pinata.cloud/ipfs/", IPFS_URI_PREFIX) : item.uri;
            let data = await fetch(itemUri);
            if (data.status === 200) {
                let jsonData = await data.json();
                jsonData.id = item.id;
                jsonData.blockNum = item.blockNum;
                jsonData.uri = IPFS_URI_PREFIX ? item.uri.replace("https://gateway.pinata.cloud/ipfs/", IPFS_URI_PREFIX) : item.uri;

                let imageFilename = jsonData?.image.split('/').pop();
                let replacedImageUri = IPFS_URI_IMAGE_PREFIX + imageFilename; //jsonData?.image.replace("https://gateway.pinata.cloud/ipfs/", IPFS_URI_IMAGE_PREFIX);
                jsonData.image = IPFS_URI_IMAGE_PREFIX ? replacedImageUri : (IPFS_URI_PREFIX ? jsonData?.image.replace("https://gateway.pinata.cloud/ipfs/", IPFS_URI_PREFIX) : jsonData?.image);

                newCardList[key] = jsonData;
            } else {
                newCardList[key] = {};
            }
        } catch (e) {
            newCardList[key] = {}
        }
    }));

    return newCardList;
}
