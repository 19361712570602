import React, { useContext, useState, useEffect } from 'react';
import {
    Nav,
    Navbar,
    Button
} from 'react-bootstrap';
import i18n from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import styles from './Navigation.module.scss';
import towerLogo from '../../assets/imgs/logo-tower.svg';
// import towerLogoDark from '../../assets/icons/logo-tower-dark.png';
import { IpContext, TokenContext } from '../Context';
import { Web3Context } from '../../web3/Web3Context';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faChevronDown,
    faChevronUp,
    faSignOutAlt,
    faTimes,
    faPlusSquare,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { middleEllipsis } from '../../helpers';
import { ConnectModal } from '../../components';
import { TopRibbon } from './TopRibbon';
import { getRemoteValues } from '../../services/cdhService';
import polygonLogo from '../../assets/imgs/home/icon-polygon-circle.png';
import ethereumLogo from '../../assets/imgs/home/icon-ethereum-circle.png';
import bscLogo from '../../assets/imgs/home/icon-bsc-circle.png';
import metamaskLogo from '../../assets/imgs/home/icon-metamask.png';
import coinbaseLogo from '../../assets/imgs/home/icon-coinbase.png';
import { EthNetworkInfo, PolygonNetworkInfo, BinanceNetworkInfo } from '../../services/networkServices';
import { AddTowerModal } from './AddTowerModal';
import IconNew from '../../assets/imgs/new_text.gif';
import { HashLink } from 'react-router-hash-link';

const ETH_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID);
const POLYGON_CHAIN_ID = parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID);
const BINANCE_CHAIN_ID = parseInt(process.env.REACT_APP_BINANCE_CHAIN_ID);
// const CoinbaseCampaignName = process.env.REACT_APP_COINBASE_CAMPAIGN_NAME;

const Navigation = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { isInChina, ipReady, eventEarningToken, earnTowerLoggedIn } = useContext(IpContext);
    const { selectedAccount, ready, disconnect, connect, networkId, switchNetwork, provider } = useContext(Web3Context);
    const [state] = useContext(TokenContext);
    const [showAddTowerModal, setShowAddTowerModal] = useState(false);
    const [showNavBar, setShowNavBar] = useState(false);
    const [showConnectModal, setShowConnectModal] = useState(false);
    const [remoteValues, setRemoteValues] = useState();
    const location = useLocation();
    const [showAboutSections, setShowAboutSections] = useState(false);
    const [networkSelected, setNetworkSelected] = useState('');

    // global dictionary object that will be used to map network names to logos
    const globalNetworkImgMap = { 'ethereum': ethereumLogo, 'polygon': polygonLogo, 'bsc': bscLogo }

    useEffect(() => {
        // Support for Goerli -? networkId 5
        if (networkId === ETH_CHAIN_ID || networkId === 5) {
            setNetworkSelected('ethereum')
        } else if (networkId === POLYGON_CHAIN_ID) {
            setNetworkSelected('polygon')
        } else if (networkId === BINANCE_CHAIN_ID) {
            setNetworkSelected('bsc')
        } else {
            setNetworkSelected('');
        }
    }, [networkId])

    const handleNetworkChange = async (networkName) => {
        if (networkName === "ethereum") {
            await switchNetwork(EthNetworkInfo);
        } else if (networkName === "polygon") {
            await switchNetwork(PolygonNetworkInfo);
        } else if (networkName === "bsc") {
            await switchNetwork(BinanceNetworkInfo);
        }
    }

    useEffect(() => {
        const fetchRemoveValues = async () => {
            const response = await getRemoteValues(isInChina);
            if (response != null) {
                i18n.addResource(
                    'en',
                    'translation',
                    'RIBBON_TEXTKEY',
                    response.RibbonEn
                );
                i18n.addResource(
                    'zh-Hant',
                    'translation',
                    'RIBBON_TEXTKEY',
                    response.RibbonTc
                );
                i18n.addResource(
                    'zh-Hans',
                    'translation',
                    'RIBBON_TEXTKEY',
                    response.RibbonSc
                );
                setRemoteValues(response);
            }
        };

        if (ipReady) {
            fetchRemoveValues();
        }
    }, [ipReady]);

    const handleConnectButton = () => {
        // collapseNavBar();
        // setShowConnectModal(true);
        connect();
    };

    const collapseNavBar = () => {
        setShowNavBar(false);
        setShowAboutSections(false);
    };

    const toggleAboutSectionMenus = () => {
        if (showAboutSections) {
            setShowAboutSections(false);
        } else {
            setShowAboutSections(true);
        }
    };

    const scrollToTop = () => {
        collapseNavBar();
        history.push('/');
    };

    const goToChestSale = () => {
        collapseNavBar();
        history.push('/chest-sale');
    };

    const goToBuyTower = () => {
        collapseNavBar();
        history.push('/buy-tower/matic');
    };

    const scrollToHero = () => {
        collapseNavBar();
        history.push('/', { hero: true, connected: selectedAccount != null });
    };

    const scrollToLatestNews = () => {
        collapseNavBar();
        history.push('/news', { news: true, connected: selectedAccount != null });
    };

    const goToInventory = () => {
        collapseNavBar();
        history.push('/inventory/cards');
    };

    const goToHomeTower = () => {
        collapseNavBar();
        history.push('/tower-token-intro', {
            tower_token_intro: true,
            connected: selectedAccount != null,
        });
    };

    const goToHomePlatform = () => {
        collapseNavBar();
        history.push('/platform', {
            platform: true,
            connected: selectedAccount != null,
        });
    };

    const goToHomeUtility = () => {
        collapseNavBar();
        history.push('/utility', {
            utility: true,
            connected: selectedAccount != null,
        });
    };

    const goToEarnTower = () => {
        collapseNavBar();
        history.push('/earn-tower');
    };

    const scrollToWhereToGetTower = () => {
        collapseNavBar();
        history.push('/get-tower', { get_tower: true, connected: selectedAccount != null, });
    };
    
    const scrollToTowerIsAvailableOn = () => {
        collapseNavBar();
        history.push('/available-on', { available_on: true, connected: selectedAccount != null, });
    };

    const renderNetworkName = (networkName) => {
        if(networkName === 'ethereum') {
            return 'Ethereum'
        }
        else if(networkName === 'polygon') {
            return 'Polygon'
        }
        else if(networkName === 'bsc') {
            return 'BNB'
        }
        else {
            return 'Select Network'
        }
    }

    const renderNetworkDropDownItem = () => {
        const networkList = ['ethereum', 'bsc', 'polygon'];
        return networkList.map((networkDropDownItem, index) => {
            // we are required to show cursor pointer only on those network items that are not currently selected
            const cursorPointerClass = (networkDropDownItem !== networkSelected) ? `${styles.cursor_pointer}` : ``;
            return (
                <div className={`${styles.network_dropdown_item} ${cursorPointerClass}`} onClick={() => handleNetworkChange(networkDropDownItem)} key={index}>
                    <img className={styles.network_dropdown_item_img} src={globalNetworkImgMap[networkDropDownItem]} alt="dropdown" />
                    <div className={styles.network_dropdown_item_text}>{renderNetworkName(networkDropDownItem)}</div>
                    { networkSelected === networkDropDownItem ? <FontAwesomeIcon icon={faCheck} className={styles.network_dropdown_item_tick}/> : <></> }
                </div>
                
            );
        });
    }

    const RenderNetworkList = (
        <div className={styles.network_and_towers}>
            <div className={styles.network_logo_name_plus_unseen_span}>
                <div className={styles.network_logo_name}>
                    { networkSelected !== '' ? <img className={styles.network_logo} src={globalNetworkImgMap[networkSelected]} /> : <></> }
                    <div className={styles.network_list}>
                        <span className={styles.select_network_text}>{'Select Network'}</span>
                        <hr className={styles.horizontal_ruler} />
                        <div className={styles.network_dropdown_list}>
                            { renderNetworkDropDownItem() }
                        </div>
                    </div>
                    <div className={styles.network_name}>{renderNetworkName(networkSelected)}</div>
                    <FontAwesomeIcon className={styles.network_chevron_arrow} icon={faChevronDown} />
                </div>

                {/* Unseen span section; used for hover effect */}
                <span className={styles.unseen_span}></span>
            </div>
            

            <div className={styles.vertical_line}></div>
            <div className={styles.towers}>
                <img onClick={()=>history.push('/')} src={towerLogo} alt="tower-logo" className={styles.tower_logo}></img>
                <div className={styles.tower_text}>{state.tokenBalance}</div>
                <FontAwesomeIcon className={styles.add_tower_icon} onClick={ () => setShowAddTowerModal(true) } icon={faPlusSquare} />
            </div>
            
        </div>
    );

    const RenderWalletConnectButton =
        selectedAccount && ready ? (
            <Button
                variant="cdh-general-white"
                className={` ${styles.btn_connect} ${styles.btn_connected_mobile}`}
            >
                <div className={styles.description_parent}>
                    <img src={(provider?.isCoinbaseWallet || provider?.isCoinbaseBrowser) ? coinbaseLogo : metamaskLogo} alt="wallet" />
                    <span className={styles.description}>
                        {middleEllipsis(selectedAccount)}
                    </span>
                    <FontAwesomeIcon onClick={disconnect} icon={faSignOutAlt} />{' '}
                </div>
            </Button>
        ) : (
            <Button
                variant="cdh-general-blue"
                className={styles.btn_connect}
                onClick={handleConnectButton}
            >
                <div className={`${styles.btn_ornament} ${styles.left_ornament}`}></div>
                {t('CONNECT WALLET')}
                <div className={`${styles.btn_ornament} ${styles.right_ornament}`}></div>
            </Button>
        );

    const RenderDefaultLinks = (
        <>
            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={() => toggleAboutSectionMenus()}
            >
                <span>{t('ABOUT_TOWER')}</span>
                {showAboutSections ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                )}
            </div>
            {showAboutSections ? (
                <Navbar.Collapse
                    className={styles.navbar_collapse_child}
                >
                    <Nav
                        className={`${styles.navbar_collapse_nav_child}`}
                    >
                        <div
                            className={`${styles.mobile_only} ${styles.nav_div}`}
                            onClick={goToHomeTower}
                        >
                            <span>{t('WHAT_IS_TOWER')}</span>
                        </div>
                        <div
                            className={`${styles.mobile_only} ${styles.nav_div}`}
                            onClick={goToHomePlatform}
                        >
                            <span>{t('PLATFORM')}</span>
                        </div>
                        <div
                            className={`${styles.mobile_only} ${styles.nav_div}`}
                            onClick={goToHomeUtility}
                        >
                            <span>{t('UTILITY')}</span>
                        </div>
                        <div
                            className={`${styles.mobile_only} ${styles.nav_div}`}
                            onClick={scrollToWhereToGetTower}
                        >
                            <span>{t('WHERE_GET_TOWER')}</span>
                        </div>
                            <div
                            className={`${styles.mobile_only} ${styles.nav_div}`}
                            onClick={scrollToTowerIsAvailableOn}
                        >
                            <span>{t('DEXS_AND_CEXS')}</span>
                        </div>
                        <div
                            className={`${styles.mobile_only} ${styles.nav_div}`}
                            onClick={scrollToLatestNews}
                        >
                            <span>{t('NEWS')}</span>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            ) : null}
            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={() => {collapseNavBar(); history.push('/fusion');}}
            >
                <img src={IconNew} alt="new" className={styles.store_new_text_img} style={{ objectFit: "contain" }} />
                <span>{t('Unknown Device')}</span>
            </div>
            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={goToBuyTower}
            >
                <span>{t('SWAP_TOWER')}</span>
            </div>
            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={goToEarnTower}
            >
                <span>{t('EARN_TOWER')}</span>
            </div>
            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={() => {collapseNavBar(); history.push('/store');}}
            >
                <img src={IconNew} alt="new" className={styles.store_new_text_img} style={{ objectFit: "contain" }} />
                <span>{t('TOWER Web Store')}</span>
            </div>
            
            {/* <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={goToChestSale}
            >
                <span>{t('CHEST_SALE')}</span>
            </div> */}
            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={goToInventory}
            >
                <span>{t('INVENTORY')}</span>
            </div>

            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={() => {collapseNavBar(); history.push('/cdh-x-bayc');}}
            >
                {/* <img src={IconNew} alt="new" className={styles.store_new_text_img} style={{ objectFit: "contain" }} /> */}
                <span>CDH x BAYC</span>
            </div>
        </>
    );

    const RenderCoinbaseLinks = (
        <>
            <div
                className={`${styles.mobile_only} ${styles.nav_div}`}
                onClick={() => toggleAboutSectionMenus()}
            >
                <span>{t('About')}</span>
                {showAboutSections ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                )}
            </div>
            {showAboutSections ? (
                <Navbar.Collapse
                    className={styles.navbar_collapse_child}
                >
                    <Nav
                        className={`${styles.navbar_collapse_nav_child}`}
                    >
                        <HashLink to="/#events"><div className={`${styles.mobile_only} ${styles.nav_div}`}>{t('Events')}</div></HashLink>
                        <HashLink to="/#what-is-tower"><div className={`${styles.mobile_only} ${styles.nav_div}`}>{t('What is TOWER?')}</div></HashLink>
                        <HashLink to="/#game-play-and-earn"><div className={`${styles.mobile_only} ${styles.nav_div}`}>{t('Game · Play And Earn')}</div></HashLink>
                        <HashLink to="/#nfts-hold-claim-stake"><div className={`${styles.mobile_only} ${styles.nav_div}`}>{t('NFTs · Hold to Claim & Stake')}</div></HashLink>
                        <HashLink to="/#tower-league-hero-points"><div className={`${styles.mobile_only} ${styles.nav_div}`}>{t('Tower League · Hero Points')}</div></HashLink>
                        <HashLink to="/#web-store"><div className={`${styles.mobile_only} ${styles.nav_div}`}>{t('Web Store')}</div></HashLink>
                        <HashLink to="/#news"><div className={`${styles.mobile_only} ${styles.nav_div}`}>{t('News')}</div></HashLink>
                    </Nav>
                </Navbar.Collapse>
            ) : null}
            <div className={`${styles.mobile_only} ${styles.nav_div}`} onClick={goToBuyTower}>{t('Get TOWER')}</div>
            <div className={`${styles.mobile_only} ${styles.nav_div}`} onClick={() => {collapseNavBar(); history.push('/earn-tower')}}>
                {t('EARN_TOWER')}
            </div>
            <div className={`${styles.mobile_only} ${styles.nav_div}`} onClick={() => {collapseNavBar(); history.push('/store')}}>
                {t('Web Store')}
            </div>
            <div className={`${styles.mobile_only} ${styles.nav_div}`} onClick={() => {collapseNavBar(); history.push('/achievements')}}>
                {t('ACHIEVEMENTS')}
            </div>
            <div className={`${styles.mobile_only} ${styles.nav_div}`} onClick={() => {collapseNavBar(); history.push('/inventory/cards')}}>
                {t('INVENTORY')}
            </div>
            <div className={`${styles.mobile_only} ${styles.nav_div}`} onClick={() => {collapseNavBar(); history.push('/fusion')}}>
                {t('Unknown Device')}
            </div>
            <div className={`${styles.mobile_only} ${styles.nav_div}`} onClick={() => {collapseNavBar(); history.push('/cdh-x-bayc')}}>
                BAYC
        </div>
        </>
    );

    return (
        <div
            className={`${
                location.pathname === '/privacy-policy' ||
                location.pathname === '/binance-nft' ||
                location.pathname === '/open-chest'
                    ? 'd-none'
                    : 'static'
            }`}
        >
            {remoteValues ? (
                remoteValues.RibbonEnabled ? (
                    <TopRibbon />
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
            <Navbar
                collapseOnSelect
                expand="lg"
                variant="light"
                expanded={showNavBar}
                onToggle={setShowNavBar}
                className={`${styles.container_header}`}
            >
                <Navbar.Brand
                    className={`${styles.mobile_only} ${styles.nav_brand}`}
                >
                    <img
                        src={towerLogo}
                        alt="Tower"
                        className={styles.tower_logo}
                        onClick={scrollToTop}
                    />
                </Navbar.Brand>
                <Navbar.Toggle className={styles.toggle_btn}>
                    {/* {showNavBar ? (
                        <FontAwesomeIcon icon={faTimes} />
                    ) : (
                        <FontAwesomeIcon icon={faBars} />
                    )} */}
                    <FontAwesomeIcon icon={faBars} />
                </Navbar.Toggle>

                <div />
                <Navbar.Collapse
                    id="navbar-menu"
                    className={styles.navbar_collapse}
                >
                    <Nav className={styles.navbar_collapse_nav}>
                        <div className={styles.wallet_related}>
                            <div className={styles.token_details}>
                                
                                {/* Hide the pending tower amounts */}
                                {/* {earnTowerLoggedIn ? (
                                    <div className={styles.tower_rewards_container}>
                                        <div className={styles.towerGoldContainer}>
                                            <img src={towerGoldLogo} alt="tower-gold"></img>
                                            <div className={styles.divider_gold}></div>

                                            <div className={styles.tower_text}>
                                                {eventEarningToken}
                                            </div>
                                            <div className={`${styles.popover} ${styles.arrowTop}`}>
                                                <div className={styles.pop_over_content}>
                                                    <span>{t('WAITING_TO_BE_TRANSFERRED')}</span>
                                                    <div>
                                                        <img src={polygonLogo} alt="polygon" />
                                                        <span>{t('Polygon(MATIC) Network')}</span>
                                                    </div>
                                                    <a
                                                        href={'https://medium.com/tower-token'}
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                        className={styles.link}
                                                    >
                                                        {t('HOW_TO_EARN_THIS')}
                                                    </a>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className={styles.content}>
                                            <span>{t('WAITING_TO_BE_TRANSFERRED')} {t('Polygon(MATIC) Network')}</span>
                                            <a
                                                href={
                                                    'https://medium.com/tower-token'
                                                }
                                                target='_blank' rel="noopener noreferrer"
                                                className={styles.link}
                                            >
                                                {t('HOW_TO_EARN_THIS')}
                                            </a>
                                        </div>

                                        <FontAwesomeIcon
                                            icon={faLongArrowAltRight}
                                            color="#69341D"
                                            className={styles.arrow}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )} */}

                                {RenderNetworkList}
                            </div>
                            {RenderWalletConnectButton}
                        </div>

                        {/* {CoinbaseCampaignName === window.location.host.split(".")[0]
                            ? RenderCoinbaseLinks
                            : RenderDefaultLinks
                        } */}

                        {RenderCoinbaseLinks}

                        {/* Hide Language Switching option from Nav bar, to be moved to footer */}
                        {/* <div className={styles.translation}>
                            <Nav className={styles.lang_select}>
                                <LanguageSwitcher
                                    content={{ callback: collapseNavBar }}
                                />
                            </Nav>
                        </div> */}

                        {/* {RenderWalletAddressForMobile} */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <AddTowerModal showAddTowerModal={showAddTowerModal} setShowAddTowerModal={setShowAddTowerModal} />

            <ConnectModal
                content={{
                    show: showConnectModal,
                    showSetter: setShowConnectModal,
                }}
            />
        </div>
    );
};

export { Navigation };
