import React from 'react';
import firebase from "firebase";
import ReactDOM from 'react-dom';
import './index.scss';
import App from './main/App';
import * as serviceWorker from './serviceWorker';
import { ABIContextProvider} from './components/Context/ABIContext';
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID,
//   auth: process.env.REACT_APP_GTM_AUTH,
//   preview: process.env.REACT_APP_GTM_PREVIEW
// }

// TagManager.initialize(tagManagerArgs)


firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  });

ReactDOM.render(
    <ABIContextProvider>
      <App />
    </ABIContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
