import React, {useEffect, useState} from 'react';
import { MediumNews } from '../../components';
import { useTranslation } from 'react-i18next';
import styles from './LatestNews.module.scss';
import { getMediumArticles } from '../../services/mediumService';
import { HomeSectionTitle } from '../HomeSectionTitle/HomeSectionTitle';

export function LatestNews() {
    const [t] = useTranslation();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchMediumArticles = async () => {
            const response = await getMediumArticles();
            if (response?.status == "ok") {
                setArticles(response.items.slice(0, 3));
            }
        }

        fetchMediumArticles();
    }, []);

    function checkSupportDOMParser() {
        if (!window.DOMParser) {
            return false;
        }
    
        const parser = new DOMParser();
        try {
            parser.parseFromString('x', 'text/html')
        } catch (err) {
            return false;
        }
        return true;
    }

    const purifyContent = (content) => {
        const regExp = /(\<script)\s*[^\>]*\>([^\<]*\<\/script>)?/gi;
        return content.replaceAll(regExp, '');
    }

    const stringToHTML = (content) => {
        const cleanContent = purifyContent(content)
        if (checkSupportDOMParser()) {
            const parser = new DOMParser();
            const html = parser.parseFromString(cleanContent, 'text/html')
            return html.body
        }

        const contentDiv = document.createElement('div')
        contentDiv.innerHTML = cleanContent
        return contentDiv
    }

    const processDescription = (description) => {
        var html = stringToHTML(description);
        var arr = Array.from(html.children);
        const filterComponents = arr.filter(component => component.tagName=="P" && component.tagName!="FIGURE" && component.tagName!="IMG")
        return filterComponents.reduce((wholeContent, thisContent)=> wholeContent += thisContent.innerText, '')
    }

    const processTimeFormat = (dateTime) => {
        const pubDate = new Date(dateTime)
        return (pubDate.toLocaleString('default', {month: 'short'}) + ' ' + pubDate.getDate())
    }

    return (    
        <div className={styles.latest_news} id="latest_news">
            <div className={styles.section_title}>
                <HomeSectionTitle
                    title={t('LATEST_NEWS')}
                />
            </div>

            <div className={styles.news_scroll}>
                {   
                    articles.length > 0
                    ? articles.map (function(object, i) {
                            return <MediumNews key={i} content={{
                                image: object.thumbnail,
                                title: object.title,
                                description: processDescription(object.description),
                                time: processTimeFormat(object.pubDate),
                                redirectUrl: object.guid
                        }}/>
                    })
                    : <></>
                }
            </div>
        </div>
    )
}
