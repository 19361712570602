import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import translationZhHans from './locales/zh-Hans.json';
import translationZhHant from './locales/zh-Hant.json';
import translationEn from './locales/en.json';
import translationKr from './locales/kr.json';
import translationTr from './locales/tr.json';
import translationAr from './locales/ar.json';
import translationEs from './locales/es.json';
import translationFr from './locales/fr.json';
import translationId from './locales/id.json';
import translationIt from './locales/it.json';
import translationMs from './locales/ms.json';
import translationNl from './locales/nl.json';
import translationPtbr from './locales/ptbr.json';
import translationRu from './locales/ru.json';
import translationTh from './locales/th.json';
import translationVi from './locales/vi.json';

import i18n from 'i18next';

const resources = {
  "zh-CN" : {
    translation: translationZhHans
  },
  "zh-SG" : {
    translation: translationZhHans
  },
  "zh-HK" : {
    translation: translationZhHant
  },
  "zh-TW" : {
    translation: translationZhHant
  },
  "zh-Hans": {
    translation: translationZhHans
  },
  "zh-Hant": {
    translation: translationZhHant
  },
  "en": {
    translation: translationEn
  },
  "kr": {
    translation: translationKr
  },
  "tr": {
    translation: translationTr
  },
  "ar": {
    translation: translationAr
  },
  "es": {
    translation: translationEs
  },
  "fr": {
    translation: translationFr
  },
  "id": {
    translation: translationId
  },
  "it": {
    translation: translationIt
  },
  "ms": {
    translation: translationMs
  },
  "nl": {
    translation: translationNl
  },
  "ptbr": {
    translation: translationPtbr
  },
  "ru": {
    translation: translationRu
  },
  "th": {
    translation: translationTh
  },
  "vi": {
    translation: translationVi
  },
}

const splitDomain = window.location.hostname.split(".");
const hostWithoutSubdomainArr = splitDomain.slice(splitDomain.length - 2,splitDomain.length);
const cookieDomain = "." + hostWithoutSubdomainArr.join(".");

const detectionOption = {
  order: ['cookie', 'localStorage','querystring', 'navigator',  'htmlTag', 'path', 'subdomain'],
  lookupQuerystring: 'lang',
  lookupCookie: 'i18next',
  caches: ['cookie'],
  cookieMinutes: 10080,
  cookieDomain: cookieDomain,
}

i18n
    // Load translations by xhr
    .use(initReactI18next)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: "en",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      detection: detectionOption,
      nsSeparator: false
    });

export default i18n;