import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './ConnectModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import metamaskLogo from '../../assets/icons/icon-metamask.png';
import { Web3Context } from '../../web3/Web3Context';

export function ConnectModal(props) {
    const { show, showSetter } = props.content || {};
    const { connect } = useContext(Web3Context);
    const metamaskInstallUrl = "https://metamask.app.link/dapp/crazydefenseheroes.com/";
    const [t] = useTranslation();

    const handleMetamaskIconClick = () => {
        if (typeof window.ethereum === 'undefined') {
            handleNotSupportedMetamask();
        } else {
            handleWalletConnect();
        }
    }

    const handleNotSupportedMetamask = () => {
        window.open(metamaskInstallUrl, "_blank");
    }

    const handleWalletConnect = async () => {
        try {
            handleCloseModal();
            await connect({connector: "metamask"});
        } catch (e)
        {
            console.log(e);
        }
	};

    const handleCloseModal = () => {
        showSetter(false);
    }

    const RenderModalConnectButton = (
        typeof window.ethereum === 'undefined'
            ?
                <>
                    <div>
                        <Button variant="cdh-general-blue" className={styles.modal_btn_connect} onClick={handleNotSupportedMetamask}>{t('GET_METAMASK')}</Button>
                    </div>
                    <div className={styles.connect_description}>
                        {t('NO_METAMASK_DETECTED')}
                    </div>
                </>
            :
            <Button variant="cdh-general-blue" className={styles.modal_btn_connect} onClick={handleWalletConnect}>{t('CONNECT')}</Button>
    );

    return (
        <Modal show={show} onHide={handleCloseModal} centered>
                <Modal.Body className={styles.connect_modal}>
                <div className={styles.close_btn} onClick={handleCloseModal}><FontAwesomeIcon icon={faTimes}/></div>
                <div className={styles.title}>{t('PLEASE_CONNECT_WALLET')}</div>
                <div className={styles.wallets_section}>
                    <div className={`${styles.choose_wallet} ${styles.active}`} onClick={handleMetamaskIconClick}>
                        <img src={metamaskLogo} />
                        <span>{t('METAMASK')}</span>
                    </div>
                    <div className={styles.wallet_description}>{t('BROWSER_EXTENSION')}</div>
                </div>
                <section className={styles.connect_section}>
                    {RenderModalConnectButton}
                </section>
            </Modal.Body>
        </Modal>
    );
}
