import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Login } from "../Login";
import { TYPES } from "./ActionTypes";
import { useCookies } from 'react-cookie';
import { useContext } from "react";
import { IpContext } from "./IpContext";
import firebase from "firebase";
import LoginAvatar from '../../assets/imgs/avatar_narrator_v2.png';

const initialState = {
    userDetails: {},
    userWalletDetails: {},
    showLogin: false,
    loginTitle: 'Login to CDH',
    loginDescription: 'Please login your CDH User ID to complete your action.',
    loginCharacerImage: LoginAvatar,
};

const UserContext = React.createContext();

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_ON_LOGIN:
            return {
                ...state,
                userDetails: action.payload.userDetails,
                userWalletDetails: action.payload.userWalletDetails,
            };

        case TYPES.SET_ON_LOGOUT:
            return {
                ...initialState,
            };

        case TYPES.SET_SHOW_LOGIN_MODAL:
            const {
                show,
                title,
                description,
                characterImage
            } = action.payload;

            const updatedState = {
                ...state,
                showLogin: show,
            }
            if (title) updatedState.loginTitle = title;
            if (description) updatedState.loginDescription = description;
            if (characterImage) updatedState.loginCharacerImage = characterImage;

            return {
                ...updatedState,
            }

        default:
            return state;
    }
}

const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [t] = useTranslation();
    const [siwaCookies, removeSiwaCookies] = useCookies(['SiwaAuthId']);
    const { isInChina } = useContext(IpContext);

    const updateOnLogin = (userDetails, userWalletDetails) => {
        dispatch({
            type: TYPES.SET_ON_LOGIN,
            payload: {userDetails, userWalletDetails}
        });

        const loggedInPages = JSON.parse(localStorage.getItem('showLoginOnLoad'));
        let updatedLoggedInPages = loggedInPages || {};
        Object.keys(updatedLoggedInPages).forEach((pages) => {
            updatedLoggedInPages[pages] = true;
        });
        updatedLoggedInPages['show'] = true;
        localStorage.setItem('showLoginOnLoad', JSON.stringify(updatedLoggedInPages));
    }

    const logout = () => {
        if (siwaCookies.SiwaAuthId != null) {
            removeSiwaCookies('SiwaAuthId', { path: '/' });
        }
        if (!isInChina) {
            firebase.auth().signOut();
        }

        dispatch({
            type: TYPES.SET_ON_LOGOUT,
        });

        const loggedInPages = JSON.parse(localStorage.getItem('showLoginOnLoad'));
        let updatedLoggedInPages = loggedInPages || {};
        Object.keys(updatedLoggedInPages).forEach((pages) => {
            updatedLoggedInPages[pages] = false;
        });
        localStorage.setItem('showLoginOnLoad', JSON.stringify(updatedLoggedInPages));
    }

    const showLoginModalHandler = ({ show, title, description, image }) => {
        dispatch({
            type: TYPES.SET_SHOW_LOGIN_MODAL,
            payload: {
                show,
                title,
                description,
                characterImage: image,
            }
        });
    }

    const value = {
        showLogin: state.showLogin,
        userDetails: state.userDetails,
        userWalletDetails: state.userWalletDetails,
        updateOnLogin,
        logout,
        showLoginModalHandler,
    }

    return (
        <UserContext.Provider value={value}>
            {children}

            {state.showLogin &&
                <Login
                    title={t(state.loginTitle)}
                    descriptions={t(state.loginDescription)}
                    loginCharacterImage={state.loginCharacerImage}
                    setShow={(show) => showLoginModalHandler({show})}
                />
            }
        </UserContext.Provider>
    )
}

export { UserProvider, UserContext };