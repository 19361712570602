import React, { useEffect, useState } from 'react';
import styles from './SocialMediaSidebar.module.scss';

import discordLogo from '../../assets/icons/icon-discord.svg';
import mediumLogo from '../../assets/icons/icon-medium.svg';
import telegramLogo from '../../assets/icons/icon-telegram.svg';
import twitterLogo from '../../assets/icons/icon-twitter.svg';
import weiboLogo from '../../assets/icons/icon-weibo-color.svg';
import wechatLogo from '../../assets/icons/icon-wechat-color.svg';
import facebookLogo from '../../assets/icons/icon-facebook-color.svg';
import instagramLogo from '../../assets/icons/icon-instagram-color.svg';
import iconX from '../../assets/icons/icon-X.svg';
import iconMore from '../../assets/icons/more.svg';
import OpenSeaLogo from '../../assets/icons/icon-opensea-simple.svg';
import OnePlanetLogo from '../../assets/icons/icon-oneplanet.png';
import BinanceLogo from '../../assets/imgs/home/icon-bsc-circle.png';


const getScrollPercentage = (element) => {
    let parent = element.parentNode;
    return Math.ceil((element.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight ) * 100);
}

const SocialMediaSidebar = () => {
    const [iconsExtend, setIconsExtend] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(false);
    const [screenLoc, setScreenLoc] = useState(window.scrollY);

    const updateScrollLoc = () => {
        setScreenLoc(window.scrollY);
    }

    window.addEventListener('scroll', updateScrollLoc);

    useEffect(() => {
        let scrollPercentage = getScrollPercentage(window.document.body) || 0;
        if ((scrollPercentage < 8 && window.screen.width < 992) || (scrollPercentage > 97)) {
            setHideSidebar(true);
        } else {
            setHideSidebar(false);
        }
    }, [screenLoc])

    const socialsFirstColumn = {
        openSea: { link: 'https://opensea.io/collection/tower-battle-cards', logo: OpenSeaLogo },
        binance: { link: 'https://www.binance.com/en/nft/search-result?isVerified=1&assetType=1&keyword=TOWER+Ticket&tab=nft&order=amount_sort%401', logo: BinanceLogo },
        onePlanet: { link: 'https://www.oneplanetnft.io/collections/items/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2?utm_source=oneplanet&utm_medium=display&utm_campaign=main+banner&sort=price_low_to_high', logo: OnePlanetLogo },
        discord: { link: 'https://discord.gg/MzKppSCKVu', logo: discordLogo },
    };

    const socialsSecondColumn = {
        openSea: { link: 'https://opensea.io/collection/tower-battle-cards', logo: OpenSeaLogo },
        binance: { link: 'https://www.binance.com/en/nft/search-result?isVerified=1&assetType=1&keyword=TOWER+Ticket&tab=nft&order=amount_sort%401', logo: BinanceLogo },
        onePlanet: { link: 'https://www.oneplanetnft.io/collections/items/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2?utm_source=oneplanet&utm_medium=display&utm_campaign=main+banner&sort=price_low_to_high', logo: OnePlanetLogo },
        discord: { link: 'https://discord.gg/MzKppSCKVu', logo: discordLogo },
        twitter: { link: 'https://twitter.com/TowerToken', logo: twitterLogo },
        telegram: { link: 'https://t.me/TowerToken', logo: telegramLogo },
        medium: { link: 'https://medium.com/tower-token', logo: mediumLogo },
        facebook: { link: 'https://www.facebook.com/TowerTokenAB/', logo: facebookLogo },
        instagram: { link: 'https://www.instagram.com/crazydefenseheroes', logo: instagramLogo },
        wechat: { link: 'https://crazydefenseheroes.com/wechat-qr.jpg', logo: wechatLogo },
        weibo: { link: 'https://weibo.com/CrazyDefenseHeroes', logo: weiboLogo },
    };

    const renderLinks = (links) => {
        return Object.keys(links).map((sm, i) => {
            return (
                <div className={styles.link} key={i}>
                    <a href={links[sm].link} target="_blank" rel="noopener noreferrer">
                        <img src={links[sm].logo} alt="links-button" />
                    </a>
                </div>
            );
        });
    };

    const handleExpand = (value) => {
        setIconsExtend(!value);
    }

    return (
        <div>
            {
                !hideSidebar &&
                <div className={iconsExtend === false ? styles.social_media_sidebar : `${styles.social_media_sidebar} ${styles.extend}`}>
                    <div className={styles.social_links}>
                        {/* Two Columns */}
                        {/* <div className={styles.links_set}>
                            {renderLinks(socialsFirstColumn)}
                        </div>
                        <div className={styles.links_set}>
                            {renderLinks(socialsSecondColumn)}
                        </div> */}

                        {/* One Column */}
                        <div className={styles.links_set}>
                            {iconsExtend
                                ? renderLinks(socialsSecondColumn)
                                : renderLinks(socialsFirstColumn)
                            }
                        </div>
                    </div>
                    <div className={styles.socials_extend_menu} onClick={() => handleExpand(iconsExtend)}>
                        {
                            iconsExtend === false ?
                                <img src={iconMore} alt="more" /> :
                                <img src={iconX} alt="cross" />
                        }
                    </div>
                </div> 
            }
        </div>
    );
}

export { SocialMediaSidebar };