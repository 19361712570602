import { ethers } from "ethers";
import { fromWei } from "web3-utils";

const contractNames = {
    1: "TOWER",
    5: "TOWER",
    137: "PolygonTower",
    80001: "PolygonTower",
    56: "BscTower",
    97: "BscTower",
}

const createNetworkContract = async (PROVIDER, DEPLOYMENT_CONTEXT, CONTRACT_NAME) => {
    try {
        if (PROVIDER) {
            const contract = new ethers.Contract(
                DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].address,
                DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].abi,
                PROVIDER,
            );
            return contract;
        }
    } catch (e) {
        console.log(e);
    }
};

export const fetchTowerBalance = async (networkId, selectedAccount, PROVIDER, DEPLOYMENT_CONTEXT, truncate) => {
    try {
        const contract = await createNetworkContract(PROVIDER, DEPLOYMENT_CONTEXT, contractNames[networkId]);
        const res = await contract.balanceOf(selectedAccount);
        const fromWeiBalance = Number(fromWei(res.toString()));
        let balance = (Math.round(fromWeiBalance * 100) / 100).toFixed(2);
        if (truncate) {
            balance = Math.trunc(balance);
        }
        return balance;
    } catch (e) {
        console.error(e)
    }
}