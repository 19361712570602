import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GeneralModal.module.scss';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import etherscanIcon from '../../assets/icons/icon-etherscan.svg';
import polygonscanIcon from '../../assets/icons/icon-polygon.png';

function GeneralModal(props) {
    const { show, showSetter, title, description, buttonText, buttonCallback, etherscanAddress, polygonscanAddress } = props.content || {};
    const [t] = useTranslation();

    const closeModal = () => {
        showSetter(false);
    }

    const renderViewOnEtherscan = (
        etherscanAddress ?
            <div className={styles.view_on_etherscan}>
                <img src={etherscanIcon} className={styles.icon} /> <a href={ etherscanAddress } target="_blank">{t('VIEW_ON_ETHERSCAN')}</a>
            </div>
            :
            polygonscanAddress ? 
                <div className={styles.view_on_etherscan}>
                    <img src={polygonscanIcon} className={styles.icon} alt="polygon" /> <a href={ polygonscanAddress } target="_blank">{t('GIFT_VIEW_PROCESS_POLYSCAN_LINK_BTN')}</a>
                </div> :
                <></>
    );

    return (
        <>
            <Modal show={show} className={styles.general_modal} onHide={closeModal} centered>
                <div className={styles.close_btn} onClick={closeModal}><FontAwesomeIcon icon={faTimes}/></div>
                <Modal.Header className={styles.modal_header}>
                    <Modal.Title className={styles.general_title}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.general_modal_body}>
                    <div>{description}</div>
                </Modal.Body>
                <Modal.Footer className={styles.general_modal_footer}>
                    <Button variant="cdh-general-blue" className={styles.general_ok_btn} onClick={buttonCallback}>{buttonText}</Button>
                    {renderViewOnEtherscan}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { GeneralModal }