import React, { Fragment, useState, useRef } from 'react';
import { Modal, Overlay, Tooltip } from 'react-bootstrap';
import styles from './TransferCardModal.module.scss';
import closeBtn from '../../assets/icons/closeIcon.svg';
import polygon from '../../assets/icons/polygon-matic-logo-purple-bg-24x24-1x.png';
import polygon1 from '../../assets/icons/polygon-matic-logo-purple-bg-24x24-2x.png';
import polygon2 from '../../assets/icons/polygon-matic-logo52x52.svg';
import fileIcon from '../../assets/icons/file-icon.svg';
import successIcon from '../../assets/icons/icon-success-green.svg';
import successIcon2 from '../../assets/icons/icon-success.svg';
import transferIcon from '../../assets/icons/transfer_horiz_24px.svg';
import arrowDown from '../../assets/icons/icon-arrow-down.svg';
import Spinner from '../Spinner/Spinner';
import { useHistory } from 'react-router-dom';
import { GeneralModal } from '../GeneralModal';
import { useTranslation } from 'react-i18next';


const POLYGON_SCAN_URL = process.env.REACT_APP_POLYGON_BLOCK_EXPLORER_URL;

const TransferCardModal = (props) => {
  const { openModal, setOpenModal, itemImg, onTransferTokenToUser, chestType, web3, selectedTransferCard, maxAmount } = props;
  
  const [walletAddress, setWalletAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [txHashLink, setTxHashLink] = useState('');
  const [signTransfer, setSignTransfer] = useState(false);
  const history = useHistory();
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef(null)
  const [errorMsg, setErrorMsg] = useState({
    validationError: '',
    transferError: ''
  });
  const [verifyAddress, setVerifyAddress] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [t] = useTranslation()
  

  // let web3 = new Web3();

  const closeMessageModal = () => {
    setErrorMsg({
      validationError: '',
      transferError: ''
    })
    setShowMessageModal(false);
  }

  const transferCardSubmit = (e) => {
    e.preventDefault();
    onTransferTokenToUser(walletAddress, selectedTransferCard, 1, "0x00", setLoading, setTransfer, setTxHashLink, setSignTransfer, setShowMessageModal, setErrorMsg);
  };

  const transferChestSubmit = (e) => {
    e.preventDefault();
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?A-Za-z]+/;
    if(parseInt(amount) <= 0 || format.test(amount)) {
      setErrorMsg((prevState) => {
        return {...prevState, validationError: 'Amount must be a positive integer greater than 0'}
      })
    }  else {
      setErrorMsg({validationError: '', transferError: '' });
      setAmount(amount.replace(/^0+/,''));
      let fixedAmount = web3.utils.toWei(amount, 'ether');
      onTransferTokenToUser(walletAddress, fixedAmount, setLoading, setTransfer, setTxHashLink, setSignTransfer, setShowMessageModal, setErrorMsg);
    }
  };

  const closeModal = () => {
    setLoading(false);
    setTransfer(false);
    setOpenModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress)
  }

  const handleTooltip = () => {
    if (window.location.protocol !== "https:") {
      setShowMessageModal(true);
    } else {
      setShowTooltip(true);
      copyToClipboard();
      setTimeout(() => {
      setShowTooltip(false);
    }, 1500)
    }
    
  }

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }

  const handleAmountChange = (e) => {
    setAmount(e.target.value)
    if(e.target.value > maxAmount) {
      setErrorMsg((prevState) => {
        return {...prevState, validationError: `Amount should not exceed ${maxAmount}`}
      })
    }
    else {
      setErrorMsg({validationError: '', transferError: '' });
    }

  }

  const handleAddressChange = (e) => {
    setWalletAddress(e.target.value);
    try {
      const address = web3.utils.toChecksumAddress(e.target.value)
      if(address && e.target.value.length == 42) {
        setVerifyAddress(true)
      } else {
        setVerifyAddress(false)
      }
    } catch(e) { 
      setVerifyAddress(false);
      console.error('invalid ethereum address', e.message) 
    }
  }

  const pasteAddress = async () => {
    if (window.location.protocol !== "https:") {
      setShowMessageModal(true);
    } else {
      if(!verifyAddress) {
        const clipText = await navigator.clipboard.readText();
        setWalletAddress(clipText)
        try {
          const address = web3.utils.toChecksumAddress(clipText)
          if(address && clipText.length == 42) {
            setVerifyAddress(true)
          } else {
            setVerifyAddress(false)
          }
        } catch (error) {
          setVerifyAddress(false);
        }
        
      }
    }
    
    
  }



  return (
    <Fragment>
      <Modal
        contentClassName={styles.modalDialog}
        centered
        show={openModal}
        onHide={closeModal}
      >
        <div className={styles.modalBody}>
          <button className={styles.closeBtn} onClick={closeModal}>
            <img src={closeBtn} alt='' />
          </button>
          <div className={styles.bodyTop}>
            {props.isCard ? (
              <div className={styles.cardImg}>
                <img src={itemImg} alt='card Image' />
                <img className={styles.polygonIcon} src={polygon} alt='' />
              </div>
            ) : (
              <div className={styles.chestImg}>
                <img src={itemImg} alt='' />
                <img
                  className={transfer ? styles.chest_successIcon : 'd-none'}
                  src={successIcon2}
                  alt=''
                />
              </div>
            )}
          </div>
          {props.isCard ? (
            <h5 className={styles.sendCard_title}>
              {transfer
                ? `${t('GIFT_VIEW_COMPLETE_TITLE_CARD')}`
                : `${t('GIFT_VIEW_CONTENT_CARD')}`}
            </h5>
          ) : (
            <h5 className={styles.sendCard_title}>
              {signTransfer
                ? `${t(`${props?.chestType.split(" ").join("_")}_WILL_BE_TRANSFERRED_TO`)}`
                : transfer
                ? `${amount} ${
                    props?.chestType.charAt(0) +
                    props?.chestType.slice(1).toLowerCase()
                  } ${t('TRANSFER_SUCCESS_TO_POLYGON')}`
                : `${t(`TRANSFER_${props?.chestType.split(" ").join("_")}_AS_GIFT`)}`}
            </h5>
          )}
          {props.isCard ? (
            <div className={styles.form_container}>
              {transfer ? (
                <div className={styles.transfer_wrapper}>
                  <div className={styles.transfer_network}>
                    <div className={styles.network}>
                      <img src={polygon1} alt='' />
                      <div className={styles.network_text}>
                        <span>{t('NETWORK')}</span>
                        <h5>{t('POLYGON')}</h5>
                      </div>
                    </div>
                    <div className={styles.transfer_address}>
                      <h5 className={styles.address_lg}>{`${walletAddress.substr(0,11)}...${walletAddress.substr(walletAddress.length - 16)}`}</h5>
                      <h5 className={styles.address_sm}>{`${walletAddress.substr(0,6)}...${walletAddress.substr(walletAddress.length - 4)}`}</h5>
                      <span title='copy' ref={tooltipTarget} onClick={handleTooltip}>
                        <img src={fileIcon} alt='' />
                      </span>
                      <Overlay target={tooltipTarget.current} show={showTooltip} placement="top">
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            {t('COPIED')}
                          </Tooltip>
                        )}
                      </Overlay>
                    </div>
                  </div>
                  <div className={styles.polyscan_link}>
                    <a href={`${POLYGON_SCAN_URL}tx/${txHashLink}`} target={'_blank'} rel='noreferrer' >
                      <img src={polygon2} alt='' />
                      {t('GIFT_VIEW_PROCESS_POLYSCAN_LINK_BTN')}
                    </a>
                  </div>
                  <button
                    className={styles.transferBtn}
                    onClick={() => {
                      closeModal();
                      history.push('/inventory/cards');
                    }}
                  >
                    {t('OK')}
                  </button>
                </div>
              ) : (
                <form onSubmit={transferCardSubmit}>
                  <label>{t('GIFT_VIEW_RECEIVER_LABEL_POLY')}</label>
                  <div className={styles.input_container}>
                    <input
                      type='text'
                      placeholder='e.g 123xxx...1234'
                      value={walletAddress}
                      onChange={handleAddressChange}
                      disabled={signTransfer}
                    />
                    <img
                      className={'d-block'}
                      src={verifyAddress ? successIcon : fileIcon}
                      onClick={pasteAddress}
                      alt=''
                      title={!verifyAddress ? 'Paste' : ''}
                    />
                  </div>
                  <button
                    className={`${styles.transferBtn} ${
                      !verifyAddress ? styles.disabled : 'defaultStyle'
                    }`}
                    type='submit'
                    disabled={!verifyAddress || signTransfer}
                  >
                    <img src={transferIcon} alt='' />
                    {t('TRANSFER')}
                  </button>
                </form>
              )}
            </div>
          ) : (
            <div className={styles.form_container}>
              {transfer ? (
                <div className={styles.transfer_wrapper}>
                  <div className={styles.transfer_network}>
                    <div className={styles.network}>
                      <img src={polygon1} alt='' />
                      <div className={styles.network_text}>
                        <span>{t('NETWORK')}</span>
                        <h5>{t('POLYGON')}</h5>
                      </div>
                    </div>
                    <div className={styles.transfer_address}>
                      <h5 className={styles.address_lg}>{`${walletAddress.substr(0,11)}...${walletAddress.substr(walletAddress.length - 16)}`}</h5>
                      <h5 className={styles.address_sm}>{`${walletAddress.substr(0,6)}...${walletAddress.substr(walletAddress.length - 4)}`}</h5>
                      <span title='copy' ref={tooltipTarget} onClick={handleTooltip}>
                        <img src={fileIcon} alt='' />
                      </span>
                      <Overlay target={tooltipTarget.current} show={showTooltip} placement="top">
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            {t('COPIED')}
                          </Tooltip>
                        )}
                      </Overlay>
                    </div>
                  </div>
                  <div className={styles.polyscan_link}>
                    <a href={`${POLYGON_SCAN_URL}/tx/${txHashLink}`} target={'_blank'} rel="noopener noreferrer" >
                      <img src={polygon2} alt='' />
                      {t('GIFT_VIEW_PROCESS_POLYSCAN_LINK_BTN')}
                    </a>
                  </div>
                  <button
                    className={styles.transferBtn}
                    onClick={() => {
                      closeModal();
                      props?.isCard
                        ? history.push('/inventory/cards')
                        : history.push('/inventory/chests');
                    }}
                  >
                    {t('OK')}
                  </button>
                </div>
              ) : (
                <form onSubmit={transferChestSubmit}>
                  <div className={styles.form_group}>
                    <label>{t('AMOUNT')}</label>
                    <div className={styles.input_container}>
                      <input
                        type='text'
                        placeholder={`Input an amount (Max ${maxAmount})`}
                        value={amount}
                        onChange={handleAmountChange}
                        onKeyPress={handleKeyPress}
                        disabled={signTransfer}
                      />
                      <div
                        className={`${styles.max_notify} ${
                          parseInt(amount) >= maxAmount ? styles.max : 'defaultStyle'
                        }`}
                        onClick={() => {
                          if(!signTransfer) {
                            setAmount(maxAmount.toString());
                            setErrorMsg({
                              validationError: '',
                              transferError: ''
                            })
                          }
                        }}
                        disabled={signTransfer}
                      >
                        {t('BTN_MAX')}
                      </div>
                      <div className={styles.available_balance}>
                        <div className={styles.balance}>
                          {t('AVAILABLE_BALANCE_LABEL')}
                          <span>{maxAmount}</span>
                        </div>
                        <span className={styles.errorMessage}>{errorMsg.validationError}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.arrowIcon}>
                    <img src={arrowDown} alt='' />
                  </div>
                  <div className={styles.form_group}>
                    <label>{t('GIFT_VIEW_RECEIVER_LABEL_POLY')}</label>
                    <div className={styles.input_container}>
                      <input
                        type='text'
                        placeholder='e.g 123xxx...1234'
                        value={walletAddress}
                        onChange={handleAddressChange}
                        disabled={signTransfer}
                      />
                      <img
                        className={'d-block'}
                        src={verifyAddress ? successIcon : fileIcon}
                        onClick={pasteAddress}
                        alt=''
                        title={!verifyAddress ? 'Paste' : ''}
                      />
                    </div>
                  </div>

                  <button
                    className={`${styles.transferBtn} ${
                      (!verifyAddress || errorMsg.validationError.length !==0 || amount.length === 0) ? styles.disabled : 'defaultStyle'
                    }`}
                    type='submit'
                    disabled={(!verifyAddress) || amount.length === 0 || signTransfer || errorMsg.validationError.length !== 0}
                  >
                    <img src={transferIcon} alt='' />
                    {t('TRANSFER')}
                  </button>
                </form>
              )}
            </div>
          )}
        </div>
      </Modal>
      {loading && (
        <Spinner
          show={loading}
          headerText={t('TRANSFER_PROGRESS')}
        />
      )}
      <GeneralModal content={{
        show: showMessageModal,
        showSetter: setShowMessageModal,
        title: t('ERROR'),
        description: errorMsg.transferError.length !== 0 ? t(errorMsg.transferError) : t('Could not paste from clipboard in non SSL site'),
        buttonText: t('OK'),
        buttonCallback: closeMessageModal
        }} 
      />
    </Fragment>
  );
};

export default TransferCardModal;
