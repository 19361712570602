import React, { useState, useEffect } from 'react';
import { checkIfInChina } from '../../services/ipService';

const IpContext = React.createContext();
const IpConsumer = IpContext.Consumer;

const IpProvider = (props) => {
    const [isInChina, setIsInChina] = useState(false);
    const [ipReady, setIpReady] = useState(false);
    const [ eventEarningToken, setEventEarningTokenBalance] = useState('0.00');
    const [earnTowerLoggedIn,setEarnedTowerLoggedIn]=useState(false);
    
    useEffect(() => {
        const fetchCountryCode = async () => {
            const inChina = await checkIfInChina();
            if (inChina) {
                setIsInChina(true);
            } else {
                setIsInChina(false);
            }
            setIpReady(true);
        }

        fetchCountryCode();
    });
    
    return (
        <IpContext.Provider value={{
            isInChina,
            ipReady,
            eventEarningToken,
            setEventEarningTokenBalance,
            earnTowerLoggedIn,
            setEarnedTowerLoggedIn
        }}>
            {props.children}
        </IpContext.Provider>
    );
}

export { IpContext as IpContext, IpProvider, IpConsumer };
