const PROXY_API_URL = process.env.REACT_APP_CDH_BACKEND_PROXY_API_URL;

export async function checkIfInChina() {
    try {
        const postData = await fetch(PROXY_API_URL + '/checkchina', {
            method: 'GET',
            mode: 'cors'
        });

        const result = await postData.json();
        return result == 1 ? true : false;
    } catch (err) {
        console.error('Error fetching data: ' + err)
        return null;
    }
}
