import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container } from 'react-bootstrap';
import styles from './Footer.module.scss';
import animocaLogo from '../../assets/imgs/logo-animoca.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import contractAuditPdf from '../../assets/pdf/CertiK_Audit_Report.pdf';
import { NewsLetterComponent } from '../Newsletter/NewsletterComponent';
import { SocialMediaLinks } from '../SocialMediaLinks/SocialMediaLinks';
import { LanguageSwitcher } from '../LanguageSwitcher';

const Footer = () => {
    const { t } = useTranslation();
    const animocaBrandsUrl = 'https://www.animocabrands.com/';
    const lightPaperUrl = 'https://lightpaper.crazydefenseheroes.com/';
    const privacyPolicyUrl = '/privacy-policy';
    const contactUs = 'mailto:tower-token@animocabrands.com';

    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    const languageSwitcherCallback = () => {
        console.log("Callback function for language switcher");
    }

    return (
        <div className={styles.footerFull}>
            <div className={styles.footerbg}>
                <Container className={styles.footer_container}>
                    <div className={styles.data}>
                        <div className={styles.language_selector_container}>
                            <Button className={styles.language_selector}>
                                <LanguageSwitcher content={{ callback: languageSwitcherCallback }}/>
                            </Button>
                        </div>
                        
                        <div className={styles.social_links_container}>
                            <SocialMediaLinks />
                        </div>

                        <div className={styles.links}>
                            <a href={contractAuditPdf} target="_blank">
                                {t('SMART_CONTRACT_AUDIT_REPORT')}
                            </a>
                            <a href={lightPaperUrl} target="_blank">
                                {t('LIGHTPAPER')}
                            </a>
                            <a href={privacyPolicyUrl} target="_blank">
                                {t('PRIVACY_POLICY')}
                            </a>
                            <a href={window.location.origin + '/Terms_and_Conditions.pdf'} target="_blank">
                                {t('TERMS_AND_CONDITIONS')}
                            </a>
                            <a href={contactUs} target="_blank">
                                {t('CONTACT_US')}
                            </a>
                        </div>
                        <div className={styles.copyright_section}>
                            <a href={animocaBrandsUrl} target="_blank">
                                <img src={animocaLogo} />
                            </a>
                            <div className={styles.copyright_text}>
                                {t('COPYRIGHT')}
                            </div>
                        </div>

                        <div className={styles.mobile_social_links_container}>
                            <div className={styles.horizontal_line}></div>
                            <SocialMediaLinks />
                        </div>
                    </div>
                    <div className={styles.footer_newsletter}>
                        <NewsLetterComponent />
                    </div>
                </Container>
            </div>
            <div className={styles.footer_links_container}>
                <div
                    className={styles.scroll_to_top_container}
                    onClick={scrollToTop}
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </div>
            </div>
        </div>
    );
};

export { Footer };
