const POLYGON_CHAIN_ID = parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID);
const ETH_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID);
const BINANCE_CHAIN_ID = parseInt(process.env.REACT_APP_BINANCE_CHAIN_ID);

const chainName = Object.freeze({
    1: "Ethereum Mainnet",
    5: "Goerli Testnet",
    56: "Smart Chain",
    97: "Smart Chain - Testnet",
    137: "Polygon Mainnet",
    80001: "Matic Polygon Testnet"
});

const rpcUrls = Object.freeze({
    56: [
        'https://bsc-dataseed.binance.org/'
    ],
    97: [
        'https://bsc-testnet.nodereal.io/v1/966e3d10552944a4b7371a2c556df19d'
    ],
    137: [
        'https://polygon-rpc.com ',
        'https://rpc-mainnet.matic.network',
        'https://rpc-mainnet.maticvigil.com',
        'https://matic-mainnet.chainstacklabs.com',
    ],
    80001: [
        "https://matic-mumbai.chainstacklabs.com"
    ]
});

export const PolygonNetworkInfo = {
    "chainId": POLYGON_CHAIN_ID === 137 ? '0x' + (137).toString(16) : '0x' + (80001).toString(16),
    "chainName": POLYGON_CHAIN_ID === 137 ? chainName[137] : chainName[80001],
    "blockExplorerUrls": [process.env.REACT_APP_POLYGON_BLOCK_EXPLORER_URL],
    "rpcUrls": POLYGON_CHAIN_ID === 137 ? rpcUrls[137] : rpcUrls[80001],
    "nativeCurrency": {
        decimals: 18,
        name: 'matic',
        symbol: 'matic',
    },
};

export const EthNetworkInfo = {
    "chainId": ETH_CHAIN_ID === 1 ? '0x' + (1).toString(16) : '0x' + (5).toString(16),
    "chainName": ETH_CHAIN_ID === 1 ? chainName[1] : chainName[5],
    "blockExplorerUrls": [process.env.REACT_APP_ETHEREUM_BLOCK_EXPLORER_URL],
    "rpcUrls": process.env.REACT_APP_ETHEREUM_PROVIDER_URL,
    "nativeCurrency": {
        decimals: 18,
        name: 'ether',
        symbol: 'eth',
    },
};

export const BinanceNetworkInfo = {
    "chainId": BINANCE_CHAIN_ID === 56 ? '0x' + (56).toString(16) : '0x' + (97).toString(16),
    "chainName": BINANCE_CHAIN_ID === 56 ? chainName[56] : chainName[97],
    "blockExplorerUrls": [process.env.REACT_APP_BINANCE_BLOCK_EXPLORER_URL],
    "rpcUrls": BINANCE_CHAIN_ID === 56 ? rpcUrls[56] : rpcUrls[97],
    "nativeCurrency": {
        decimals: 18,
        name: 'bnb',
        symbol: 'bnb',
    },
};
