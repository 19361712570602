import React from 'react';
import styles from './Loading.module.scss';
import loader from '../../assets/imgs/loader.gif';

const Loading = () => {
  return (
    <div className={styles.loading_container}>
      <img src={loader} alt='' />
    </div>
  );
};

export default Loading;
