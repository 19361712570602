export const TYPES = {
    RESET: 'reset',
    INIT_INVENTORY_CONTRACT: 'init-inventory-contract',
    INIT_TICKET_CONTRACT: 'init-ticket-contract',
    INIT_BADGE_CONTRACT: 'init-badge-contract',
    INIT_POLYGON_TICKET_CONTRACT: 'init-polygon-ticket-contract',
    INIT_SKIN_CONTRACT: 'init-skin-contract',
    INIT_POLYGON_SKIN_CONTRACT: 'init-polygon-skin-contract',
    INIT_POLYGON_TOWER_INVENTORY_CONTRACT: 'init-polygon-tower-inventory-contract',
    SET_PROVIDERS: 'set-providers',
    SET_DEPLOYMENT_CONTEXTS: 'set-depoloyment-context',
    SET_USER_ID: 'set-user',
    SET_ON_LOGIN: 'set-on-login',
    SET_ON_LOGOUT: 'set-on-logout',
    SET_SHOW_LOGIN_MODAL: 'set-show-login-modal',
    SET_TOKEN_BALANCE: 'set-token-balance',
    SET_CHEST_COUNT_MATIC: 'set-chest-count-matic',
    SET_CHEST_COUNT_GOERLI: 'set-chest-count-goerli',
    SET_TICKET_COUNT_BSC: 'set-ticket-count-bsc',
    SET_MAP_COUNT_MATIC: 'set-map-count-matic',
    SET_TICKET_COUNT_MATIC: 'set-ticket-count-matic',
    SET_SKIN_COUNT_BSC: 'set-skin-count-bsc',
    SET_SPECIAL_SKIN_COUNT_BSC: 'set-special-skin-count-bsc',
    SET_SKIN_COUNT_MATIC: 'set-skin-count-matic',
    SET_BRIDGING_TICKET_TYPE_BSC: 'set-bridging-ticket-type-bsc',
    SET_BRIDGING_SKIN_TYPE_BSC: 'set-bridging-skin-type-bsc',
    SET_UPDATE_TOKEN: 'set-update-token',
    SET_TOKENS_OWNED: 'set-tokens-owned',
    SET_BRIDGE_CHEST: 'set-bridge-chest',
    SET_TOTAL_CHEST_COUNT: 'set-total-chest-count',
    SET_TOTAL_CARD_COUNT: 'set-total-card-count',
    SET_OPEN_CHEST: 'set-open-chest',
    SET_CARDS: 'set-cards',
    SET_CLAIM_REWARDS_CONFIG: 'set-claim-rewards-config',
    SET_LATEST_CARD_DETAILS: 'set-latest-card-details',
    INIT_STORE_CONTRACT: 'init-store-contract',
};
