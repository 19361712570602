import { ethers } from "ethers";
import Web3 from "web3";
import { TYPES } from '../ActionTypes';

const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID);

const ETHEREUM_PROVIDER = CHAIN_ID == 1 ? process.env.REACT_APP_PROVIDER_URL : process.env.REACT_APP_GOERLI_PROVIDER_URL;
const ETHEREUM_DEPLOYMENT_CONTEXT = CHAIN_ID == 1 ? process.env.REACT_APP_DEPLOYMENT_CONTEXT_URL : process.env.REACT_APP_GOERLI_DEPLOYMENT_CONTEXT_URL;
const POLYGON_PROVIDER = process.env.REACT_APP_MATIC_PROVIDER_URL;
const POLYGON_DEPLOYMENT_CONTEXT = process.env.REACT_APP_MATIC_DEPLOYMENT_CONTEXT_URL;
const BINANCE_PROVIDER = process.env.REACT_APP_BINANCE_PROVIDER_URL;
const BINANCE_DEPLOYMENT_CONTEXT = process.env.REACT_APP_BINANCE_DEPLOYMENT_CONTEXT_URL;
const ROUTER_CONTEXT = process.env.REACT_APP_MATIC_ROUTER_CONTRACT_URL;


export const createProvider = (providerUrl) => {
    let currentProvider = new Web3.providers.HttpProvider(providerUrl);
    const provider = new ethers.providers.Web3Provider(currentProvider);
    return provider;
};

export const initializeProviders = async () => {
    try {
        let polygonProviderObj = await fetch(POLYGON_PROVIDER);
        polygonProviderObj = await polygonProviderObj.json();
        let ethereumProviderObj = await fetch(ETHEREUM_PROVIDER);
        ethereumProviderObj = await ethereumProviderObj.json();
        let binanceProviderObj = await fetch(BINANCE_PROVIDER);
        binanceProviderObj = await binanceProviderObj.json();

        let polygonProvider = createProvider(polygonProviderObj.url);
        let ethereumProvider = createProvider(ethereumProviderObj.url);
        let binanceProvider = createProvider(binanceProviderObj.url);

        return {
            type: TYPES.SET_PROVIDERS,
            payload: {
                polygonProvider,
                ethereumProvider,
                binanceProvider
            },
        };
    } catch (e) {
        console.log("Error initializing providers.", e);
    }
};

export const initializeDeploymentContexts = async () => {
    try {
        let polygonDeploymentContext = await fetch(POLYGON_DEPLOYMENT_CONTEXT);
        polygonDeploymentContext = await polygonDeploymentContext.json();
        let ethereumDeploymentContext = await fetch(ETHEREUM_DEPLOYMENT_CONTEXT);
        ethereumDeploymentContext = await ethereumDeploymentContext.json();
        let binanceDeploymentContext = await fetch(BINANCE_DEPLOYMENT_CONTEXT);
        binanceDeploymentContext = await binanceDeploymentContext.json();
        let routerDeploymentContext = await fetch(ROUTER_CONTEXT);
        routerDeploymentContext = await routerDeploymentContext.json();
        
        return {
            type: TYPES.SET_DEPLOYMENT_CONTEXTS,
            payload: {
                polygonDeploymentContext,
                ethereumDeploymentContext,
                binanceDeploymentContext,
                routerDeploymentContext
            }
        }
    } catch (e) {
        console.log("Error initializing providers.", e);
    }
}