import SoulHarvester from '../../assets/imgs/skins/soul_harvester_small.png';
import PhantomQueen from '../../assets/imgs/skins/phantom_queen_small.png';
import MiraculousAngel from '../../assets/imgs/skins/miraculous_angel_small.png';
import DivineChampion from '../../assets/imgs/skins/divine_champion_small.png';
import SparklingFairy from '../../assets/imgs/skins/sparkling_fairy_small.png';
import ArchMage from '../../assets/imgs/skins/arch_mage_small.png';
import FantasyHealer from '../../assets/imgs/skins/fantasy_healer_small.png';
import MagicalWarrior from '../../assets/imgs/skins/magical_warrior_small.png';
import AssassinFemale from '../../assets/imgs/skins/assassin_female_small.png';
import AssassinMale from '../../assets/imgs/skins/assassin_male_small.png';
import CaptainFemale from '../../assets/imgs/skins/captain_female_small.png';
import CaptainMale from '../../assets/imgs/skins/captain_male_small.png';
import SoldierFemale from '../../assets/imgs/skins/soldier_female_small.png';
import SoldierMale from '../../assets/imgs/skins/soldier_male_small.png';
import RougeFemale from '../../assets/imgs/skins/rouge_female_small.png';
import RougeMale from '../../assets/imgs/skins/rouge_male_small.png';
import BardFemale from '../../assets/imgs/skins/bard_female_small.png';
import BardMale from '../../assets/imgs/skins/bard_male_small.png';

import NRarity from '../../assets/imgs/skins/n.svg';
import RRarity from '../../assets/imgs/skins/r.svg';
import SRRarity from '../../assets/imgs/skins/sr.svg';
import SSRRarity from '../../assets/imgs/skins/ssr.svg';

export const skinMap = {
    soulHarvester: {
        img: SoulHarvester,
        name: 'Soul Harvester',
        rarity: 'SSR',
    },

    phantomQueen: {
        img: PhantomQueen,
        name: 'Phantom Queen',
        rarity: 'SSR',
    },

    miraculousAngel: {
        img: MiraculousAngel,
        name: 'Miraculous Angel',
        rarity: 'SSR',
    },

    divineChampion: {
        img: DivineChampion,
        name: 'Divine Champion',
        rarity: 'SSR',
        quantity: 0
    },

    sparklingFairy: {
        img: SparklingFairy,
        name: 'Sparkling Fairy',
        rarity: 'SR',
    },

    archMage: {
        img: ArchMage,
        name: 'Arch Mage',
        rarity: 'SR',
    },

    fantasyHealer: {
        img: FantasyHealer,
        name: 'Fantasy Healer',
        rarity: 'SR',
    },

    magicalWarrior: {
        img: MagicalWarrior,
        name: 'Magical Warrior',
        rarity: 'SR',
    },

    assassinFemale: {
        img: AssassinFemale,
        name: 'Assassin Female',
        rarity: 'R',
    },

    assassinMale: {
        img: AssassinMale,
        name: 'Assassin Male',
        rarity: 'R',
    },

    captainFemale: {
        img: CaptainFemale,
        name: 'Captain Female',
        rarity: 'R',
    },

    captainMale: {
        img: CaptainMale,
        name: 'Captain Male',
        rarity: 'R',
    },

    soldierFemale: {
        img: SoldierFemale,
        name: 'Soldier Female',
        rarity: 'N',
    },

    soldierMale: {
        img: SoldierMale,
        name: 'Soldier Male',
        rarity: 'N',
    },

    rougeFemale: {
        img: RougeFemale,
        name: 'Rouge Female',
        rarity: 'N',
    },

    rougeMale: {
        img: RougeMale,
        name: 'Rouge Male',
        rarity: 'N',
    },

    bardFemale: {
        img: BardFemale,
        name: 'Bard Female',
        rarity: 'N',
    },

    bardMale: {
        img: BardMale,
        name: 'Bard Male',
        rarity: 'N',
    }
}

export const returnRarityImage = (rarityVal) => {
    if(rarityVal === 'SSR') {
        return SSRRarity;
    }
    else if(rarityVal === 'SR') {
        return SRRarity;
    }
    else if(rarityVal === 'R') {
        return RRarity;
    }
    else {
        return NRarity;
    }
}

// Maps, Skins and Tickets to tokenType encoding
export const allTokenTypes = {
    1: "gold",
    2: "silver",
    3: "bronze",
    4: "miraculousAngel",
    5: "divineChampion",
    6: "sparklingFairy",
    7: "archMage",
    8: "fantasyHealer",
    9: "magicalWarrior",
    10: "assassinMale",
    11: "assassinFemale",
    12: "captainFemale",
    13: "captainMale",
    14: "soldierFemale",
    15: "soldierMale",
    16: "rougeFemale",
    17: "rougeMale",
    18: "bardFemale",
    19: "bardMale",
    20: "phantomQueen",
    21: "soulHarvester",
    22: "mapCommon",
    23: "mapRare",
    24: "mapEpic",
    25: "mapLegendary"
}

// convert the input array to camel case
export const toCamelCase = (inputArray) => {
    let result = "";
    for(let i = 0 , len = inputArray.length; i < len; i++) {
        let currentStr = inputArray[i];
        let tempStr = currentStr.toLowerCase();
        if(i != 0) {
            // convert first letter to upper case (the word is in lowercase) 
            tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        }
        result +=tempStr;
    }
    return result;
}