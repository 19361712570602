import React, { useState, useEffect, useRef } from 'react';
import { Button, Overlay, Tooltip, Spinner } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import './Card.scss';
import {getMetadataForTokenId} from '../../pages/MyCards/service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from '../../helpers';

function Card(props) {
    const [t] = useTranslation();
    const {tokenId} = props.data;
    const [itemMetdata,setItemMetadata] = useState(null);
    const tokenIdRef1 = useRef(null);
    const [showTT, setShowTT] = useState(false);
    function copyReferralLink() {
        setShowTT(true);
        copyToClipboard(`${tokenId}`);
    }
    useEffect( () => {
        (async ()=> {
                const response = await getMetadataForTokenId(tokenId).catch(err => { 
                console.error("Could not get item metadata! ",tokenId)})
                setItemMetadata(response)
        })()
    },[tokenId])

    return <div className="cdh_card">
        <div className="top">
            {itemMetdata?<img src={itemMetdata.image}></img>:<></>}
        </div>
        <div className="bottom">
            <div className="name">{itemMetdata?itemMetdata.name:<Spinner animation="border" variant="secondary" size="sm"/>}</div>
            <div className="address_container">
            <div className="address">{tokenId}</div>
            <span id="copy-icon" ref={tokenIdRef1} onClick={() => copyReferralLink(1)}>
                        <FontAwesomeIcon icon={faCopy} />
            </span></div>
        </div>
        <Overlay target={tokenIdRef1.current} placement="top" show={showTT} rootClose={true} onHide={() => setShowTT(false)}>
                <Tooltip>{t('Copied!')}</Tooltip>
            </Overlay>
    </div>
}

export { Card };