import React, { useState, useContext, useEffect } from 'react';
import styles from './TowerXBaycPage.module.scss';
// import { ReactComponent as TwitterIcon } from '../../assets/icons/twitterIcon.svg';
// import { ReactComponent as DiscordIcon } from '../../assets/icons/discordIcon.svg';
// import BoredApeHelmImg from '../../assets/imgs/bayc/boredape_helm.png';
import EarnBoredApe from '../../assets/imgs/bayc/boredape_earn.png';
import FadedBoredApe from '../../assets/imgs/bayc/boredape_faded.png';
import HeroPointsLogo from '../../assets/imgs/bayc/hero_points_logo.png';
import TowerLogo from '../../assets/imgs/bayc/logo_tower.png';
import ApeCoinLogo from '../../assets/imgs/bayc/logo_apecoin.png';
import TowerBattleCard from '../../assets/imgs/bayc/tower_battle_card.png';
import BoredApeLovesTower from '../../assets/imgs/bayc/boredape_loves_tower.png';
import BoardApeSmiling from '../../assets/imgs/bayc/boredape_smiling.png';
import DoubleArrow from '../../assets/imgs/bayc/double_arrow.png';
import OrnamentTopLeft from '../../assets/imgs/bayc/ornament_topleft.png';
import OrnamentTopRight from '../../assets/imgs/bayc/ornament_topright.png';
import OrnamentBottomLeft from '../../assets/imgs/bayc/ornament_bottomleft.png';
import OrnamentBottomRight from '../../assets/imgs/bayc/ornament_bottomright.png';
// import IconQuestionMark from '../../assets/imgs/bayc/icon_question_mark.png';
import IconBinanceWhite from '../../assets/icons/icon_binance_white.png';
import IconTowerWhite from '../../assets/imgs/bayc/icon_tower_white.png';
import IconOpenSeaWhite from '../../assets/icons/icon_opensea_white.png';
import IconCheck from '../../assets/imgs/bayc/icon_check.png';
import AvatarApeImg from '../../assets/imgs/bayc/avatar_ape.png';
import AvatarHeroImg from '../../assets/imgs/bayc/avatar_hero.png';
import google_store_btn from '../../assets/imgs/btn_google_play_new.png';
import apple_store_btn from '../../assets/imgs/btn_apple_store_new.png';
import { PointsEarningSteps } from './PointsEarningSteps';
import { Web3Context } from '../../web3';
import kyc_before from '../../assets/imgs/bayc/kyc_before.png';
import nft_before from '../../assets/imgs/bayc/nft_before.png';
import kyc_verified from '../../assets/imgs/bayc/kyc_verified.png';
import nft_verified from '../../assets/imgs/bayc/nft_verified.png';
import TowerImgOne from '../../assets/imgs/bayc/tower1.png';
import TowerImgTwo from '../../assets/imgs/bayc/tower2.png';
import TowerImgThree from '../../assets/imgs/bayc/tower3.png';
import TowerImgFour from '../../assets/imgs/bayc/tower4.png';
import TowerImgFive from '../../assets/imgs/bayc/tower5.png';
import TowerImgSix from '../../assets/imgs/bayc/tower6.png';
import TowerImgSeven from '../../assets/imgs/bayc/tower7.png';
import TowerImgEight from '../../assets/imgs/bayc/tower8.png';
import TowerImgNine from '../../assets/imgs/bayc/tower9.png';
import TowerImgTen from '../../assets/imgs/bayc/tower10.png';
import OpenPadLock from '../../assets/imgs/bayc/padlock_open.png';

import LoginAvatar from '../../assets/imgs/avatar_narrator_v2.png';
import { Login } from '../../components';
import { useTranslation } from 'react-i18next';

import metamaskLogo from '../../assets/imgs/icon-metamask.png';
import coinbaseLogo from '../../assets/imgs/home/icon-coinbase.png';
import battleCardIcon from '../../assets/icons/icon-battle-card.png';
import questionMarkIcon from '../../assets/icons/icon-question-mark.png';
import levelLogo from '../../assets/imgs/bind/icon-level.png';
import personLogo from '../../assets/imgs/bind/icon-person.png';
import LogoutIcon from '../../assets/imgs/icon-logout.png';
import Loader from '../../assets/imgs/loader.gif';
import { middleEllipsis } from '../../helpers';
import { ABIContext, IpContext, TokenContext, TYPES } from '../../components/Context';
import { useCookies } from 'react-cookie';
import firebase from "firebase";
import { createContract } from '../../services/contractService';
import { fetchPlayerStatus } from '../../services/claimRewardsServices';
import axios from 'axios';
import { fetchTowerBalance } from '../../components/Context/Actions/TokenActions';
import IconPointsCheck from '../../assets/imgs/bayc/icon_points_check.png';
import { fromWei } from 'web3-utils';


// const TwitterLink = "https://twitter.com/TowerToken";
// const DiscordLink = "https://discord.gg/MzKppSCKVu";
const cdhGpLink = "https://go.onelink.me/bUzl/9c836b67";
const cdhAppStoreLink = "https://go.onelink.me/VHLW/e01c1c3f";
const battleCardsOpenSeaLink = "https://opensea.io/collection/tower-battle-cards";
const binanceTicketsURL = 'https://www.binance.com/en/nft/search-result?isVerified=1&assetType=1&keyword=TOWER+Ticket&tab=nft&order=amount_sort%401';
const getTowerURL = window.location.origin + `/buy-tower/matic`;
const getApeURL = 'https://apecoin.com/exchanges';
const POLYGON_CHAIN_ID = process.env.REACT_APP_POLYGON_CHAIN_ID;
const blockpassVerifyURL = process.env.REACT_APP_BLOCKPASS_VERIFICATION_URL;
const invasionEventGenerationCount = process.env.REACT_APP_INVASION_EVENT_GENERATION;
const invasionEventMediumArticleURL = 'https://medium.com/tower-token/introducing-the-2022-crazy-defense-heroes-halloween-invasion-bored-ape-edition-aba2ffa6088c';

const INVASION_EVENT_BASE_URL = process.env.REACT_APP_INVASION_EVENT_GAME_API_BASE_URL;

const InitialUserInvasionProgressDetails = [
    {
        heroPointStatus: 'Normal',
        minLevelRequired: 12,
        finishGameHeroPointsEarned: 10000,
        holdingTowerExtraPointsEarned: 20000,
        holdingCardExtraPointsEarned: 20000,
        holdingApeExtraPointsEarned: 20000,
        maxPossibleHeroPoints: 70000,
        requiredTowerBalance: 5000,
        requiredCardBalance: 5,
        requiredApeBalance: 5,
        towerExtraPointsEligible: false,
        cardExtraPointsEligible: false,
        apeExtraPointsEligible: false,
        isCompleted: false,
    },
    {
        heroPointStatus: 'Hard',
        minLevelRequired: 25,
        finishGameHeroPointsEarned: 20000,
        holdingTowerExtraPointsEarned: 40000,
        holdingCardExtraPointsEarned: 40000,
        holdingApeExtraPointsEarned: 40000,
        maxPossibleHeroPoints: 140000,
        requiredTowerBalance: 10000,
        requiredCardBalance: 10,
        requiredApeBalance: 10,
        towerExtraPointsEligible: false,
        cardExtraPointsEligible: false,
        apeExtraPointsEligible: false,
        isCompleted: false,
    },
    {
        heroPointStatus: 'Crazy',
        minLevelRequired: 35,
        finishGameHeroPointsEarned: 40000,
        holdingTowerExtraPointsEarned: 80000,
        holdingCardExtraPointsEarned: 80000,
        holdingApeExtraPointsEarned: 80000,
        maxPossibleHeroPoints: 280000,
        requiredTowerBalance: 20000,
        requiredCardBalance: 20,
        requiredApeBalance: 20,
        towerExtraPointsEligible: false,
        cardExtraPointsEligible: false,
        apeExtraPointsEligible: false,
        isCompleted: false,
    },
    {
        heroPointStatus: 'Nightmare',
        minLevelRequired: 70,
        finishGameHeroPointsEarned: 80000,
        holdingTowerExtraPointsEarned: 160000,
        holdingCardExtraPointsEarned: 160000,
        holdingApeExtraPointsEarned: 160000,
        maxPossibleHeroPoints: 560000,
        requiredTowerBalance: 40000,
        requiredCardBalance: 30,
        requiredApeBalance: 40,
        towerExtraPointsEligible: false,
        cardExtraPointsEligible: false,
        apeExtraPointsEligible: false,
        isCompleted: false,
    }
]

const TowerXBaycPage = () => {
    const [t] = useTranslation()
    const { provider } = useContext(Web3Context);
    const { isInChina } = useContext(IpContext);
    const [, dispatch] = useContext(TokenContext);

    const [siwaCookies, removeSiwaCookies] = useCookies(['SiwaAuthId']);

    const [abiConfig] = useContext(ABIContext);
    const {
        binanceDeploymentContext,
        binanceProvider,
        ethereumDeploymentContext,
        ethereumProvider,
        polygonDeploymentContext,
        polygonProvider
    } = abiConfig;

    const [loggedIn, setLoggedIn] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [walletInfo, setWalletInfo] = useState({});
    
    // state variables to identify user battle card and ticket count, tower balance and ape balance
    const [userTicketWorth, setUserTicketWorth] = useState(0);
    const [userBattleCardCount, setUserBattleCardCount] = useState(0);
    const [equivalentCardCount, setEquivalentCardCount] = useState(0);
    const [linkedWalletTowerBalance, setLinkedWalletTowerBalance] = useState(0);
    const [linkedWalletApeBalance, setLinkedWalletApeBalance] = useState(0);

    // state variable to identify if a user has completed steps 1, 2 and 3
    // step 1 whether linked or not will be identified from the state variable loggedIn
    const [walletVerified, setWalletVerified] = useState(true);
    const [userOwnsCardsOrTickets, setUserOwnsCardsOrTickets] = useState(false);

    // logic for heroPointsLocked is:
        // initially false when not logged in with linked wallet
        // (loggedIn && (!walletVerified || !userOwnsCardsOrTickets)) => true
        // loggedIn &&  walletVerified && userOwnsCardsOrTickets => false
    const [heroPointsLocked, setHeroPointsLocked] = useState(false);

    // rendering of incomplete ribbon will be checked by this initial variable;
    const [incompleteRibbonCheck, setIncompleteRibbonCheck] = useState(false);

    const [userInvasionProgressDetails, setUserInvasionProgressDetails] = useState(JSON.parse(JSON.stringify(InitialUserInvasionProgressDetails)));
    const [totalHeroPointsEarned, setTotalHeroPointsEarned] = useState(0);
    
    // state variable to identify whether the invasion progress details for the user is being fetched or not
        // initially false because we are not fetching invasion progress
        // once startss call to fetchLinkedUserInvasionProgress -> true
        // completes fetching response from the function set back to false again
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isBanned, setIsBanned] = useState(false);

    useEffect(() => {
        const loggedInPages = JSON.parse(localStorage.getItem('showLoginOnLoad'));
        if (loggedInPages?.baycPage) {
            setShowLoginModal(true);
        }
    }, [])

    useEffect(() => {
        if (loggedIn && userDetails && walletInfo) {
            checkUserVerified();

            const loggedInPages = JSON.parse(localStorage.getItem('showLoginOnLoad'));
            let updatedLoggedInPages = loggedInPages || {};
            Object.keys(updatedLoggedInPages).forEach((pages) => {
                updatedLoggedInPages[pages] = true;
            });
            updatedLoggedInPages['baycPage'] = true;
            localStorage.setItem('showLoginOnLoad', JSON.stringify(updatedLoggedInPages));
        };
    },[userDetails, walletInfo, loggedIn])

    const signOut = () => {
        if (siwaCookies.SiwaAuthId != null) {
            removeSiwaCookies('SiwaAuthId', { path: '/' });
        }
        if (!isInChina) {
            firebase.auth().signOut();
        }
        handleSignedOutUser();
    }

    const checkUserVerified = async () => {
        let statusResponse = await fetchPlayerStatus(walletInfo.WalletId);
        // statusResponse = {"status":"success","data":{"status":"approved","recordId":"62c59976b0dba35f2aa4d664","refId":"0x10Dd3BaD2D1ADDC1fE433007046cE51941d16C8A","submitCount":2,"blockPassID":"624ac0a05763cb00123c4863","isArchived":false,"inreviewDate":"2022-07-07T09:03:41.432Z","waitingDate":"2022-07-06T14:18:26.973Z","approvedDate":"2022-07-07T09:03:44.442Z"}}
        if (statusResponse && statusResponse?.data && statusResponse.data.status === "approved") {
            setWalletVerified(true);
        } else {
            setWalletVerified(false);
        }
    }

    const handleSignedOutUser = () => {
        const loggedInPages = JSON.parse(localStorage.getItem('showLoginOnLoad'));
        let updatedLoggedInPages = loggedInPages || {};
        Object.keys(updatedLoggedInPages).forEach((pages) => {
            updatedLoggedInPages[pages] = false;
        });
        localStorage.setItem('showLoginOnLoad', JSON.stringify(updatedLoggedInPages));

        // dispatch userId to set the value in the Context variable
        dispatch({
            type: TYPES.SET_USER_ID,
            payload: "",
        });
        setLoggedIn(false);
        setWalletInfo();
        setUserDetails();
        resetUserInvasionProgress();
    }

    const calculateTotalHeroPointsEarned = async () => {
        let heroPointsEarned = 0
        userInvasionProgressDetails.forEach((singleLevelInvasionProgress) => {
            // base hero points earned
            if(singleLevelInvasionProgress['isCompleted']) {
                heroPointsEarned += singleLevelInvasionProgress['finishGameHeroPointsEarned'];

                // extra hero points earned calculation/addition
                if(singleLevelInvasionProgress['apeExtraPointsEligible']) {
                    heroPointsEarned += singleLevelInvasionProgress['holdingApeExtraPointsEarned'];
                }

                if(singleLevelInvasionProgress['towerExtraPointsEligible']) {
                    heroPointsEarned += singleLevelInvasionProgress['holdingTowerExtraPointsEarned']
                }

                if(singleLevelInvasionProgress['cardExtraPointsEligible']) {
                    heroPointsEarned += singleLevelInvasionProgress['holdingCardExtraPointsEarned']
                }
            }
        })
        setTotalHeroPointsEarned(heroPointsEarned);
    }

    const resetUserInvasionProgress = () => {
        setUserInvasionProgressDetails(JSON.parse(JSON.stringify(InitialUserInvasionProgressDetails)));
        setTotalHeroPointsEarned(0);
        setIncompleteRibbonCheck(false);
        setHeroPointsLocked(true);
    }

    // fetch user invasion progress when cdhPlayerId (step 1) along with step 2 and step 3 are completed
    // currently we are calling this with only step 1 completed
    const fetchLinkedUserInvasionProgress = async () => {
        try {
            setLoading(true);
            const IS_USER_BANNED_URL = INVASION_EVENT_BASE_URL + '/admin/is_user_banned'
            const isUserBannedQueryParams = {
                playerId: userDetails?.cdhUserId
            }

            const bannedURLResponse = await axios.get(IS_USER_BANNED_URL, { params: isUserBannedQueryParams }).catch((err) => err.response);
            if (!bannedURLResponse || bannedURLResponse.status !== 200) {
                throw new Error("Problem fetching user ban status.");
            }
            // user ban check
            if((bannedURLResponse.status === 200) && bannedURLResponse.data.result) {
                setIsBanned(true);
                setLoading(false);
                return;
            }

            const USER_INVASION_PROGRESS_URL = INVASION_EVENT_BASE_URL + '/admin/get_user_invasion_progress'
            const invasionProgressQueryParams = {
                playerId: userDetails?.cdhUserId,
                invasion: "Halloween",
                generation: invasionEventGenerationCount
            }
            const invasionProgressResponse = await axios.get(USER_INVASION_PROGRESS_URL, { params: invasionProgressQueryParams }).catch((err) => err.response);

            // when user is not banned and status response is 200 only, set invasion data
            if(invasionProgressResponse.status === 200) {
                const invasionProgressStatus = invasionProgressResponse.data.result;
                
                // code logic for updating invasion status level completed or not
                Object.keys(invasionProgressStatus).forEach((level) => {
                    if(invasionProgressStatus[level]) {
                        let updatedUserInvasionProgress = [...userInvasionProgressDetails];
                        updatedUserInvasionProgress.forEach((userInvasionProgressLevel) => {
                            if(userInvasionProgressLevel['heroPointStatus'] === level) {
                                userInvasionProgressLevel['isCompleted'] = true

                                // condition check for extra points eligibility for TOWER Balance and Card Balance
                                if(equivalentCardCount >= userInvasionProgressLevel['requiredCardBalance']) {
                                    userInvasionProgressLevel['cardExtraPointsEligible'] = true;
                                }

                                if(linkedWalletTowerBalance >= userInvasionProgressLevel['requiredTowerBalance']) {
                                    userInvasionProgressLevel['towerExtraPointsEligible'] = true;
                                }

                                if(linkedWalletApeBalance >= userInvasionProgressLevel['requiredApeBalance']) {
                                    userInvasionProgressLevel['apeExtraPointsEligible'] = true;
                                }
                            }
                        })
                        setUserInvasionProgressDetails(updatedUserInvasionProgress)
                    }
                })
            } else if (invasionProgressResponse.status === 500) {
                throw new Error("Problem fetching user progress.");
            }

            // after fetching user invasion progress, we also want to render incomplete ribbon values for levels not complete
            setIncompleteRibbonCheck(true);
        } catch(err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const fetchCardsCount = async () => {
        try {
            const inventoryInstance = await createContract(polygonDeploymentContext, "CDHInventory", polygonProvider.connection.url);
            let cardsCount = await inventoryInstance.methods.count(walletInfo?.WalletId).call();
            cardsCount = await cardsCount.toString();
            setUserBattleCardCount(parseInt(cardsCount))
        } catch (err) {
            console.log(err);
        }
    }

    const fetchApeBalance = async () => {
        try {
            const ethApeContract = await createContract(ethereumDeploymentContext, "ApeCoin", ethereumProvider.connection.url);
            let ethApeBalance = await ethApeContract.methods.balanceOf(walletInfo?.WalletId).call();
            ethApeBalance = Number(fromWei(ethApeBalance.toString()));

            const polygonApeContract = await createContract(polygonDeploymentContext, "ApeCoin", polygonProvider.connection.url);
            let polygonApeBalance = await polygonApeContract.methods.balanceOf(walletInfo?.WalletId).call();
            polygonApeBalance = Number(fromWei(polygonApeBalance.toString()));

            let totalApeBalance = ethApeBalance + polygonApeBalance;
            setLinkedWalletApeBalance(parseInt(totalApeBalance));
        } catch(err) {
            console.log(err);
        }
    }

    const fetchBSCTicketsCount = async () => {
        try {
            const ticketContractInstance = await createContract(binanceDeploymentContext, 'Ticket', binanceProvider.connection.url)
            const totalTicketCount = await ticketContractInstance.methods.balanceOf(walletInfo?.WalletId).call();
            let allTicketsAndSkins = {};
            let goldTicketCount = 0;
            let silverTicketCount = 0;
            let bronzeTicketCount = 0;

            const fetchedTokensURLResponse = await axios.get(process.env.REACT_APP_GOOGLE_STORAGE_COMMON_TOKENS_URL).catch((err) => err.response);
            if(fetchedTokensURLResponse && fetchedTokensURLResponse?.status === 200) {
                allTicketsAndSkins = fetchedTokensURLResponse.data;
            }

            if(totalTicketCount > 0) {
                for(let i=0; i < totalTicketCount; i++) {
                    let ticketTokenId = await ticketContractInstance.methods.tokenOfOwnerByIndex(walletInfo?.WalletId, i).call();
                    let metaDataFromFile = allTicketsAndSkins[ticketTokenId];
                    if(metaDataFromFile !== undefined) {
                        if(metaDataFromFile === 'Bronze TOWER Ticket') {
                            bronzeTicketCount++;
                        }
                        if(metaDataFromFile === 'Silver TOWER Ticket') {
                            silverTicketCount++;
                        }
                        if(metaDataFromFile === 'Gold TOWER Ticket') {
                            goldTicketCount++;
                        }
                    } else {
                        let tokenURI = await ticketContractInstance.methods.tokenURI(ticketTokenId).call();
                        let config = { 
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                            }
                        }
                        
                        let rawTokenMetaData = await axios.get(tokenURI, config).catch((err) => err.response);
                        let tokenMetaData = {};

                        if(rawTokenMetaData && rawTokenMetaData?.status === 200) {
                            tokenMetaData = rawTokenMetaData.data;
                        }

                        if(tokenMetaData['name'] === 'Bronze TOWER Ticket') {
                            bronzeTicketCount++;
                        }
                        if(tokenMetaData['name'] === 'Silver TOWER Ticket') {
                            silverTicketCount++;
                        }
                        if(tokenMetaData['name'] === 'Gold TOWER Ticket') {
                            goldTicketCount++;
                        }
                    }
                }

                let totalTicketWorth = (5 * goldTicketCount) + (4 * silverTicketCount) + (3 * bronzeTicketCount);
                setUserTicketWorth(totalTicketWorth)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const fetchLinkedWalletTowerBalance = async () => {
        try {
            let polygonTowerBalance = await fetchTowerBalance(POLYGON_CHAIN_ID, walletInfo?.WalletId, polygonProvider, polygonDeploymentContext, false);
            polygonTowerBalance = parseInt(polygonTowerBalance);

            const ethTowerContract = await createContract(ethereumDeploymentContext, "TOWER", ethereumProvider.connection.url);
            let ethTowerBalance = await ethTowerContract.methods.balanceOf(walletInfo?.WalletId).call();
            ethTowerBalance = Number(fromWei(ethTowerBalance.toString()));

            const bscTowerContract = await createContract(binanceDeploymentContext, "BscTower", binanceProvider.connection.url);
            let bscTowerBalance = await bscTowerContract.methods.balanceOf(walletInfo?.WalletId).call();
            bscTowerBalance = Number(fromWei(bscTowerBalance.toString()));

            let totalTowerBalance = polygonTowerBalance + ethTowerBalance + bscTowerBalance;

            setLinkedWalletTowerBalance(totalTowerBalance);
        } catch(err) {
            console.log(err)
        }
    }

    const calculateEquivalentCards = () => {
        let totalCardWorth = userBattleCardCount + userTicketWorth;
        setEquivalentCardCount(totalCardWorth);
    }

    const connectGameID = () => {
        setShowLoginModal(true);
    }

    const scrollToUsersStatus = () => {

        if(!userDetails?.cdhUserId) {
            document.getElementById('shouldLinkWallet').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }

        if(userDetails?.cdhUserId && !walletVerified) {
            document.getElementById('shouldVerifyLinkedAccount').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }

        if(userDetails?.cdhUserId && !userOwnsCardsOrTickets) {
            document.getElementById('shouldHoldCardsOrTickets').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }

        if(userDetails?.cdhUserId && walletVerified && userOwnsCardsOrTickets) {
            document.getElementById('shouldHeroPointsEarned').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }

    useEffect(() => {
        if(!userDetails?.cdhUserId) {
            // Reset any existing counts for battle cards and tickets
            setUserBattleCardCount(0);
            setUserTicketWorth(0);
            setLinkedWalletTowerBalance(0);
        }
    }, [userDetails?.cdhUserId])

    useEffect(() => {
        if(userDetails?.cdhUserId && walletInfo?.WalletId &&
            polygonDeploymentContext && binanceDeploymentContext && ethereumDeploymentContext && polygonProvider && binanceProvider && ethereumProvider) {
            fetchCardsCount();
            fetchBSCTicketsCount();
            fetchLinkedWalletTowerBalance();
            fetchApeBalance();
        }
    }, [userDetails?.cdhUserId, walletInfo?.WalletId, polygonDeploymentContext, polygonProvider, binanceDeploymentContext, binanceProvider, ethereumDeploymentContext, ethereumProvider])

    useEffect(() => {
        if(userDetails?.cdhUserId && walletVerified && userOwnsCardsOrTickets) {
            fetchLinkedUserInvasionProgress();
        }
    }, [userDetails?.cdhUserId, walletVerified, userOwnsCardsOrTickets, linkedWalletTowerBalance, linkedWalletApeBalance])

    useEffect(() => {
        if(userInvasionProgressDetails) {
            calculateTotalHeroPointsEarned();
        }
    }, [userInvasionProgressDetails])


    useEffect(() => {
        if(userBattleCardCount >= 0 || userTicketWorth >= 0) {
            calculateEquivalentCards()
        }
    }, [userBattleCardCount, userTicketWorth])

    useEffect(() => {
        if(equivalentCardCount >= 1) {
            setUserOwnsCardsOrTickets(true);
        }
    }, [equivalentCardCount])

    useEffect(() => {
        if(loggedIn && (!walletVerified || !userOwnsCardsOrTickets)) {
            setHeroPointsLocked(true);
        }
        else if(loggedIn && walletVerified && userOwnsCardsOrTickets) {
            setHeroPointsLocked(false);
        }

    }, [loggedIn, walletVerified, userOwnsCardsOrTickets])

    const renderPointsLoading = (
        <div className={styles.hero_points_loading}>
            <div>
                <img className={styles.loading_icon} src={Loader} alt="loading" />
            </div>
            <div className={styles.loading_text}>
                {`Checking Your progress for the Halloween Invasion`}
            </div>
        </div>
    );

    const renderEarnedPoints = (
        <>
            {
                (heroPointsLocked)
                ?
                    <div className={styles.unlock_hero_points_wrapper}>
                        <img src={OpenPadLock} className={styles.open_padlock} alt="Unlock-Hero-Points" />
                        <span className={styles.unlock_hero_points_text}>{'To unlock the Hero Points you earned, please connect wallet and complete Step'}</span>
                        <div className={styles.step_number}>{'2'}</div>
                        <span className={styles.unlock_hero_points_text}>{' and Step '}</span>
                        <div className={styles.step_number}>{'3'}</div>
                    </div>
                :
                    <></>
            }

            <div id="shouldHeroPointsEarned" className={styles.hero_points_earned_ribbon}>
                <div className={styles.hero_points_earned_text}>
                    {heroPointsLocked
                        ? <><span className={styles.full_text}>{t('HERO_POINTS_LOCKED')}</span><span className={styles.short_text}>{t('Locked:')}</span></>
                        : t('HERO_POINTS_EARNED')
                    }
                </div>
                <div className={styles.hero_points_earned_value_wrapper}>
                    <img src={HeroPointsLogo} alt="Hero-Points-Logo" width="27px" height="27px" />
                    <div className={styles.hero_points_earned_value}>{heroPointsLocked ? '1,050,000' : parseInt(totalHeroPointsEarned).toLocaleString()}</div>
                </div>
            </div>

            <div className={styles.bayc_earn_status_hero_points_categories}>

                { (loading)
                    ?
                        <div className={styles.loading_overlay}>
                            { renderPointsLoading }
                        </div>
                    :
                        <></>
                }

                { (error || isBanned) 
                    ?
                        <div className={styles.loading_overlay}>
                            <div className={styles.error_text}>
                                {`${(error) ? t('ERROR_DESCRIPTION_TEXT') : t('BANNED_DESCRIPTION_TEXT') }`}
                            </div>
                        </div>
                    :
                        <></>
                }
                
                {/* Loop through hero points card details to render 4 types of cards */}
                { userInvasionProgressDetails.map((singleLevelProgressDetails) => (
                    <div key={singleLevelProgressDetails.heroPointStatus} 
                        className={`${styles.bayc_earn_status_hero_points_card} 
                                    ${(singleLevelProgressDetails['heroPointStatus'] === 'Normal') && styles.normal_card}
                                    ${(singleLevelProgressDetails['heroPointStatus'] === 'Hard') && styles.hard_card}
                                    ${(singleLevelProgressDetails['heroPointStatus'] === 'Crazy') && styles.crazy_card}
                                    ${(singleLevelProgressDetails['heroPointStatus'] === 'Nightmare') && styles.nightmare_card}`
                        }>
                        <div className={styles.bayc_earn_status_card_title}>
                            {singleLevelProgressDetails.heroPointStatus}
                        </div>
                        <div className={`${styles.bayc_earn_status_card_requirement} ${(userDetails?.Avatar?.AvatarLevel < singleLevelProgressDetails.minLevelRequired) ? styles.highlight_level_not_reached : ''}`}>
                            {`At least Lv. ${singleLevelProgressDetails.minLevelRequired} to Play`}
                        </div>
                        <div className={styles.bayc_earn_status_reward_text}>
                        {'Rewards'}
                        </div>
                        <div className={styles.bayc_earn_status_todo_wrapper}>
                            <div className={styles.bayc_earn_status_todo}>
                                {'To Do'}
                            </div>
                            <div className={styles.bayc_earn_status_hero_points_earned}>
                                {'Hero Points Earned'}
                            </div>
                        </div>
                        <div className={styles.bayc_earn_status_finish_game_wrapper}>
                            <div className={styles.bayc_earn_status_finish_game_text}>
                                {'Finish Game'}
                            </div>
                            <div className={styles.bayc_earn_status_finish_game_points_wrapper}>
                                <div className={styles.bayc_earn_status_finish_point_logo}>
                                    <img src={HeroPointsLogo} alt="Hero-Points-Logo"/>
                                </div>
                                <div className={styles.bayc_earn_status_finish_points}>{parseInt(singleLevelProgressDetails.finishGameHeroPointsEarned).toLocaleString()}</div>
                                {/* <div className={styles.bayc_earn_status_finish_multiplier}>{'x10'}</div> */}
                                <div className={styles.bayc_earn_status_finish_span}>
                                    { (singleLevelProgressDetails.isCompleted) 
                                        ? <img src={IconPointsCheck} width="15px" height="17px" alt="Icon-Check" />
                                        : <></>
                                    }
                                </div> 
                            </div>
                        </div>
                        <div className={styles.bayc_earn_status_extra_points_wrapper}>
                            {'Extra Hero Points'}
                        </div>

                        <div className={styles.bayc_earn_status_holding_ape_wrapper}>
                            <div className={styles.bayc_earn_status_holding_ape_text}>
                                <span>{`Holding ${parseInt(singleLevelProgressDetails.requiredApeBalance).toLocaleString()} or above `}</span>
                                <img src={ApeCoinLogo} alt="ApeCoin" />
                                <span>{'$ApeCoin'}</span>
                            </div>
                            <div className={styles.bayc_earn_status_holding_ape_points_wrapper}>
                                <div className={styles.bayc_earn_status_holding_ape_points_logo}>
                                    <img src={HeroPointsLogo} alt="Hero-Points-Logo"/>
                                </div>
                                <div className={styles.bayc_earn_status_holding_ape_points}>{parseInt(singleLevelProgressDetails.holdingApeExtraPointsEarned).toLocaleString()}</div>
                                {/* <div className={styles.bayc_earn_status_holding_ape_multiplier}>{'x10'}</div> */}
                                <div className={styles.bayc_earn_status_holding_ape_span}>
                                    { (singleLevelProgressDetails.apeExtraPointsEligible)
                                        ? <img src={IconPointsCheck} width="15px" height="17px" alt="Icon-Check" />
                                        : <></>
                                    }    
                                </div> 
                            </div>
                        </div>

                        <div className={styles.bayc_earn_status_holding_tower_wrapper}>
                            <div className={styles.bayc_earn_status_holding_tower_text}>
                                <span>{`Holding ${parseInt(singleLevelProgressDetails.requiredTowerBalance).toLocaleString()} or above`}</span>
                                <img src={TowerLogo} alt="Crazy-Defense-Heroes-TOWER" />
                                <span>{'TOWER'}</span>
                            </div>
                            <div className={styles.bayc_earn_status_holding_tower_points_wrapper}>
                                <div className={styles.bayc_earn_status_holding_tower_points_logo}>
                                    <img src={HeroPointsLogo} alt="Hero-Points-Logo"/>
                                </div>
                                <div className={styles.bayc_earn_status_holding_tower_points}>{parseInt(singleLevelProgressDetails.holdingTowerExtraPointsEarned).toLocaleString()}</div>
                                {/* <div className={styles.bayc_earn_status_holding_tower_multiplier}>{'x10'}</div> */}
                                <div className={styles.bayc_earn_status_holding_tower_span}>
                                    { (singleLevelProgressDetails.towerExtraPointsEligible)
                                        ? <img src={IconPointsCheck} width="15px" height="17px" alt="Icon-Check" />
                                        : <></>
                                    }    
                                </div> 
                            </div>
                        </div>

                        <div className={styles.bayc_earn_status_holding_battle_card_wrapper}>
                            <div className={styles.bayc_earn_status_holding_battle_card_text}>
                                <span>{`Hold ${singleLevelProgressDetails.requiredCardBalance} or above`}</span>
                                &nbsp;
                                <img src={TowerBattleCard} alt="Crazy-Defense-Heroes-TOWER" />
                                &nbsp;
                                <span>{'TOWER Battle Card'}</span>
                            </div>
                            <div className={styles.bayc_earn_status_holding_battle_card_points_wrapper}>
                                <div className={styles.bayc_earn_status_holding_battle_card_points_logo}>
                                    <img src={HeroPointsLogo} alt="Hero-Points-Logo"/>
                                </div>
                                <div className={styles.bayc_earn_status_holding_battle_card_points}>{parseInt(singleLevelProgressDetails.holdingCardExtraPointsEarned).toLocaleString()}</div>
                                {/* <div className={styles.bayc_earn_status_holding_battle_card_multiplier}>{'x10'}</div> */}
                                <div className={styles.bayc_earn_status_holding_battle_card_span}>
                                    { (singleLevelProgressDetails.cardExtraPointsEligible)
                                        ? <img src={IconPointsCheck} width="15px" height="17px" alt="Icon-Check" />
                                        : <></>
                                    }    
                                </div> 
                            </div>
                        </div>

                        <div className={styles.bayc_earn_status_hr_line}>
                        </div>

                        <div className={styles.bayc_earn_status_earn_upto_wrapper}>
                            <span>{'Earn up to '}</span>
                            &nbsp;
                            <span className={styles.highlighted_text}>
                                {parseInt(singleLevelProgressDetails.maxPossibleHeroPoints).toLocaleString()}
                            </span>
                            &nbsp;
                            <span>{'Hero Points'}</span>
                        </div>

                        {/* Rendering of padlock on each card depending on whether or not status is locked*/}
                        {
                            (heroPointsLocked) 
                                ?
                                    <div className={styles.hero_card_padlock}>
                                    </div>
                                : 
                                    <></>
                        }

                        {/* Rendering of incomplete ribbon on each card for not yet completed levels */}
                        {
                            (incompleteRibbonCheck && !singleLevelProgressDetails['isCompleted'])
                                ? 
                                    <div className={styles.incomplete_ribbon_on_card}>
                                        <div className={styles.incomplete_text}>
                                            {'Incomplete'}
                                        </div>
                                    </div>
                                : <></>
                        }
                        
                    </div>
                ))}
            </div>
            
            <div className={styles.bayc_earn_status_extra_points_desc}>
                <div className={styles.bored_ape_loves_tower}>
                    <img src={BoredApeLovesTower} alt="Bored-Ape-Loves-Tower" />
                </div>

                <div className={styles.earn_extra_hero_points}>
                    <div className={styles.title_and_image}>
                        <div className={styles.bored_ape_loves_tower_small}>
                            <img src={BoredApeLovesTower} alt="Bored-Ape-Loves-Tower" />
                        </div>

                        <div className={styles.earn_extra_hero_points_title}>
                            {'Want to get Extra Hero Points!'}
                        </div>
                    </div>
                    <div className={styles.earn_extra_hero_points_text}>
                        <span>{'By holding a specific amount of '} <strong>{'$TOWER / $ApeCoin / 5 or above TOWER Battle Card / Ticket NFTs,'}</strong> {'you can get extra Hero Points!'}</span>
                    </div>
                    <div className={styles.earn_extra_points_button_set}>
                        <a href={getTowerURL} target="_blank" rel="noopener noreferrer">
                            <button className={styles.blue_button}>
                                <span>{'Get'}</span>
                                <img src={IconTowerWhite} height="14px" width="12px" alt="Tower-Token" /> 
                                <span>{'Tower'}</span>
                            </button>
                        </a>

                        <a href={getApeURL} target="_blank" rel="noopener noreferrer">
                            <button className={styles.blue_button}>
                                <span>{'Get'}</span>
                                <img src={ApeCoinLogo} height="14px" width="12px" alt="ApeCoin" /> 
                                <span>{'ApeCoin'}</span>
                            </button>
                        </a>
                    
                        <a href={battleCardsOpenSeaLink} target="_blank" rel="noopener noreferrer">
                            <button className={styles.blue_button}>
                                <img src={IconOpenSeaWhite} alt="Opensea" width="15px" height="15px" />
                                <span>{'Buy Cards'}</span>
                            </button>
                        </a>
                        
                    
                        <a href={binanceTicketsURL} target="_blank" rel="noopener noreferrer">
                            <button className={styles.blue_button}>
                                <img src={IconBinanceWhite} width="14px" height="14px" alt="Binance" />
                                <span>{'Buy Tickets'}</span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <>
            <div className={styles.bayc_container}>
                <div className={styles.bayc_body}>
                    <img src={AvatarApeImg} alt="avatar_ape" className={styles.avatar_ape} />
                    <img src={AvatarHeroImg} alt="avatar_hero" className={styles.avatar_hero} />

                    <div className={styles.content}>
                        <div className={styles.text_content_div}>
                            <div className={styles.row1}>
                                {'CDH x BAYC Event'}
                            </div>
                            <div className={styles.row2}>
                                <div>
                                    {'Earn Up To'}
                                    <span className={styles.row2_span}> {'1,050,000 Hero Points'} </span>
                                    {'In Halloween Invasion'}
                                </div>
                            </div>
                        </div>

                        <div className={styles.check_my_progress_wrapper}>
                            <div className={styles.check_my_progress_btn} onClick={() => scrollToUsersStatus()}>
                                {'Check My Progress'}
                            </div>
                            <a href={invasionEventMediumArticleURL} rel="noopener noreferrer"><div className={styles.view_event_details}>{'View Event Details'}</div></a>
                        </div>
                    </div>

                    <div className={styles.float_bottom}>
                        <div className={styles.bottom_text}>
                            {'Enter Reward raffle by playing Crazy Defense Hero Mobile Game - Halloween Invasion.'}
                        </div>
                        <div className={styles.bottom_img_group}>
                            <a href={cdhGpLink} target="_blank" rel="noopener noreferrer"><img src={google_store_btn} alt="play-store-qr" className={styles.store_btn} /></a>
                            <a href={cdhAppStoreLink} target="_blank" rel="noopener noreferrer"><img src={apple_store_btn} alt="app-store-qr" className={styles.store_btn} /></a>
                        </div>
                    </div>
                </div>

                <div className={styles.bayc_earn_status}>
                    <div className={styles.bayc_earn_status_title}>
                        <div className={styles.bayc_earn_text}>{'My Earning Status'}</div>
                        <div className={styles.bayc_earn_ape}>
                            <img src={EarnBoredApe} alt="My-Earning-Status" />
                        </div>
                    </div>

                    <div className={styles.bayc_earn_status_container}>
                        <img src={FadedBoredApe} className={styles.faded_bored_ape} alt="Faded-Bored-Ape" />

                        {userDetails?.cdhUserId && walletVerified && userOwnsCardsOrTickets &&
                        <div className={styles.you_are_all_set}>
                            <img width="17px" height="15px" src={IconCheck} alt="Icon-Check" />
                            <span className={styles.you_are_all_set_text}>{'You are all set.'}</span>
                        </div>}

                        <div id="shouldLinkWallet" className={styles.my_invasion_status}>
                            <div className={styles.step_with_title}>
                                <div className={styles.step_one}>
                                    {'1'}
                                </div>
                                <div className={styles.title}>
                                    {'My Invasion Status'}
                                </div>
                            </div>

                            <div className={styles.connect_game_id}>
                                { !loggedIn 
                                    ?
                                        // If the user is not logged in, render connect game id btn
                                        <>
                                            <div className={styles.warning_text}>
                                                {'Connect your Game ID to see your status.'}
                                            </div>

                                            <div className={styles.connect_game_id_button} onClick={connectGameID}>
                                                <span className={styles.connect_game_id_text}>{'Connect Game Id'}</span>
                                            </div>
                                        </>
                                    :
                                        // Rendering of user details info when logged in 
                                        <div className={styles.user_assets}>
                                            {
                                                walletInfo?.WalletId ?
                                                    <div className={styles.user_asset_section}>
                                                        <div className={`${styles.item_info} ${styles.token_boost_info}`}>
                                                            <div className={styles.battle_card_count}>
                                                                <img className={styles.battle_card_img} src={battleCardIcon} alt="battle-card-icon" />
                                                                <span className={styles.count}>{ equivalentCardCount }</span>
                                                            </div>
                                                            <div className={styles.info_text}>
                                                                <img className={styles.icon_question_mark} src={questionMarkIcon} alt="token-boost-info" />
                                                            </div>
                                                            
                                                            <div className={styles.info_popup_box}>
                                                                <div className={styles.info_popup_description}>{'Having 5 or more TOWER Battle Card in your wallet provides $TOWER earning boosts. For details, check the link below.'}</div>
                                                                <div className={styles.info_description_link}><a target="_blank" rel="noopener noreferrer" href={'https://medium.com/tower-token/crazy-defense-heroes-play-and-earn-events-are-now-exclusive-for-tower-battle-card-nft-holders-1f080172ab9c/'}>{'More details'}</a></div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className={styles.item_info}>
                                                            <div className={styles.item_img}>
                                                                <img src={levelLogo} alt="tower-icon" />
                                                            </div>
                                                            <div>Lv. {userDetails?.Avatar?.AvatarLevel}</div>
                                                        </div>

                                                        <div className={styles.item_info} style={{ padding: "5px 12px" }}>
                                                            <div className={styles.item_img}>
                                                                <img src={(provider?.isCoinbaseWallet || provider?.isCoinbaseBrowser) ? coinbaseLogo : metamaskLogo} alt="wallet" />
                                                            </div>
                                                            <div>{middleEllipsis(walletInfo.WalletId)}</div>
                                                        </div>
                                                        
                                                        <div className={styles.item_info}>
                                                            <div className={styles.item_img}>
                                                                <img src={personLogo} alt="person-icon" />
                                                            </div>
                                                            <div>{userDetails?.cdhUserId}</div>
                                                            <div className={styles.logout_btn} onClick={signOut}>
                                                                <img src={LogoutIcon} alt="logout" />
                                                            </div>
                                                        </div>
                                                    </div> 
                                                : <div></div>
                                            }
                                        </div>
                                }
                                
                            </div>
                        </div>

                        <div className={styles.hero_point_description}>
                            {'Get 10x Hero Points by completing the'}<strong>{' Halloween Invasion '}</strong>{'on'} <strong>{' Crazy Defense Hero Mobile Game'}</strong>{', and meeting the extra Hero Points requirement! '}
                            <a href={invasionEventMediumArticleURL} target="_blank" rel="noopener noreferrer">{'Full Details'}</a>
                        </div>

                        <div className={styles.bayc_earn_status_content_box}>
                            <div className={styles.bayc_earn_status_hero_points_title}>
                                <span className={styles.bayc_earn_status_hero_points_brown_text}>
                                    {'Get'}
                                </span>
                                <div className={styles.bayc_earn_status_hero_points_hero_point_logo}>
                                    <img src={HeroPointsLogo} alt="Hero-Points-Logo" />
                                </div>
                                <span className={styles.bayc_earn_status_hero_points_yellow_text}>
                                    {'10 Times Hero Points'}
                                </span>
                                <span className={`${styles.bayc_earn_status_hero_points_brown_text} ${styles.mobile_hideable}`}>
                                    {'In This Event!'}
                                </span>
                            </div>

                            { renderEarnedPoints }
                            
                        </div>
                        <div className={styles.wallet_verify_card_hold_container}>
                            <div id="shouldVerifyLinkedAccount" className={styles.wallet_verify_card_hold_wrapper}>
                                <div className={styles.step_with_title}>
                                    <div className={styles.step_one}>
                                        {'2'}
                                    </div>
                                    <div className={styles.title}>
                                        {'Verify Wallet Address'}
                                    </div>
                                </div>
                                <span className={styles.verify_wallet_address_text}>
                                    {'You need to complete Player verification before '}<strong>{'7 Nov,2022'}</strong>{' day end (UTC), Verification takes around 3 days to be completed.'}
                                </span>
                                {/* <div className={styles.wallet_verify_card_hold_content_box}> */}
                                <div className={`${styles.wallet_verify_card_hold_content_box} ${((loggedIn && !walletInfo?.WalletId) || (loggedIn && walletInfo?.WalletId && !walletVerified) ? styles.red_border : styles.with_padding)}`}>
                                    <div className={styles.wallet_verify_card_hold_top_section}>
                                        <span className={styles.wallet_verify_card_hold_text}>
                                        {'Player Verification'} 
                                        </span>
                                        <img src={(loggedIn && walletInfo?.WalletId && walletVerified) ? kyc_verified : kyc_before} alt={(loggedIn && walletInfo?.WalletId && walletVerified) ? 'kyc-verified' : 'kyc-before'} className={styles.verified_img} />
                                        { !loggedIn && !walletInfo?.WalletId
                                        ?
                                            <div className={`${styles.blue_button} ${styles.blue_button_long}`} onClick={connectGameID}>
                                                <span>{'Connect Game Id'}</span>
                                            </div> 
                                            :
                                            (!walletVerified ?
                                            <>
                                                <div className={styles.not_verified}>
                                                    <div className={styles.fail_ellipse}></div>
                                                    <div className={styles.not_verified_text}> Not Verified </div>
                                                </div>
                                                <a href={blockpassVerifyURL}  target="_blank" rel="noopener noreferrer">
                                                    <div className={`${styles.blue_button} ${styles.blue_button_long}`}>
                                                        <span>{'Start Verification'}</span>
                                                    </div>
                                                </a>
                                            </> 
                                            :
                                            <>
                                                <div className={styles.verified}>
                                                    <div className={styles.success_ellipse}></div>
                                                    <div className={styles.verified_text}> Verified </div>
                                                </div>
                                                <div className={`${styles.blue_button} ${styles.blue_button_long} ${styles.button_disabled}`}>
                                                    <span>{'Verification Status'}</span>
                                                </div>
                                            </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div id="shouldHoldCardsOrTickets" className={styles.wallet_verify_card_hold_wrapper}>
                                <div className={styles.step_with_title}>
                                    <div className={styles.step_one}>
                                        {'3'}
                                    </div>
                                    <div className={styles.title}>
                                        {'Hold TOWER Battle Card/Tickets'}
                                    </div>
                                </div>
                                <span className={styles.verify_wallet_address_text}>
                                    {'You need to'} <strong>{'hold TOWER Battle Card / Tickets NFTs'}</strong> {'during snapshot period: '}<strong>{'24 Oct, 2022 - 14 Nov, 2022'}</strong>
                                </span>
                                {/* <div className={styles.hold_tower_battle_content_box}> */}
                                <div className={`${styles.wallet_verify_card_hold_content_box} ${!loggedIn ? styles.with_padding : ''} ${((loggedIn && !walletInfo?.WalletId) || (loggedIn && walletInfo?.WalletId && !userOwnsCardsOrTickets) ? styles.red_border_hold_ticket : '')} ${(loggedIn && walletInfo?.WalletId && userOwnsCardsOrTickets) ? styles.padding_applied_on_nft_verified : ''}`}>
                                    <div className={styles.wallet_verify_card_hold_top_section}>
                                        <span className={`${styles.wallet_verify_card_hold_text} ${((loggedIn && !walletInfo?.WalletId) || (loggedIn && walletInfo?.WalletId && !userOwnsCardsOrTickets) ? styles.no_nft_tower_battle_text : '')}`}>
                                        {'Hold TOWER Battle Card / Tickets'} 
                                        </span>
                                        <img src={(loggedIn && walletInfo?.WalletId && userOwnsCardsOrTickets) ? nft_verified : nft_before} alt={(loggedIn && walletInfo?.WalletId && userOwnsCardsOrTickets) ? 'nft-verified' : 'nft-before'} className={styles.verified_img} />
                                        <div className={styles.hold_tower_battle_bottom_section}>
                                            {loggedIn && walletInfo?.WalletId ? 
                                            (
                                                <>
                                                    <div className={`${userOwnsCardsOrTickets ? styles.verified : styles.not_verified} `}>
                                                        <div className={`${userOwnsCardsOrTickets ? styles.success_ellipse : styles.fail_ellipse} `}></div>
                                                        <div className={`${userOwnsCardsOrTickets ? styles.verified_text : styles.not_verified_text} `}>{userOwnsCardsOrTickets ? 'NFT Verified' : 'No NFT Detected'}</div>
                                                    </div>
                                                </>
                                            )
                                            : 
                                            ''
                                            }
                                            <div className={styles.hold_tower_battle_btn_group}>
                                                <a href={battleCardsOpenSeaLink} target="_blank" rel="noopener noreferrer">
                                                    <button className={styles.blue_button}>
                                                        <img src={IconOpenSeaWhite} alt="Opensea" width="15px" height="15px" />
                                                        <span>{'Buy Cards'}</span>
                                                    </button>
                                                </a>
                                        
                                    
                                                <a href={binanceTicketsURL} target="_blank" rel="noopener noreferrer">
                                                    <button className={styles.blue_button}>
                                                        <img src={IconBinanceWhite} width="14px" height="14px" alt="Binance" />
                                                        <span>{'Buy Tickets'}</span>
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {loggedIn && walletInfo?.WalletId ? 
                                    (
                                        <>
                                            <span className={styles.hold_tower_battle_bottom_text}>
                                                {'* You need to withdraw your'} <strong>{' Ticket '}</strong>{'from'}<a href='https://binance.com/'><strong>{' Binance.com'}</strong></a>
                                            </span>
                                        </>
                                    )
                                    : 
                                    ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.horizontal_separator}></div>
                {/*Event Details Section*/}
                <div className={styles.event_details_section}>
                    <img className={styles.ornament_top_left} src={OrnamentTopLeft} alt='ornament-top-left' />
                    <img className={styles.ornament_top_right} src={OrnamentTopRight} alt='ornament-top-right' />
                    <img className={styles.ornament_bottom_left} src={OrnamentBottomLeft} alt='ornament-bottom-left' />
                    <img className={styles.ornament_bottom_right} src={OrnamentBottomRight} alt='ornament-bottom-right' />
                    <div className={styles.event_details_section_title}>
                        <img src={BoardApeSmiling} alt="boardape" />
                        <span className={styles.title_text}>Event Details</span>
                    </div>
                    <div className={styles.event_details_section_description}>
                        {t('INVASION_EVENT_DETAILS_DESCRIPTION')}
                    </div>
                    <div className={styles.view_details_btn}>
                        <a href='https://medium.com/tower-token/introducing-the-2022-crazy-defense-heroes-halloween-invasion-bored-ape-edition-aba2ffa6088c' target="_blank" rel="noopener noreferrer">
                            <span>{'View Details on Medium'}</span>
                        </a>
                        {/* <a href='#' target="_blank" rel="noopener noreferrer">
                            <span>{'View Full Rewards List'}</span>
                        </a> */}
                    </div>
                    <div className={styles.total_rewards_header}>
                        <img src={DoubleArrow} alt='arrow-line'/>
                        <div className={`${styles.total_rewards_header_title} ${styles.mobile_hideable}`}>TOWER League Redeemables</div>
                        <div className={`${styles.total_rewards_header_title} ${styles.desktop_hideable}`}>Rewards</div>
                        <img src={DoubleArrow} alt='arrow-line'/>
                    </div>
                    <div className={styles.total_redeemables_body}>
                        <div className={styles.total_rewards_contents}>
                            <div className={styles.total_rewards_contents_box1}>
                                <img src={TowerImgOne} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box2}>
                                <img src={TowerImgTwo} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box3}>
                                <img src={TowerImgThree} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box4}>
                                <img src={TowerImgFour} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box5}>
                                <img src={TowerImgFive} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box6}>
                                <img src={TowerImgSix} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box7}>
                                <img src={TowerImgSeven} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box8}>
                                <img src={TowerImgEight} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box9}>
                                <img src={TowerImgNine} alt='tower-img' />
                            </div>
                            <div className={styles.total_rewards_contents_box10}>
                                <img src={TowerImgTen} alt='tower-img' />
                            </div>
                        </div>
                        <div className={styles.bottom_main_text}>{'Otherdeed for Otherside NFTs'}</div>
                        <div className={styles.bottom_secondary_text}>{'Floor Price: ~1.68 ETH'}</div>
                        <div className={styles.long_para_event_details}>{'Otherdeed is the key to claiming land in Otherside. Each have a unique blend of environment and sediment - some with resources, some home to powerful artifacts. And on a very few, a Koda roams'}</div>
                        <div className={styles.view_details_btn}>
                            <a href='https://opensea.io/collection/otherdeed' target="_blank" rel="noopener noreferrer">
                                <span>{'View the NFT Collection on OpenSea'}</span>
                            </a>
                        </div>
                    </div>
                </div>    
                <PointsEarningSteps />

                {showLoginModal &&
                    <Login
                        title={t('Login to TOWER')}
                        descriptions={t('Please login your CDH User ID to complete your action.')}
                        loginCharacterImage={LoginAvatar}
                        setShow={setShowLoginModal}
                        setLoggedIn={setLoggedIn}
                        setUserDetails={setUserDetails}
                        setWalletInfo={setWalletInfo}
                    />
                }
            </div>
        </>
    );
};

export { TowerXBaycPage };
