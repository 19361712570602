import React, { useContext, useEffect, useReducer, useState } from "react";
import { Web3Context } from "../../web3/Web3Context";
import { TYPES } from "./ActionTypes";
import { ABIContext } from ".";
import { fetchTowerBalance } from './Actions/TokenActions';

const TokenContext = React.createContext();
const TokenConsumner = TokenContext.Consumer;

const initialState = {
    giftedTokenIds: [],
    tokenBalance: "0.00",
    userId: "",
    updatePrepaid: 1,
    updateToken: 1,
    chestBalance: {
        goerli: {
            bronze: 0,
            silver: 0,
            gold: 0,
        },
        matic: {
            bronze: 0,
            silver: 0,
            gold: 0,
        },
    },
    ticketBalance: {
        bsc: {
            gold: {
                count: 0,
                tokens: [],
            },
            silver: {
                count: 0,
                tokens: [],
            },
            bronze: {
                count: 0,
                tokens: [],
            },
        },
        polygon: {
            gold: {
                count: 0,
            },
            silver: {
                count: 0,
            },
            bronze: {
                count: 0,
            }
        },
    },
    mapBalance: {
        polygon: {
            mapCommon: {
                count: 0,
            },
            mapRare: {
                count: 0,
            },
            mapEpic: {
                count: 0,
            },
            mapLegendary: {
                count: 0,
            }
        },
    },
    skinBalance: {
        bsc: {
            soulHarvester: {
                count: 0,
                tokens: [],
            },
            phantomQueen: {
                count: 0,
                tokens: [],
            },
            miraculousAngel: {
                count: 0,
                tokens: [],
            },
            divineChampion: {
                count: 0,
                tokens: [],
            },
            sparklingFairy: {
                count: 0,
                tokens: [],
            },
            archMage: {
                count: 0,
                tokens: [],
            },
            fantasyHealer: {
                count: 0,
                tokens: [],
            },
            magicalWarrior: {
                count: 0,
                tokens: [],
            },
            assassinFemale: {
                count: 0,
                tokens: [],
            },
            assassinMale: {
                count: 1,
                tokens: [],
            },
            captainFemale: {
                count: 0,
                tokens: [],
            },
            captainMale: {
                count: 0,
                tokens: [],
            },
            soldierFemale: {
                count: 0,
                tokens: [],
            },
            soldierMale: {
                count: 0,
                tokens: [],
            },
            rougeFemale: {
                count: 0,
                tokens: [],
            },
            rougeMale: {
                count: 0,
                tokens: [],
            },
            bardFemale: {
                count: 1,
                tokens: [],
            },
            bardMale: {
                count: 1,
                tokens: [],
            },
        },
        polygon: {
            soulHarvester: {
                count: 0,
            },
            phantomQueen: {
                count: 0,
            },
            miraculousAngel: {
                count: 0,
            },
            divineChampion: {
                count: 0,
            },
            sparklingFairy: {
                count: 0,
            },
            archMage: {
                count: 0,
            },
            fantasyHealer: {
                count: 0,
            },
            magicalWarrior: {
                count: 0,
            },
            assassinFemale: {
                count: 0,
            },
            assassinMale: {
                count: 0,
            },
            captainFemale: {
                count: 0,
            },
            captainMale: {
                count: 0,
            },
            soldierFemale: {
                count: 0,
            },
            soldierMale: {
                count: 0,
            },
            rougeFemale: {
                count: 0,
            },
            rougeMale: {
                count: 0,
            },
            bardFemale: {
                count: 0,
            },
            bardMale: {
                count: 0,
            },
        }
    },
    bridgingTicketType: '',
    bridgingSkinType: '',
    itemsOwned: null,
    bridgeChest: null,
    totalChestCount: 0,
    totalCardCount: 0,
    totalBSCTicketCount: 0,
    totalMapCount: 0,
    totalSkinCount: 0,
    openChest: null,
    cards: null,
    latestCardDetails: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.RESET:
            return initialState;

        case TYPES.SET_USER_ID:
            return {
                ...state,
                userId: action.payload,
            };
        case TYPES.SET_TOKEN_BALANCE:
            return {
                ...state,
                tokenBalance: action.payload,
            };
        case TYPES.SET_TOKENS_OWNED:
            return {
                ...state,
                itemsOwned: action.payload,
            };
        case TYPES.SET_UPDATE_TOKEN:
            return {
                ...state,
                updateToken: action.payload,
            };
        case TYPES.SET_CHEST_COUNT_MATIC:
            return {
                ...state,
                chestBalance: {
                    ...state.chestBalance,
                    matic: {
                        ...state.chestBalance.matic,
                        gold: action.payload.gold,
                        silver: action.payload.silver,
                        bronze: action.payload.bronze,
                    },
                },
            };

        case TYPES.SET_TICKET_COUNT_BSC:
            return {
                ...state,
                ticketBalance: {
                    ...state.ticketBalance,
                    bsc: {
                        ...state.ticketBalance.bsc,
                        gold: {
                            ...state.ticketBalance.bsc.gold,
                            count: action.payload.gold.count,
                            tokens: action.payload.gold.tokens,
                        },
                        silver: {
                            ...state.ticketBalance.bsc.silver,
                            count: action.payload.silver.count,
                            tokens: action.payload.silver.tokens,
                        },
                        bronze: {
                            ...state.ticketBalance.bsc.bronze,
                            count: action.payload.bronze.count,
                            tokens: action.payload.bronze.tokens,
                        },
                    },
                },
                totalBSCTicketCount: (action.payload.gold.count + action.payload.silver.count + action.payload.bronze.count),
            };

        case TYPES.SET_TICKET_COUNT_MATIC:
            return {
                ...state,
                ticketBalance: {
                    ...state.ticketBalance,
                    polygon: {
                        ...state.ticketBalance.polygon,
                        gold: {
                            ...state.ticketBalance.polygon.gold,
                            count: action.payload.gold.count,
                            tokenId: action.payload.gold.tokenId,
                        },
                        silver: {
                            ...state.ticketBalance.polygon.silver,
                            count: action.payload.silver.count,
                            tokenId: action.payload.silver.tokenId,
                        },
                        bronze: {
                            ...state.ticketBalance.polygon.bronze,
                            count: action.payload.bronze.count,
                            tokenId: action.payload.bronze.tokenId,
                        },
                    },
                },
            };

        case TYPES.SET_MAP_COUNT_MATIC:
            return {
                ...state,
                mapBalance: {
                    ...state.mapBalance,
                    polygon: {
                        ...state.mapBalance.polygon,
                        mapCommon: {
                            ...state.mapBalance.polygon.mapCommon,
                            count: action.payload.mapCommon.count,
                        },
                        mapRare: {
                            ...state.mapBalance.polygon.mapRare,
                            count: action.payload.mapRare.count,
                        },
                        mapEpic: {
                            ...state.mapBalance.polygon.mapEpic,
                            count: action.payload.mapEpic.count,
                        },
                        mapLegendary: {
                            ...state.mapBalance.polygon.mapLegendary,
                            count: action.payload.mapLegendary.count,
                        },
                    },
                },
            }

        case TYPES.SET_SKIN_COUNT_BSC:
            return {
                ...state,
                skinBalance: {
                    ...state.skinBalance,
                    bsc: {
                        ...state.skinBalance.bsc,
                        miraculousAngel: {
                            ...state.skinBalance.bsc.miraculousAngel,
                            count: action.payload.miraculousAngel.count,
                            tokens: action.payload.miraculousAngel.tokens,
                        },
                        divineChampion: {
                            ...state.skinBalance.bsc.divineChampion,
                            count: action.payload.divineChampion.count,
                            tokens: action.payload.divineChampion.tokens,
                        },
                        sparklingFairy: {
                            ...state.skinBalance.bsc.sparklingFairy,
                            count: action.payload.sparklingFairy.count,
                            tokens: action.payload.sparklingFairy.tokens,
                        },
                        archMage: {
                            ...state.skinBalance.bsc.archMage,
                            count: action.payload.archMage.count,
                            tokens: action.payload.archMage.tokens,
                        },
                        fantasyHealer: {
                            ...state.skinBalance.bsc.fantasyHealer,
                            count: action.payload.fantasyHealer.count,
                            tokens: action.payload.fantasyHealer.tokens,
                        },
                        magicalWarrior: {
                            ...state.skinBalance.bsc.magicalWarrior,
                            count: action.payload.magicalWarrior.count,
                            tokens: action.payload.magicalWarrior.tokens,
                        },
                        assassinFemale: {
                            ...state.skinBalance.bsc.assassinFemale,
                            count: action.payload.assassinFemale.count,
                            tokens: action.payload.assassinFemale.tokens,
                        },
                        assassinMale: {
                            ...state.skinBalance.bsc.assassinMale,
                            count: action.payload.assassinMale.count,
                            tokens: action.payload.assassinMale.tokens,
                        },
                        captainFemale: {
                            ...state.skinBalance.bsc.captainFemale,
                            count: action.payload.captainFemale.count,
                            tokens: action.payload.captainFemale.tokens,
                        },
                        captainMale: {
                            ...state.skinBalance.bsc.captainMale,
                            count: action.payload.captainMale.count,
                            tokens: action.payload.captainMale.tokens,
                        },
                        soldierFemale: {
                            ...state.skinBalance.bsc.soldierFemale,
                            count: action.payload.soldierFemale.count,
                            tokens: action.payload.soldierFemale.tokens,
                        },
                        soldierMale: {
                            ...state.skinBalance.bsc.soldierMale,
                            count: action.payload.soldierMale.count,
                            tokens: action.payload.soldierMale.tokens,
                        },
                        rougeFemale: {
                            ...state.skinBalance.bsc.rougeFemale,
                            count: action.payload.rougeFemale.count,
                            tokens: action.payload.rougeFemale.tokens,
                        },
                        rougeMale: {
                            ...state.skinBalance.bsc.rougeMale,
                            count: action.payload.rougeMale.count,
                            tokens: action.payload.rougeMale.tokens,
                        },
                        bardFemale: {
                            ...state.skinBalance.bsc.bardFemale,
                            count: action.payload.bardFemale.count,
                            tokens: action.payload.bardFemale.tokens,
                        },
                        bardMale: {
                            ...state.skinBalance.bsc.bardMale,
                            count: action.payload.bardMale.count,
                            tokens: action.payload.bardMale.tokens,
                        },
                    },
                },
            };

        case TYPES.SET_SPECIAL_SKIN_COUNT_BSC:
            return {
                ...state,
                skinBalance: {
                    ...state.skinBalance,
                    bsc: {
                        ...state.skinBalance.bsc,
                        soulHarvester: {
                            ...state.skinBalance.bsc.soulHarvester,
                            count: action.payload.soulHarvester.count,
                            tokens: action.payload.soulHarvester.tokens,
                        },
                        phantomQueen: {
                            ...state.skinBalance.bsc.phantomQueen,
                            count: action.payload.phantomQueen.count,
                            tokens: action.payload.phantomQueen.tokens,
                        },
                    }
                }
            }

        case TYPES.SET_SKIN_COUNT_MATIC:
            return {
                ...state,
                skinBalance: {
                    ...state.skinBalance,
                    polygon: {
                        ...state.skinBalance.polygon,
                        soulHarvester: {
                            ...state.skinBalance.polygon.soulHarvester,
                            count: action.payload.soulHarvester.count,
                        },
                        phantomQueen: {
                            ...state.skinBalance.polygon.phantomQueen,
                            count: action.payload.phantomQueen.count,
                        },
                        miraculousAngel: {
                            ...state.skinBalance.polygon.miraculousAngel,
                            count: action.payload.miraculousAngel.count,
                        },
                        divineChampion: {
                            ...state.skinBalance.polygon.divineChampion,
                            count: action.payload.divineChampion.count,
                        },
                        sparklingFairy: {
                            ...state.skinBalance.polygon.sparklingFairy,
                            count: action.payload.sparklingFairy.count,
                        },
                        archMage: {
                            ...state.skinBalance.polygon.archMage,
                            count: action.payload.archMage.count,
                        },
                        fantasyHealer: {
                            ...state.skinBalance.polygon.fantasyHealer,
                            count: action.payload.fantasyHealer.count,
                        },
                        magicalWarrior: {
                            ...state.skinBalance.polygon.magicalWarrior,
                            count: action.payload.magicalWarrior.count,
                        },
                        assassinFemale: {
                            ...state.skinBalance.polygon.assassinFemale,
                            count: action.payload.assassinFemale.count,
                        },
                        assassinMale: {
                            ...state.skinBalance.polygon.assassinMale,
                            count: action.payload.assassinMale.count,
                        },
                        captainFemale: {
                            ...state.skinBalance.polygon.captainFemale,
                            count: action.payload.captainFemale.count,
                        },
                        captainMale: {
                            ...state.skinBalance.polygon.captainMale,
                            count: action.payload.captainMale.count,
                        },
                        soldierFemale: {
                            ...state.skinBalance.polygon.soldierFemale,
                            count: action.payload.soldierFemale.count,
                        },
                        soldierMale: {
                            ...state.skinBalance.polygon.soldierMale,
                            count: action.payload.soldierMale.count,
                        },
                        rougeFemale: {
                            ...state.skinBalance.polygon.rougeFemale,
                            count: action.payload.rougeFemale.count,
                        },
                        rougeMale: {
                            ...state.skinBalance.polygon.rougeMale,
                            count: action.payload.rougeMale.count,
                        },
                        bardFemale: {
                            ...state.skinBalance.polygon.bardFemale,
                            count: action.payload.bardFemale.count,
                        },
                        bardMale: {
                            ...state.skinBalance.polygon.bardMale,
                            count: action.payload.bardMale.count,
                        },
                    },
                },
            };

        case TYPES.SET_BRIDGING_TICKET_TYPE_BSC:
            return {
                ...state,
                bridgingTicketType: action.payload,
            };

        case TYPES.SET_BRIDGING_SKIN_TYPE_BSC:
            return {
                ...state,
                bridgingSkinType: action.payload,
            };
        
        case TYPES.SET_CHEST_COUNT_GOERLI:
            return {
                ...state,
                chestBalance: {
                    ...state.chestBalance,
                    goerli: {
                        ...state.chestBalance.goerli,
                        gold: action.payload.gold,
                        silver: action.payload.silver,
                        bronze: action.payload.bronze,
                    },
                },
            };
        case TYPES.SET_BRIDGE_CHEST:
            return {
                ...state,
                bridgeChest: {
                    name: action.payload.name,
                    tokenContractName: action.payload.tokenContractName
                }
            };
        case TYPES.SET_TOTAL_CHEST_COUNT:
            return {
                ...state,
                totalChestCount: action.payload,
            };
        case TYPES.SET_TOTAL_CARD_COUNT:
            return {
                ...state,
                totalCardCount: action.payload,
            };

        case TYPES.SET_OPEN_CHEST:
            return {
                ...state,
                openChest: action.payload,
            };
        case TYPES.SET_CARDS:
            return {
                ...state,
                cards: action.payload,
            };
        case TYPES.SET_LATEST_CARD_DETAILS:
            return {
                ...state,
                latestCardDetails: action.payload.latestCardDetails
            }

        default:
            return state;
    }
};

const TokenProvider = (props) => {
    const { selectedAccount, networkId } = useContext(Web3Context);
    let cancel = false;
    const [abiConfig] = useContext(ABIContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [fetching, setFetching] = useState(false);

    const clearTokensBalance = () => {
        dispatch({
            type: TYPES.SET_TOKEN_BALANCE,
            payload: "0.00"
        });
    };

    const deploymentContexts = {
        1: abiConfig.ethereumDeploymentContext,
        5: abiConfig.ethereumDeploymentContext,
        137: abiConfig.polygonDeploymentContext,
        80001: abiConfig.polygonDeploymentContext,
        56: abiConfig.binanceDeploymentContext,
        97: abiConfig.binanceDeploymentContext,
    }

    const networkProviders = {
        1: abiConfig.ethereumProvider,
        5: abiConfig.ethereumProvider,
        137: abiConfig.polygonProvider,
        80001: abiConfig.polygonProvider,
        56: abiConfig.binanceProvider,
        97: abiConfig.binanceProvider,
    }

    const getToken = async () => {
        setFetching(true);
        let towerBalance = await fetchTowerBalance(networkId, selectedAccount, networkProviders[networkId], deploymentContexts[networkId], false);
        if (towerBalance) {
            dispatch({
                type: TYPES.SET_TOKEN_BALANCE,
                payload: towerBalance
            });
        }
        setFetching(false);
    }

    // const fetchToken = async (environment, contractName, truncate) => {
    //     try {
    //         if (networkId === envId) {
    //             const contract = await environment.getContract(
    //                 contractName,
    //                 web3
    //             );
    //             const res = await contract.methods
    //                 .balanceOf(selectedAccount)
    //                 .call();
    //             const fromWeiBalance = Number(fromWei(res.toString()));
    //             let balance = (Math.round(fromWeiBalance * 100) / 100).toFixed(
    //                 2
    //             );
    //             if (truncate) {
    //                 balance = Math.trunc(balance);
    //             }
    //             !cancel &&
    //                 dispatch({
    //                     type: TYPES.SET_TOKEN_BALANCE,
    //                     payload: balance,
    //                 });
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    useEffect(() => {
        if (selectedAccount && abiConfig.ethereumProvider && abiConfig.polygonProvider && abiConfig.binanceProvider && networkId && abiConfig.polygonDeploymentContext) {
            clearTokensBalance();
            getToken();
        }

        if (!selectedAccount) {
            clearTokensBalance();
        }

        return () => {cancel = true}
    }, [selectedAccount, networkId, abiConfig.ethereumProvider, abiConfig.polygonProvider, abiConfig.binanceProvider, abiConfig.polygonDeploymentContext]);

    useEffect(() => {
        if (!fetching && !selectedAccount && state.tokenBalance) {
            clearTokensBalance();
        }
    }, [fetching])

    return (
        <TokenContext.Provider value={[state, dispatch]}>
            {props.children}
        </TokenContext.Provider>
    );
};

export { TokenContext, TokenProvider, TokenConsumner };