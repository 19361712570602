import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../assets/imgs/loader.gif';
import { Modal, Button } from 'react-bootstrap';
import styles from './BridgeProcess.module.scss';
import { useTranslation } from 'react-i18next';

import ethereumIcon from '../../assets/imgs/bridge/ethereum-logo52x52.png';
import polygonIcon from '../../assets/imgs/bridge/polygon-matic-logo52x52.png';
import arrowRight from '../../assets/imgs/bridge/blue-arrow-2x.png';

import bscScan from '../../assets/imgs/bridge/bscscan.png';
import polyScan from '../../assets/imgs/bridge/icon-polyscan.png';
import etherScan from '../../assets/imgs/bridge/etherscan.png';

import iconFailed from '../../assets/icons/icon-failed.png';
import iconSuccess from '../../assets/imgs/bridge/icon-success.png';
import { useHistory } from 'react-router-dom';

import { skinMap } from '../Skins/utils';

import chestApproveProgressBar from '../../assets/imgs/bridge/progress-bar-step1.png';
import chestConfirmedProgressBar from '../../assets/imgs/bridge/progress-bar-step2.png';
import chestCompletedProgressBar from '../../assets/imgs/bridge/progress-bar-step3.png';

import waitingApprovalProgressBar from '../../assets/imgs/ticket/progress-bar-step0.png';
import approveProgressBar from '../../assets/imgs/ticket/progress-bar-step1.png';
import confirmedProgressBar from '../../assets/imgs/ticket/progress-bar-step2.png';
import completedProgressBar from '../../assets/imgs/ticket/progress-bar-step3.png';

import GoldTicketImg from '../../assets/imgs/gold_ticket.png';
import SilverTicketImg from '../../assets/imgs/silver_ticket.png';
import BronzeTicketImg from '../../assets/imgs/bronze_ticket.png';

const etherScanLink = process.env.REACT_APP_ETHEREUM_BLOCK_EXPLORER_URL + 'tx/';

const BridgeProcess = (props) => {
    const { t } = useTranslation();
    const [displayWaitingApproval, setDisplayWaitingApproval] = useState(false);
    const [displaytimingInfo, setDisplayTimingInfo] = useState(false);
    const [bridgeSuccess, setBridgeSuccess] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [failed, setFailed] = useState(false);
    const [show, setShow] = useState(false);
    const [prevChestValue, setPrevChestValue] = useState(0);
    const history = useHistory();

    const {
        confirm, 
        chests,
        tickets,
        skinCount,
        chestType,
        skinType,
        ticketType,
        setBridgeConfirm, 
        txnHash,
        mintTxnHash
    } = props;

    const renderChestModalHeaderProgressBar = () => {
        return (
            <>
            {!bridgeSuccess
                ?
                    <>
                        <div className={`${styles.header_texts} ${(chestType !== undefined) ? styles.header_texts_chest : ''}`}>{t('BRIDGING')}</div>
                        {!canceled && !txnHash 
                            ?
                                <div className={styles.progres_bar}>
                                    <img src={chestApproveProgressBar} alt="progress-bar" />
                                </div>
                            : !canceled && txnHash 
                                ?
                                    <div className={styles.progress_bar}>
                                        <img src={chestConfirmedProgressBar} alt="progress-bar" />
                                    </div>
                                : ''
                        }
                    </>
                :
                    <>
                        <div className={`${styles.header_texts} ${(chestType !== undefined) ? styles.header_texts_chest : ''}`}>{t('BRIDGE_SUCCESS')}</div>
                        <div className={styles.progress_bar}>
                            <img src={chestCompletedProgressBar} alt="progress-bar" />
                        </div>
                    </>
            }
            </>
        )
    }

    const renderModalHeaderProgressBar = () => {
        return(
            <>
            {!bridgeSuccess 
                ?
                    <>
                    <div className={styles.header_texts}>{t('APPROVE_BRIDGING')}</div>
                    
                    {/* Rendering of header progress bar */}
                    {/* if bridging is not cancelled by user but has no transaction hash */}
                    {!canceled && !txnHash ?
                        <>
                        { displayWaitingApproval 
                            ?
                            <div className={styles.progress_bar}>
                                <img src={waitingApprovalProgressBar} alt="progress-bar" />
                            </div>
                            :
                            <div className={styles.progress_bar}>
                                <img src={approveProgressBar} alt="progress-bar" />
                            </div>
                        }
                        </>
                        // if bridging is not cancelled and has transaction hash
                        : !canceled && txnHash ?
                            <div className={styles.progress_bar}>
                                <img src={confirmedProgressBar} alt="progress-bar" />
                            </div>
                            : ''
                    }
                    </>
                :
                    <>
                        <div className={styles.header_texts}>{t('BRIDGE_SUCCESS')}</div>
                        <div className={styles.progress_bar}>
                            <img src={completedProgressBar} alt="progress-bar" />
                        </div>
                    </>
            
            }
            </>
        )
    }

    const renderChestBridgingSuccess = () => {
        return (
            <>
                <img src={iconSuccess} />
                <div className={styles.transfer_wrapper}>
                    <div className={styles.polygon_wrapper}>
                        <div className={styles.modeText}>{t('FROM_NETWORK')}</div>
                        <img src={ethereumIcon} className={styles.ethereumIcon} />
                        <div className={styles.modeText}>{t('ETHEREUM')}</div>
                    </div>
                    <img src={arrowRight} className={styles.trasferArrow} />
                    <div className={styles.ethereum_wrapper}>
                        <div className={styles.modeText}>{t('TO_NETWORK')}</div>
                        <img src={polygonIcon} className={styles.polygonIcon} />
                        <div className={styles.modeText}>{t('POLYGON')}</div>
                    </div>
                </div>
                <div className={styles.success_info}>
                    { prevChestValue + " " + chestType.toUpperCase() + " " + t('CHEST_READY_NETWORK_INFO')}
                </div>
            </>
        )
    }

    const renderBridgingSuccess = () => {
        return (
            <>
                <div className={styles.success_wrapper}>
                    <div>
                        <img className={styles.success_icon} src={iconSuccess} />
                    </div>
                    
                    <div className={styles.success_text}>
                        {t('ITEMS_BRIDGED_TO_POLYGON_NETWORK')}
                    </div>

                    <div className={styles.success_item_type_count}>
                        <div className={styles.success_item_type_count_content}>
                            <div>
                                { ticketType === 'gold' && <img className={styles.item_image} src={GoldTicketImg} />}
                                { ticketType === 'silver' && <img className={styles.item_image} src={SilverTicketImg} />}
                                { ticketType === 'bronze' && <img className={styles.item_image} src={BronzeTicketImg} />}
                                { (skinType !== undefined) && <img className={`${styles.item_image} ${(skinType !== undefined ) ? styles.item_image_skin : ''}`} src={skinMap[skinType]['img']} />}
                            </div>
                            <div className={styles.item_type}>
                                {tickets && (ticketType.charAt(0).toUpperCase() + ticketType.slice(1))}
                                {skinCount && skinMap[skinType]['name']}
                            </div>
                            <div className={styles.item_count}>
                                {`x${(tickets || skinCount)}`}
                            </div>
                        </div>
                    </div>
                    
                    <div className={styles.done_btn}>
                        <Button className={styles.ok_button} onClick={() => {
                            setShow(false); 
                            setBridgeConfirm(false); 
                            (tickets && history.push("/inventory/tickets") || skinCount && history.push("/inventory/skins"))
                        }}>
                            {'Done'}
                        </Button>
                    </div>

                    {mintTxnHash && (
                    <div className={styles.polygonScan}>
                        <a href={mintTxnHash && `${process.env.REACT_APP_POLYGON_BLOCK_EXPLORER_URL}tx/${mintTxnHash}`} target="_blank" className={`${styles.polygonRedirect} ${(mintTxnHash === '') && styles.polygonLinkDisable}`}>
                            <img src={polyScan}/>
                            <div className={styles.polygonScanView}>{t('VIEW_POLYSCAN')}</div>
                        </a>
                    </div>
                    )}
                </div>  
            </>
        )
    }


    useEffect(() => {
        if (confirm !== '') {
            setShow(true);
            if (confirm === 'waitingapproval') {
                setDisplayWaitingApproval(true);
            }
            if (confirm === 'deposit') {
                setDisplayWaitingApproval(false);
                setDisplayTimingInfo(true);
            }

            // need another check here, since this is only transaction confirmation
            // but we need confirmation but backend that token is successfully bridged
            if (confirm === 'awaiting-mint') {
                setDisplayTimingInfo(true)
            }
            if (confirm === 'deposit-success') {
                setBridgeSuccess(true);
            }
            if (confirm === 'failed') {
                setFailed(true);
            }
            if (confirm === 'canceled') {
                setCanceled(true);
            }
        } else {
            setShow(false);
        }
    }, [confirm])

    useEffect(() => {
        if (chests) {
            setPrevChestValue(parseInt(chests));
        }
    }, [chests])

    return (
        <Modal show={show} className={styles.bridge_modal} centered>
            <Modal.Header>
                <div className={`${styles.close_btn} ${(chestType !== undefined) ? styles.close_btn_chest : ''}`} 
                    onClick={ () => {
                        setShow(false); 
                        setBridgeConfirm(false); 
                        ((chestType !== undefined) && history.push("/inventory/chests") || (ticketType !== undefined) && history.push("/inventory/tickets") || (skinType !== undefined) && history.push("/inventory/skins"))
                    }}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className={`${styles.bridge_modal_header} ${(chestType !== undefined) ? styles.bridge_modal_header_chest : ''}`}>
                    { (chestType !== undefined) ? renderChestModalHeaderProgressBar() : renderModalHeaderProgressBar() }
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.bridge_modal_body}>
                    {!bridgeSuccess ?
                        <>
                            {!canceled && !failed &&
                                <>
                                    <img src={Loader} alt="spinner" className={styles.bridge_spinner} />
                                    <div className={styles.processing}>{t('PROCESSING')}</div>
                                    {(confirm !== 'awaiting-mint' && confirm !== 'deposit-success') && 
                                        <div className={styles.processing_msg}>
                                            { t('PLEASE')}
                                            <strong>&nbsp;{t('CONFIRM')}&nbsp;</strong> 
                                            { t('TO_COMPLETE_TRANSACTION') } 
                                        </div>}
                                </>
                            }

                            {canceled && !failed && (
                                <>
                                    <div className={styles.canceled}>{t('TRANSACTION_CANCEL')}</div>
                                    <Button className={styles.ok_button} onClick={() => {setShow(false); setCanceled(false);}}>{t('OK')}</Button>
                                </>
                            )}

                            {!canceled && failed && (
                                <>
                                    <div className={styles.txn_failed_img}>
                                        <img src={iconFailed} width="73px" height="68px" />
                                    </div>
                                    <div className={styles.failed}>{t('TRANSACTION_FAIL')}</div>
                                    <Button className={styles.ok_button} onClick={() => {
                                        setShow(false); 
                                        setBridgeConfirm(false); 
                                        (tickets && history.push("/inventory/tickets") || skinCount && history.push("/inventory/skins"))
                                    }}>
                                        {t('BACK')}
                                    </Button>
                                </>
                            )}

                            {!canceled && !failed && (displaytimingInfo) && (
                                <div className={styles.timing_info}>
                                        {
                                            t('YOUR')} {(prevChestValue || tickets || skinCount) + " " + 
                                            t(`${(prevChestValue && chestType.toUpperCase()) || (tickets && ticketType.toUpperCase()) || (skinCount && t(`${skinMap[skinType]['name']}`)) }`) + " "} 
                                            {(prevChestValue && t('CHEST_READY_INFO')) || (tickets && t('TICKETS_READY_INFO')) || (skinCount && t('SKINS_READY_INFO'))}
                                            <span className={styles.special_text}>
                                                &nbsp;{t('10_MINS')}&nbsp;
                                            </span>
                                </div>
                            )}

                            {txnHash && (
                                <div className={styles.hashWrapper}>
                                    <div className={styles.titleHash}>
                                        {t('TXHASH')}:
                                    </div>
                                    <div className={styles.valueHash}>
                                        {txnHash}
                                    </div>
                                </div>
                            )}

                            {txnHash && (
                            <div className={styles.etherScan}>
                                <a href={txnHash && (chestType !== undefined) ? `${etherScanLink}${txnHash}` : `${process.env.REACT_APP_BINANCE_BLOCK_EXPLORER_URL}tx/${txnHash}`} target="_blank" className={`${styles.etherRedirect} ${!txnHash && styles.disableButton}`}>
                                    <img src={(chestType !== undefined) ? etherScan : bscScan} />
                                    <div className={styles.view}>{ (chestType !== undefined) ? t('VIEW_ETHERSCAN') : t('VIEW_BSCSCAN')}</div>
                                </a>
                            </div>
                            )}
                        </>
                        :
                            ((chestType !== undefined) ? renderChestBridgingSuccess() : renderBridgingSuccess())
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export { BridgeProcess }
