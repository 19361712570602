import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import styles from './PlayToEarn.module.scss';
import * as qrCkLogo from '../../assets/imgs/qr_ck.png';
import * as qrCdhLogo from '../../assets/imgs/qr_cdh.png';
import cdhLogo from '../../assets/imgs/logo_en.png';
import ckLogo from '../../assets/imgs/logo_crazy_kings.png';
import androidLogo from '../../assets/icons/icon-android.svg';
import appleLogo from '../../assets/icons/icon-apple.svg';

export function PlayToEarn() {
    const [t] = useTranslation();
    const cdhGpLink = "https://go.onelink.me/bUzl/9c836b67";
    const cdhAppStoreLink = "https://go.onelink.me/VHLW/e01c1c3f";
    const ckAppStoreLink = "https://crazykings.onelink.me/nZB2/3c36c39a";
    const moreDetailsLink = "https://medium.com/tower-token";

    return <div className={styles.pte_box}>
        <div className={styles.pte_header}>
            <div className={styles.header1}>{t('PLAY_TO_EARN')}</div>
            <div className={styles.header2}>{t('DOWNLOAD_THE_GAMES')}</div>
        </div>
        <div className={styles.pte_body}>
            <section className={styles.games_section}>
                <div className={styles.game_container}>
                    <div className={styles.game_logo_container}>
                        <img src={cdhLogo} className={styles.game_logos} />
                    </div>
                    <div className={styles.description}>{t('CDH_DESCRIPTION')}</div>
                    <div className={styles.game_links}>
                        <div className={styles.non_mobile}><img src={qrCdhLogo} className={styles.qrLogo}/></div>
                        <a href={cdhGpLink} target="_blank"><img src={androidLogo} /></a>
                        <a href={cdhAppStoreLink} target="_blank"><img src={appleLogo} /></a>
                    </div>
                </div>
                <div className={styles.game_container}>
                    <div className={styles.game_logo_container}>
                        <img src={ckLogo} className={styles.game_logos} />
                    </div>
                    <div className={styles.description}>{t('CK_DESCRIPTION')}</div>
                    <div className={styles.game_links}>
                        <div className={styles.non_mobile}><img src={qrCkLogo} className={styles.qrLogo}/></div>
                        <a href={ckAppStoreLink} target="_blank"><img src={appleLogo} /></a>
                    </div>
                </div>
            </section>
            <a href={moreDetailsLink} target="_blank" className={styles.more_details}>
                <Button variant="cdh-general-blue" className={styles.btn_connect}>{t('MORE_DETAILS')}</Button>
            </a>
            <div className={styles.claim_text}>
                {t('TOWER_AVAILABLE_SOON')}
            </div>
        </div>
    </div>
}
