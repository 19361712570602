const API_URL = process.env.REACT_APP_TOWER_TOKEN_API_URL;
const PROXY_API_URL = process.env.REACT_APP_TOWER_TOKEN_PROXY_API_URL;

export async function getTower(userId, siwaId, isInChina) {
    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    let playerId = userId ? userId : siwaId;
    try {
        const url = apiUrl + `/towertoken`;

        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                playerID: playerId
            })
        };
        const response = await fetch(url, options)
        if (response.status == 500 || response.status == 400) {
            return null;
        }
        const obj = await response.json();
        return obj;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}

export async function getWallet(firebaseuuid, siwaId, isInChina) {
    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    try {
        const url = apiUrl + `/token/get_wallet`;
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'firebaseuuid': firebaseuuid,
                'siwaid': siwaId
            }
        };
        const response = await fetch(url, options)
        if (response.status == 500) {
            return null;
        }
        const obj = await response.json();
        return obj;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}

export async function bindWallet(FirebaseUuid, SiwaId, Signature, WalletId, WalletType, CdhPlayerId, isInChina) {
    // backend cant seem to properly check null, so hack to use "" first
    if (CdhPlayerId == null) {
        CdhPlayerId = "";
    }

    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    try {
        const postData = await fetch(apiUrl + '/token/bind_wallet', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({FirebaseUuid, SiwaId, Signature, WalletId, WalletType, CdhPlayerId})
        });

        if (postData.status == 500)
        {
            return null;
        }
        const result = await postData.json();
        return result;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}

export async function loginWithId(Firebaseuuid, SiwaId, CdhPlayerId, isInChina) {
    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    try {
        const postData = await fetch(apiUrl + '/login/id', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({Firebaseuuid, SiwaId, CdhPlayerId})
        });

        const result = await postData.json();
        return result;
    } catch (err) {
        console.error('Error fetching data: ' + err)
        return null;
    }
}
