import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './NewsletterComponent.scss';
import { GeneralModal } from '..';
import mage from '../../assets/imgs/img-footer.png';

export const NewsLetterComponent = () => {
    const [values, setValues] = useState({ email: null });
    const [validationIssues, setValidationIssues] = useState({
        email: 'Please provide email address',
    });
    const { t } = useTranslation();

    const emailTest = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const [dataResult, setDataResult] = useState({ data: {} });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [sendStatus, setSendStatus] = useState({
        sending: false,
        complete: false,
    });
    const [showErrorModal, setShowErrorModal] = useState(false);
    const MAILERLITE_API_KEY = process.env.REACT_APP_MAILERLITE_API_KEY;

    const handleOnChange = (e) => {
        let { name, value } = e.target;

        switch (name) {
            case 'email':
                if (value == null || value == '') {
                    setValidationIssues({
                        ...validationIssues,
                        [name]: 'Please provide email address',
                    });
                } else {
                    const emailValidation = emailTest.test(value);
                    if (!emailValidation) {
                        setValidationIssues({
                            ...validationIssues,
                            [name]: 'Invalid email address.',
                        });
                    } else {
                        setValidationIssues({
                            ...validationIssues,
                            [name]: null,
                        });
                    }
                }
                setValues({ ...values, [name]: value });
                break;
            default:
                break;
        }
    };

    const proxyurl = 'https://cors.f1deltatime.com/';

    const subscribeNewsletter = async (e) => {
        e.preventDefault();
        try {
            setSendStatus({ sending: true, complete: false });
            const postData = await fetch(
                proxyurl +
                    'https://api.mailerlite.com/api/v2/groups/106116841/subscribers',
                {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(values),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'X-MailerLite-ApiKey': MAILERLITE_API_KEY,
                    },
                }
            );

            const result = await postData.json();
            setDataResult({ ...dataResult, data: result });
            setSendStatus({ sending: false, complete: true });

            if (result.error) {
                setShowErrorModal(true);
            } else {
                setShowSuccessModal(true);
            }
        } catch (err) {
            console.error('Error fetching data: ' + err);
        }
    };

    const hasError = () => {
        return Object.values(validationIssues).filter((v) => v).length > 0;
    };

    const onHideSucessModal = () => {
        setShowSuccessModal(false);
    };

    const successModalButtonCallback = () => {
        setShowSuccessModal(false);
        setTimeout(onHideSucessModal, 0);
    };

    const errorModalButtonCallback = () => {
        setShowErrorModal(false);
    };
    return (
        <div className="footer_logo1">
            <div className="FormGroup">
                <label>{t('SUBSCRIBE_TO_NEWSLETTER')}</label>
                <div className="FormField">
                    <input
                        type="text"
                        onChange={handleOnChange}
                        name="email"
                        placeholder={t('YOUR_EMAIL')}
                        values={values.email}
                    />
                </div>
                <Button
                    className="subscribe_button"
                    variant="cdh-general-blue"
                    onClick={subscribeNewsletter}
                    disabled={hasError()}
                >
                    {!sendStatus.sending ? t('SEND') : t('PLEASE_WAIT')}
                </Button>
            </div>
            <div className="footer_logo">
                <img src={mage} alt="mage"></img>
            </div>
            
            <GeneralModal
                content={{
                    show: showSuccessModal,
                    showSetter: setShowSuccessModal,
                    title: t('SUBSCRIPTION_SUCCESS'),
                    description:
                        dataResult.data.type === 'active'
                            ? t('SUB_SUCCESS')
                            : t('THANK_YOU_FOR_SUBSCRIBING'),
                    buttonText: t('OK'),
                    buttonCallback: successModalButtonCallback,
                }}
            />

            <GeneralModal
                content={{
                    show: showErrorModal,
                    showSetter: setShowErrorModal,
                    title: t('Error Occured'),
                    description: dataResult.data.error
                        ? dataResult.data.error.message
                        : '',
                    buttonText: t('CLOSE'),
                    buttonCallback: errorModalButtonCallback,
                }}
            />
        </div>
    );
};
