import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import './SignInWithApple.scss';

const SignInWithApple = (props) => {
    const { callback } = props.content || {};

    const MyAppleSigninButton = () => (
        <AppleSignin
            authOptions={{
                clientId: 'com.animocabrands.cdh.signinwithapple.webapp',
                scope: 'email name',
                redirectURI: 'https://crazydefenseheroes.com',
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
            }}
            uiType="dark"
            className="apple-auth-btn"
            buttonExtraChildren="Sign in with Apple"
            onSuccess={(response) => {
                var token = response.authorization.id_token
                callback(token)
            }}
          onError={(error) => console.error(error)}
        />
    );

    return (
        <MyAppleSigninButton/>
    );
}

export { SignInWithApple };
