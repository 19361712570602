import React from 'react';
import styles from './MediumNews.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

function MediumNews(props) {
    const { image, title, description, time, redirectUrl } = props.content || {};

    return(
        <>
            <a href={redirectUrl} target="_blank" className={styles.news_container}>
                <div className={styles.image_container}>
                    <img src={ image } />
                </div>
                <div className={styles.news_content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}>{description}</div>
                    <div className={styles.time}><FontAwesomeIcon icon={faClock} /> {time}</div>
                </div>
            </a>
        </>
    );
}

export { MediumNews }
