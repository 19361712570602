import React, { useState, useEffect, useContext } from 'react';
import { Button, ToggleButton, ToggleButtonGroup, Image, Carousel, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {toWei, BN, toBN, fromAscii, fromWei} from 'web3-utils';
import { Web3Context } from '../../web3/Web3Context';
import { TokenContext, TYPES, ABIContext } from '../Context';
import towerLogo from '../../assets/imgs/logo-tower.svg';
import iconLeft from '../../assets/icons/icon-left.svg';
import iconRight from '../../assets/icons/icon-right.svg';
import chestBronze from '../../assets/imgs/chests_bronze.png';
import chestSilver from '../../assets/imgs/chests_silver.png';
import chestGold from '../../assets/imgs/chests_gold.png';
import dropRates from '../../assets/imgs/drop_rates.png';
import loadingWheel from '../../assets/imgs/loading_wheel.gif';
import { GeneralModal } from '../../components';
import './ChestSale.scss';
import loader from '../../assets/imgs/loading_wheel.gif';
import Web3 from 'web3';
import {estimatePriorityFee} from "../../services/contractService";
import {Web3Environment} from "ethenv";
import { PolygonNetworkInfo } from '../../services/networkServices';

const towerAddr = Object.freeze({
    1: "0x1C9922314ED1415c95b9FD453c3818fd41867d0B",
    4: "0x8329B2Dab2AD937d69327F6868aFa39420e9482b",
    80001: "0xBb2c9203c8D0C169638c6391DA2828f0278231aF",
    137: "0x2bC07124D8dAc638E290f401046Ad584546BC47b"
});

const CHAIN_ID = parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID);

const chestsData = [
    {
        img: chestGold,
        rarity: 'GOLD',
        title: 'GOLD_CHEST',
        description: 'GOLD_INFO',
        supply: 400,
        price: 12800,
        contractName: 'TOWERChest-GOLD',
        skuKey: CHAIN_ID === 137 ? 'TWR.GOLD' : 'TWR.GLD'
    },
    {
        img: chestSilver,
        rarity: 'SILVER',
        title: 'SILVER_CHEST',
        description: 'SILVER_INFO',
        supply: 2000,
        price: 4800,
        contractName: 'TOWERChest-SLVR',
        skuKey: 'TWR.SLVR'
    },
    {
        img: chestBronze,
        rarity: 'BRONZE',
        title: 'BRONZE_CHEST',
        description: 'BRONZE_INFO',
        supply: 2800,
        price: 1800,
        contractName: 'TOWERChest-BRNZ',
        skuKey: 'TWR.BRNZ'
    }
]

function ChestSale(props) {
    const metamaskInstallUrl = "https://metamask.app.link/dapp/crazydefenseheroes.com/";
    const saleContractName = "TOWERChestSale";
    const towerContractName = "PolygonTower";
    //Max BN number 2**256 - 1
    // const MAX_256_BIT_VALUE = toBN(2).pow(toBN(256)).sub(toBN(1));

    const [t] = useTranslation();
    const history = useHistory();

    const { connect, selectedAccount, ready, switchNetwork, web3 } = useContext(Web3Context);
    const [state, dispatch] = useContext(TokenContext);
    const [abiConfig] = useContext(ABIContext);
    const {polygonProvider, polygonDeploymentContext} = abiConfig;

    const [chestId, setChestId] = useState(0);
    const [chestQuantity, setChestQuantity] = useState(1);
    const [chestQtyMaxLimit, setChestQtyMaxLimit] = useState([10, 10, 10]);
    const [chestQtyError, setChestQtyError] = useState('');
    const [totalPrice, setTotalPrice] = useState(1000);
    const [remaining, setRemaining] = useState([50,250,350]);
    const [supply, setSupply] = useState([50,250,350]);

    const [buyDisabled, setBuyDisabled] = useState(false);
    const [buyPending, setBuyPending] = useState(false);

    const [approvePending, setApprovePending] = useState(false);
    const [approved, setApproved] = useState(false);

    const [isStarted, setIsStarted] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [hasFetched, setHasFetched] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showOutOfStockModal, setShowOutOfStockModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showApproveSuccessModal, setShowApproveSuccessModal] = useState(false);
    const [showPurchaseSuccessModal, setShowPurchaseSuccessModal] = useState(false);
    const [etherscanTxUrl, setEtherscanTxUrl] = useState('');

    const soldOut = remaining[chestId] <= 0;
    const notEnoughTower = parseFloat(state.tokenBalance) < totalPrice;
    const walletConnected = selectedAccount!=null && ready;
    const noMetamask = typeof window.ethereum === 'undefined' || (!window.ethereum.isMetaMask && !window.ethereum.isCoinbaseWallet && !window.ethereum.isCoinbaseBrowser);

    const createNetworkContract = async (PROVIDER, DEPLOYMENT_CONTEXT, CONTRACT_NAME) => {
        try {
            if (PROVIDER) {
                let web3Provider = new Web3(window.ethereum);
                const contract = new web3Provider.eth.Contract(DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].abi, DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].address);
                contract.setProvider(PROVIDER.url);
                return contract;
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Runs only once on first render
    useEffect(() => {
        if (polygonProvider && polygonDeploymentContext) {
            const getPaused = async () => {
                let providerObj = polygonProvider.connection;
                let deploymentContextObj = polygonDeploymentContext;
                const contract = await createNetworkContract(providerObj, deploymentContextObj, saleContractName);
                const startTime = await contract.methods.startedAt().call();
                setIsStarted(parseInt(startTime) != 0);
                const p = await contract.methods.paused().call();
                setIsPaused(p);
            }

            getPaused();
            FetchSupply();
        }
        // Always fetch supply on first render, regardless of paused state
    }, [polygonProvider, polygonDeploymentContext]);

    // chests remaining supply
    useEffect(() => {
        if (!isPaused && polygonProvider && polygonDeploymentContext) {
            FetchSupply();
        }
    }, [state.updateToken, isPaused, polygonProvider, polygonDeploymentContext])

    useEffect(() => {
        // first calculate and set new total price
        const newPrice = chestsData[chestId].price * chestQuantity;
        setTotalPrice(newPrice);

        // then determine if buy button is active
        setBuyDisabled(false);
        if(!isPaused && isStarted) {
            if (isNaN(chestQuantity)) {
                setBuyDisabled(true);
            } else {
                // Disable buy button if amount of remaining chests are not enough to purchase
                if (remaining[chestId] < chestQuantity) {
                    setBuyDisabled(true);
                }

                if (new BN(toWei(state.tokenBalance.toString())).lt(new BN(toWei(newPrice.toString())))) {
                    setBuyDisabled(true);
                }
            }
        } else {
            setBuyDisabled(true);
        }
    }, [chestId, remaining, state.tokenBalance, chestQuantity, isPaused, isStarted])

    const fetchApproval = async () => {        
        let providerObj = polygonProvider.connection;
        let deploymentContextObj = polygonDeploymentContext;

        const towerContract = await createNetworkContract(providerObj, deploymentContextObj, towerContractName);
        const saleContract = await createNetworkContract(providerObj, deploymentContextObj, saleContractName);
        const allowance = await towerContract.methods.allowance(selectedAccount, saleContract.options.address).call();

        if (toBN(allowance).lt(toBN(toWei(totalPrice.toString())))) {
            setApproved(false);
        } else {
            setApproved(true);
        }
    }

    useEffect(() => {
        if (walletConnected && polygonProvider && polygonDeploymentContext) {
            fetchApproval();
        }
    }, [totalPrice, selectedAccount, polygonProvider, polygonDeploymentContext]);

    const LeftClicked = () => {
        var newId = chestId - 1;
        while (newId < 0) {
            newId += chestsData.length;
        }
        setChestId(newId);
    };

    const FetchSupply = async () => {
        let providerObj = polygonProvider.connection;
        let deploymentContextObj = polygonDeploymentContext;
        const saleContract = await createNetworkContract(providerObj, deploymentContextObj, saleContractName);

        const skus = await Promise.all(Object.values(chestsData).map(x => saleContract.methods.getSkuInfo(fromAscii(x.skuKey)).call()));
        setSupply(skus.map(x => parseInt(x.totalSupply * 0.5)));  // since only selling 50% now
        setChestQtyMaxLimit(skus.map(x => parseInt(x.maxQuantityPerPurchase)));

        // Query tokenHolder address from sale contract, then get remaining balance by checking how much of each chest the tokenHolder address has
        const tokenHolder = await saleContract.methods.tokenHolder().call();
        const holderRemaining = await Promise.all(Object.values(chestsData).map(x => {
            return createNetworkContract(providerObj, deploymentContextObj, x.contractName).then(skuContract => {
                return skuContract.methods.balanceOf(tokenHolder).call();
            });
        }));

        setRemaining(holderRemaining.map(x => parseInt(fromWei(x))));
        setHasFetched(true);
    }

    const RightClicked = () => {
        var newId = chestId + 1;
        while (newId >= chestsData.length) {
            newId -= chestsData.length;
        }
        setChestId(newId);
    };

    const HandleChestSelected = (newId) => {
        setChestId(newId);
    }

    const HandleChestQuantitySelected = (newQuantity) => {
        setChestQuantity(newQuantity.target.value);
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?A-Za-z]+/;

        if (newQuantity.target.value > chestQtyMaxLimit[chestId]) {
            setChestQtyError(`No. of chests cannot exceed more than ${chestQtyMaxLimit[chestId]}`);
        } else if (format.test(newQuantity.target.value) || newQuantity.target.value <= 0) {
            setChestQtyError('Amount must be a positive integer greater than 0')
        } else {
            setChestQtyError('');
        }
    }

    const HandleApproveClicked = async () => {
        setApprovePending(true);
        await connect({connector: 'metamask'});

        let switchNetworkResponse = await switchNetwork(PolygonNetworkInfo);
        if (!switchNetworkResponse) {
            setApprovePending(false);
            return;
        }

        let providerObj = polygonProvider.connection;
        let deploymentContextObj = polygonDeploymentContext;

        const saleContract = await createNetworkContract(providerObj, deploymentContextObj, saleContractName);

        let web3M = await Web3Environment.get(process.env.REACT_APP_MATIC_PROVIDER_URL, process.env.REACT_APP_MATIC_DEPLOYMENT_CONTEXT_URL);

        let towerContractWeb3 = await web3M.getContract(towerContractName, web3);
        const allowance = await towerContractWeb3.methods.allowance(selectedAccount, saleContract.options.address).call();

        let gasPrice = await web3.eth.getGasPrice();
        let gasPriceBN = toBN(gasPrice);

        if (toBN(allowance).lt(toBN(toWei(totalPrice.toString())))) {
            let gasEstimate = await towerContractWeb3.methods
                .approve(saleContract.options.address, toWei(totalPrice.toString()))
                .estimateGas({from: selectedAccount});
            let finalGasPrice = gasPriceBN.add(toBN(parseInt(0.1 * gasPriceBN))).toString();
            let estimatedPriorityFee = await estimatePriorityFee(web3);
            let finalMaxFeePerGas = toBN(estimatedPriorityFee).add(toBN(parseInt(0.1 * gasPriceBN))).toString();

            await towerContractWeb3.methods.approve(saleContract.options.address, toWei(totalPrice.toString()))
                .send({
                    from: selectedAccount,
                    gasLimit: gasEstimate,
                    gasPrice: finalGasPrice,
                    maxFeePerGas: finalMaxFeePerGas,
                    maxPriorityFeePerGas: estimatedPriorityFee
                })
                .on('transactionHash', txHash => {
                    console.log(`txHash: ${txHash}`);
                })
                .on('receipt', (receipt) => {
                    const transactionHash = receipt.transactionHash;
                    console.log(`Approval finished with receipt.transactionHash : ${transactionHash}`);
                    setEtherscanTxUrl(`${process.env.REACT_APP_POLYGON_BLOCK_EXPLORER_URL}tx/${transactionHash}`);
                    setShowApproveSuccessModal(true);
                    setApproved(true);
                })
                .on('error', error => {
                    console.log(error);
                    console.error(`Error caught during approval transaction: ${error.message}`);
                    if (error.code === 4001) {
                        setShowCancelModal(true);
                    } else {
                        setShowErrorModal(true);
                    }
                });
        } else {
            setApproved(true);
        }

        setApprovePending(false);
        return;
    }

    const HandleBuyClicked = async () => {
        if (buyPending || !isStarted)
        {
            return;
        }
        setBuyPending(true);

        let switchNetworkResponse = await switchNetwork(PolygonNetworkInfo);
        if (!switchNetworkResponse) {
            setApprovePending(false);
            return;
        }

        await connect();

        let providerObj = polygonProvider.connection;
        let deploymentContextObj = polygonDeploymentContext;

        const saleContract = await createNetworkContract(providerObj, deploymentContextObj, saleContractName);
        const sku = fromAscii(chestsData[chestId].skuKey);

        setHasFetched(false);
        // Query tokenHolder address from sale contract, then get remaining balance by checking how much of each chest the tokenHolder address has
        const tokenHolder = await saleContract.methods.tokenHolder().call();
        const holderRemaining = await Promise.all(Object.values(chestsData).map(x => {
            return createNetworkContract(providerObj, deploymentContextObj, x.contractName).then(skuContract => {
                return skuContract.methods.balanceOf(tokenHolder).call();
            });
        }));
        setRemaining(holderRemaining.map(x => parseInt(fromWei(x))));
        setHasFetched(true);

        if (parseInt(fromWei(holderRemaining[chestId])) < chestQuantity) {
            setShowOutOfStockModal(true);
            setBuyPending(false);
            return;
        }

        const txPromise = new Promise((resolve, reject) => {
            web3.eth.sendTransaction({
                from: selectedAccount,
                to: saleContract.options.address,
                data: saleContract.methods
                    .purchaseFor(
                        selectedAccount,
                        towerAddr[CHAIN_ID],
                        sku,
                        chestQuantity.toString(),
                        "0x"
                    ).encodeABI(),
            })
            .on('transactionHash', (txHash) => {
                console.log(`Transaction hash obtained : ${txHash}`);
            })
            .on('receipt', (receipt) => {
                resolve(receipt);
            })
            .on('error', e => {
                reject(e);
            })
        });

        await txPromise.then((receipt) => {
            const transactionHash = receipt.transactionHash;
            console.log(`Transaction completed with receipt.transactionHash : ${transactionHash}`);
            dispatch({
                type: TYPES.SET_UPDATE_TOKEN,
                payload: state.updateToken + 1,
            });
            dispatch({
                type: TYPES.SET_POLYGON_TOKEN_BALANCE,
                payload: parseFloat(state.tokenBalance) - totalPrice,
            })

            setEtherscanTxUrl(`${process.env.REACT_APP_POLYGON_BLOCK_EXPLORER_URL}tx/${transactionHash}`);
            setShowPurchaseSuccessModal(true);
        }).catch((e) => {
            console.error(`Transaction failed: ${e.message}`);
            if (e.code === 4001) {
                setShowCancelModal(true);
            } else {
                setShowErrorModal(true);
            }
        }).finally(() => {
            setBuyPending(false);
            fetchApproval();
        });
    }

    const HandleConnectClicked = async () => {
        try {
            await connect({connector: "metamask"});
        } catch (e)
        {
            console.log(e);
        }
    }

    const HandleGetMetamaskClicked = () => {
        window.open(metamaskInstallUrl, "_blank");
    }

    const HandleGetTowerClicked = () => {
        history.replace('/buy-tower/matic', {hero: true});
    }

    return <div className='ChestSaleContainer'>
        <Container className='ChestSale' id='chest_sale'>
        <div className='LeftBox'>
            <Image className='ArrowBtn' src={iconLeft} onClick={ LeftClicked }/>
            <Carousel className="ChestCarousel" controls={false} activeIndex={chestId} indicators={false} fade>
                <Carousel.Item>
                    <Image className='ChestImg' src={chestsData[0].img} />
                </Carousel.Item>
                <Carousel.Item>
                    <Image className='ChestImg' src={chestsData[1].img} />
                </Carousel.Item>
                <Carousel.Item>
                    <Image className='ChestImg' src={chestsData[2].img} />
                </Carousel.Item>
            </Carousel>

            <Image className='ArrowBtn' src={iconRight} onClick={ RightClicked }/>
        </div>
        <div className='RightBox'>
            <div className='TopSection'>
                <ToggleButtonGroup className="ChestSelector" name="ChestSelector" value={chestId} onChange={HandleChestSelected}>
                    <ToggleButton variant='cdh-new' value={0}>{t(chestsData[0].rarity)}</ToggleButton>
                    <ToggleButton variant='cdh-new' value={1}>{t(chestsData[1].rarity)}</ToggleButton>
                    <ToggleButton variant='cdh-new' value={2}>{t(chestsData[2].rarity)}</ToggleButton>
                </ToggleButtonGroup>
                <div className='ChestInfoHeader'>
                    <label className='ChestTitle'>{t(chestsData[chestId].title)}</label>
                    { hasFetched
                        ? <div className='ChestStock' hidden={isPaused && soldOut}>
                            <label className='Remaining'>{remaining[chestId]}</label>
                            <label className='Supply'>/{supply[chestId]}</label>
                        </div>
                    : <>
                        <img src={loader} alt="loader" style={{width: "32px", height: "32px"}} />
                    </>
                    }
                </div>
                <p className='ChestDescription'>
                    {t(chestsData[chestId].description)} (<a href={dropRates} target="_blank">{t('MORE_DETAILS')}</a>)
                </p>
                <div className='QuantitySelector' hidden={soldOut}>
                    <div className='quantitySection'>
                        <label>{t('QUANTITY')}</label>
                        <div className='QuantityInput'>
                            <input name="QuantityInput" type="number" value={chestQuantity} onChange={HandleChestQuantitySelected}
                                onKeyDown={(evt) =>
                                    (evt.key === "e" || evt.key === "E" || evt.key === ".") &&
                                        evt.preventDefault()
                                    }
                            />
                        </div>
                    </div>
                    {setChestQtyError && <label className="chest_input_error">{chestQtyError}</label>}
                </div>
                <div className='TotalPriceBox' hidden={soldOut}>
                    <label>{t('TOTAL_PRICE')}</label>
                    <div className='PriceHolder'>
                        <Image className='TowerIcon' src={towerLogo} />
                        <label className='Price'>{totalPrice}</label>
                    </div>
                </div>
            </div>
            { noMetamask
                ? <Button className='GetMetamaskButton' variant='cdh-general-blue' onClick={HandleGetMetamaskClicked}>{t('GET_METAMASK').toUpperCase()}</Button>
            : !walletConnected
                ? <Button className='ConnectButton' variant='cdh-general-blue' onClick={HandleConnectClicked}>{t('CONNECT_METAMASK').toUpperCase()}</Button>
            : notEnoughTower
                ? <div className='NotEnoughTowerSection'>
                    <label className='NotEnoughTowerLabel' hidden={!notEnoughTower}>{t('NOT_ENOUGH_TOWER')}</label>
                    <a href="https://quickswap.exchange/#/swap?outputCurrency=0x2bc07124d8dac638e290f401046ad584546bc47b"
                            target="_blank" rel="noopener noreferrer"
                        >
                        <Button className='GetTowerButton' block variant='cdh-general-blue'
                            // onClick={HandleGetTowerClicked}
                        >
                            {t('GET_TOWER').toUpperCase()}
                        </Button>
                    </a>
                </div>
            : !approved
                ? <Button className='ApproveButton' variant='cdh-general-blue' onClick={HandleApproveClicked} disabled={approvePending || chestQtyError}>{approvePending ? <> <Image className='LoadingWheel' src={loadingWheel}/> {t('APPROVING').toUpperCase()} </> : t('APPROVE').toUpperCase()}</Button>
            : !isStarted
                ? <Button className='NotStartedButton' variant='cdh-general-blue' disabled>{t('ON_SALE_SOON').toUpperCase()}</Button>
            : isPaused
                ? <Button className='PausedButton' variant='cdh-general-blue' disabled>{t('SALE_PAUSED').toUpperCase()}</Button>
            : soldOut
                ? <Button className='SoldOutButton' variant='cdh-general-blue' disabled>{t('SOLD_OUT').toUpperCase()}</Button>
            : <Button className='BuyButton' variant='cdh-general-blue' onClick={HandleBuyClicked} disabled={buyDisabled || buyPending || !isStarted || isPaused || chestQtyError}>{buyPending ? <> <Image className='LoadingWheel' src={loadingWheel}/> {t('PENDING').toUpperCase()} </> : t('BUY').toUpperCase()}</Button>
            }
        </div>
    </Container>

        <GeneralModal content={{
                show: showPurchaseSuccessModal,
                showSetter: setShowPurchaseSuccessModal,
                title: t('TRANSACTION_SUCCESSFUL'),
                description: t('PURCHASE_SUCCESSFUL'),
                buttonText: t('GO_TO_INVENTORY'),
                buttonCallback: () => history.push('/inventory/chests'),
                polygonscanAddress: etherscanTxUrl
            }} />

        <GeneralModal content={{
                show: showApproveSuccessModal,
                showSetter: setShowApproveSuccessModal,
                title: t('TRANSACTION_SUCCESSFUL'),
                description: t('APPROVE_TO_SPEND_TOWER'),
                buttonText: t('OK'),
                buttonCallback: () => setShowApproveSuccessModal(false),
                polygonscanAddress: etherscanTxUrl
            }} />

        <GeneralModal content={{
                show: showErrorModal,
                showSetter: setShowErrorModal,
                title: t('ERROR_OCCURRED'),
                description: t('ERROR_OCCURRED') + ". " + t('TRY_AGAIN') + ".",
                buttonText: t('OK'),
                buttonCallback: () => setShowErrorModal(false)
            }} />

        <GeneralModal content={{
                show: showCancelModal,
                showSetter: setShowCancelModal,
                title: '',
                description: t('TRANSACTION_CANCEL') + ". " + t('TRY_AGAIN') + ".",
                buttonText: t('OK'),
                buttonCallback: () => setShowCancelModal(false)
            }} />

            <GeneralModal content={{
                show: showOutOfStockModal,
                showSetter: setShowOutOfStockModal,
                title: t('ERROR_OCCURRED'),
                description: t('CHEST_OUT_OF_STOCK') + " " + t('REMAINING_CHESTS') + " " + remaining[chestId],
                buttonText: t('OK'),
                buttonCallback: () => setShowOutOfStockModal(false)
            }} />
    </div>
}

export { ChestSale }
