const API_URL = process.env.REACT_APP_CDH_BACKEND_API_URL;
const PROXY_API_URL = process.env.REACT_APP_CDH_BACKEND_PROXY_API_URL;
const GAME_APP_ID = process.env.REACT_APP_CDH_APP_ID;

export async function getRemoteValues(isInChina) {
    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    try {
        const url = apiUrl + `/tower_remote_config`;
        const options = {
            method: 'GET',
            mode: 'cors',
        };
        const response = await fetch(url, options)
        const obj = await response.json();
        return obj;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}

export async function getCdhUserDetails(cdhUserId, isInChina) {
    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    try {
        const url = apiUrl + `/user/public/user_simple`;
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-APP-ID': GAME_APP_ID,
                'X-USER-ID': cdhUserId
            }
        };
        const response = await fetch(url, options)
        const obj = await response.json();
        return obj;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}

export async function verifySignInWithApple(idToken, isInChina) {
    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    try {
        const url = apiUrl + `/apple/callback`;
        const options = {
            method: 'POST',
            mode: 'cors',
            body: idToken
        };
        const response = await fetch(url, options)
        const obj = await response.json();
        return obj;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}

export async function getCdhUserIdFromSiwaAuth(authId, isInChina) {
    var apiUrl = isInChina ? PROXY_API_URL : API_URL;
    try {
        const url = apiUrl + `/apple/get_nonfirebase_auth_web`;
        const options = {
            method: 'POST',
            mode: 'cors',
            body: authId
        };
        const response = await fetch(url, options)
        const obj = await response.json();
        console.log(obj);
        return obj;
    } catch (err) {
        console.error('Error fetching data: ' + err)
    }
}
