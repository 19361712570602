import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Tutorial.module.scss';

const imageUrls = {
    "1": require('./img/howtobuy-01.png'),
    "2": require('./img/howtobuy-02.png'),
    "3": require('./img/howtobuy-03.png'),
    "4": require('./img/howtobuy-04.png'),
    "5": require('./img/howtobuy-05.png')
}

function Tutorial({id, title, text, children}) {
    const [t] = useTranslation();

    return <div className={styles.tutorial_step}>
        <span className={styles.step_num}>{id}</span>
        <div className={styles.img_container}><img src={imageUrls[id]} /></div>
        <h4>{t(title)}</h4>
        <p>{t(text)}</p>
        <div className={styles.align_bottom}>{children}</div>
    </div>
}

export { Tutorial };