import React from 'react';
import styles from './TowerXBaycPage.module.scss';
import StepPlayImage from '../../assets/imgs/bayc/step_play.png';
import StepVerifyImage from '../../assets/imgs/bayc/step_verify.png';
import StepHoldNftsImage from '../../assets/imgs/bayc/step_hold_nfts.png';
import StepEarnHeroPointsImage from '../../assets/imgs/bayc/step_earn_hero_points.png';
import AppleStoreBtn from '../../assets/imgs/btn_apple_store_new.png';
import GoogleStoreBtn from '../../assets/imgs/btn_google_play_new.png';
import BinanceIcon from '../../assets/icons/icon_binance_white.png';
import OpenSeaIcon from '../../assets/icons/icon_opensea_white.png';
import BoardApeKissing from '../../assets/imgs/bayc/boredape_kissing.png';
import BoardApeBG from '../../assets/imgs/bayc/boreadape_bg_transparent.png';
import HeroesBG from '../../assets/imgs/bayc/heroes_bg_transparent.png';

const cdhGpLink = "https://go.onelink.me/bUzl/9c836b67";
const cdhAppStoreLink = "https://go.onelink.me/VHLW/e01c1c3f";
const battleCardsOpenSeaLink = "https://opensea.io/collection/tower-battle-cards";
const binanceNFTLink = "https://www.binance.com/en/nft/search-result?isVerified=1&assetType=1&keyword=TOWER+Ticket&tab=nft&order=amount_sort%401";
const blockpassVerifyURL = process.env.REACT_APP_BLOCKPASS_VERIFICATION_URL;

const PointsEarningStepsDetails = [
    {
        stepNumber: '1',
        title: 'Play',
        description: <>Play Crazy Defense Hero Mobile Game - <b>Halloween Invasion</b> to earn TOWER Hero Points. If you holding a specific amount of TOWER or NFTs, you can get extra Hero Points!</>,
        image: StepPlayImage,
        buttons: <div className={styles.step_one_app_links}>
            <a href={cdhAppStoreLink} target="_blank" rel="noopener noreferrer">
                <img src={AppleStoreBtn} alt="apple-app-store" />
            </a>
            <a href={cdhGpLink} target="_blank" rel="noopener noreferrer">
                <img src={GoogleStoreBtn} alt="google-app-store" />
            </a>
        </div>
    },
    {
        stepNumber: '2',
        title: 'Verify',
        description: <>Complete <b>Player Verification</b> to verify your game ID’s wallet address on this website.</>,
        image: StepVerifyImage,
        buttons: <div className={styles.step_two_btns}>
            <a href={blockpassVerifyURL}  target="_blank" rel="noopener noreferrer">
                <button className={styles.verification_button}>Start Verification</button>
            </a>
        </div>
    },
    {
        stepNumber: '3',
        title: 'Hold NFTs',
        description: <>You need to <b>hold TOWER Battle Card / Tickets NFTs</b> in your wallet.</>,
        image: StepHoldNftsImage,
        buttons: <div className={styles.step_three_nft_market_links}>
            <a href={battleCardsOpenSeaLink}  target="_blank" rel="noopener noreferrer">
                <button className={styles.market_link_button}>
                    <img src={OpenSeaIcon} alt="opensea" />
                    <span>Buy Cards</span>
                </button>
            </a>
            <a href={binanceNFTLink}  target="_blank" rel="noopener noreferrer">
                <button className={styles.market_link_button}>
                    <img src={BinanceIcon} alt="binance" />
                    <span>Buy Tickets</span>
                </button>
            </a>
        </div>
    },
    {
        stepNumber: '4',
        title: 'Earn Hero Points!',
        description: 'After completed the above steps, you can earn Hero Points and join TOWER Leauge to redeem valuable rewards and enjoy exclusive benefits!',
        image: StepEarnHeroPointsImage,
        buttons: <div className={styles.step_four_btns}>
            {/* <a href={''}  target="_blank" rel="noopener noreferrer"> */}
                <button className={`${styles.verification_button} ${styles.button_disabled}`}>TOWER League</button>
            {/* </a> */}
            <div className={styles.coming_soon_text}>{'(Coming Soon)'}</div>
        </div>
    },
]

const PointsEarningSteps = () => {

    return (
        <div className={styles.points_earning_steps}>
            <img src={BoardApeBG} alt="bored-ape-bg" className={styles.bored_ape_bg} />
            <img src={HeroesBG} alt="heroes-bg" className={styles.heroes_bg} />

            <div className={styles.points_earning_steps_title}>
                <img src={BoardApeKissing} alt="boardape" />
                <span className={styles.title_text}>Hero Points Earning Steps</span>
            </div>

            <div className={styles.points_earning_steps_descriptions}>
                <div>Sign in with your Crazy Defense Heroes mobile game and completed to following steps to enter CDH x BAYC Event! </div>
                <div>Check your progress on the above section!</div>
            </div>

            <div className={styles.earning_steps}>
                {PointsEarningStepsDetails.map((step) => (
                    <div key={step.stepNumber} className={styles.earning_step}>
                        <div className={styles.step_header}>
                            <div className={styles.steps_down_arrow}></div>
                            <div className={styles.steps_right_arrow}></div>

                            <div className={styles.step_numbering}>
                                <div className={styles.step_number}>{step.stepNumber}</div>
                                <div className={styles.step_title}>{step.title}</div>
                            </div>
                        </div>

                        <div className={styles.step_contents}>
                            <div className={styles.step_image}>
                                <img src={step.image} alt="step-play" />
                            </div>

                            <div className={styles.step_title}>{step.title}</div>
                            <div className={styles.step_description}>{step.description}</div>
                            {step.buttons}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export { PointsEarningSteps };
