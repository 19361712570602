import React, { useState, useRef, useContext, useEffect } from 'react';
import { Card, Overlay, Tooltip } from 'react-bootstrap';
import styles from './CardItem.module.scss';
import polygon from '../../assets/icons/polygon-matic-logo-purple-bg-24x24-1x.png';
import openSea from '../../assets/icons/icon-opensea-simple.svg';
import dots from '../../assets/icons/Group.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import facebookIcon from '../../assets/icons/facebook1.png';
import telegramIcon from '../../assets/icons/telegramIcon.svg';
import twitterIcon from '../../assets/icons/twitterIcon.svg';
import sharelinkIcon from '../../assets/icons/sharelinkIcon.svg';
import transfer from '../../assets/icons/transfer_horiz_24px.svg';
import { useHistory } from 'react-router-dom';
import { IpContext, ABIContext } from '../../components/Context';
import { GeneralModal } from '../GeneralModal';
import { useTranslation } from 'react-i18next';

const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;
const POLYGON_URL = process.env.REACT_APP_POLYGON_BLOCK_EXPLORER_URL

const copyToClipboard = (link) => {
  navigator.clipboard.writeText(link)
}

const CardItem = ({ itemDetails, setTransferModal, id, setSelectedTransferCard }) => {
  const [open, setOpen] = useState(false);
  const [socialShareLinks, setSocialShareLinks] = useState([]);
  const history = useHistory();
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef(null)
  const {isInChina} = useContext(IpContext);
  const [abiConfig] = useContext(ABIContext);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [t] = useTranslation()

  const getSocialLinks = (inventoryAddress, item) => {
    return [
      {
        icon: facebookIcon,
        name: 'Facebook',
        link: 'https://www.facebook.com/sharer/sharer.php?u=' +  OPENSEA_URL + inventoryAddress + "/" + item.id,
        disabled: isInChina
      },
      {
        icon: telegramIcon,
        name: 'Telegram',
        link: 'https://telegram.me/share/url?url=' + OPENSEA_URL + inventoryAddress + "/" + item.id,
        disabled: isInChina
      },
      {
        icon: twitterIcon,
        name: 'Twitter',
        link: 'https://twitter.com/intent/tweet?url=' + OPENSEA_URL + inventoryAddress + "/" + item.id,
        disabled: isInChina
      },
      {
        icon: sharelinkIcon,
        name: 'Copy Link',
        copyLink: OPENSEA_URL + inventoryAddress + "/" + item.id,
        disabled: isInChina
      },
    ];
  }

  useEffect(() => {
    if (abiConfig.polygonDeploymentContext?.contracts) {
      let socialLinksResult = getSocialLinks(abiConfig.polygonDeploymentContext.contracts['CDHInventory'].address, itemDetails);
      setSocialShareLinks([...socialLinksResult]);
    }
  }, [abiConfig.polygonDeploymentContext])

  const handleOptionOpen = () => {
    setOpen(true);
  };

  const handleTooltip = (link) => {
    if (window.location.protocol !== "https:") {
      setShowMessageModal(true);
    } else {
      setShowTooltip(true);
      copyToClipboard(link?.toLowerCase())
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500)
    }
    
  }

  const closeMessageModal = () => {
    setShowMessageModal(false);
  }

  return (
    <Card className={styles.card}>
      <Card.Header className={styles.cardHeader}>
        <div className={styles.headerLeft}>
          <span className={styles.polyIcon}>
            <a href={POLYGON_URL + 'token/' + abiConfig.polygonDeploymentContext.contracts['CDHInventory'].address.toLowerCase() + "?a=" + id } target={'_blank'} rel="noopener noreferrer">
              <img src={polygon} alt='polygon' />
            </a>
          </span>
          <span>
            <a href={OPENSEA_URL + abiConfig.polygonDeploymentContext.contracts['CDHInventory'].address.toLowerCase() + "/" + id} target='_blank' rel="noopener noreferrer">
              <img src={openSea} alt='opensea' />
            </a>
          </span>
        </div>
        <div className={styles.headerRight}>
          <span onClick={handleOptionOpen}>
            <img src={dots} alt='' />
          </span>
        </div>
      </Card.Header>
      <div className={styles.cardImg}>
        <Card.Img
          src={itemDetails.image}
          onClick={() => history.push(`/inventory/cards/${itemDetails.id}`)}
        ></Card.Img>
      </div>
      <div className={styles.cardBody}>
        <h3 className={styles.title}>{itemDetails.name}</h3>
      </div>
      <div className={`${styles.overlay} ${open && styles.open}`}>
        <span className={styles.closeBtn} onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <h3 className={styles.title}>{itemDetails.name}</h3>
        <ul className={styles.shareLinks}>
          {socialShareLinks.map((item, idx) => {
            if (!item.disabled && !item.copyLink) {
              return (
                <li key={idx}>
                  <a href={item.link?.toLowerCase()} target="_blank"><img src={item.icon} alt='' />{item.name}</a>
                </li>
              );
            } else if (!item.disabled) {
              return (
                <li key={idx}>
                  <span className={styles.copyToClipboard} ref={tooltipTarget} onClick={() => handleTooltip(item?.copyLink)}><img src={item.icon} alt='' />{item.name}</span>
                    <Overlay target={tooltipTarget.current} show={showTooltip} placement="top">
                      {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                          Copied
                        </Tooltip>
                      )}
                    </Overlay>
                </li>
              );
            }
          })}
        </ul>
        <button className={styles.transferBtn} onClick={() => {
              setTransferModal(true);
              setOpen(false);
              setSelectedTransferCard(id);
            }}
          >
            <img src={transfer} alt='' />
            Transfer
          </button>
      </div>
      <GeneralModal content={{
        show: showMessageModal,
        showSetter: setShowMessageModal,
        title: t('ERROR'),
        description: t('COPY_ERROR'),
        buttonText: t('OK'),
        buttonCallback: closeMessageModal
        }} 
      />
    </Card>
  );
};

export default CardItem;
