import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './discordBoard.module.scss';
import { useLocation } from 'react-router-dom';
import google_store_btn from '../../assets/imgs/btn_google_play_new.png';
import apple_store_btn from '../../assets/imgs/btn_apple_store_new.png';
// import qr_cdh from '../../assets/imgs/qr-with-cdh-img.svg';

import QROthers from '../../assets/imgs/QR codes/TOWER Website - Others - main - 23 Dec 2022.svg';
import QRCoinbaseOthers from '../../assets/imgs/QR codes/TOWER Website - others - coinbase - 23 Dec 2022.svg';

const CoinbaseCampaignName = process.env.REACT_APP_COINBASE_CAMPAIGN_NAME;

const cdhGpLink = "https://go.onelink.me/bUzl/9c836b67";
const cdhAppStoreLink = "https://go.onelink.me/VHLW/e01c1c3f";

export const DiscordBoard = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [hideBoard, setHideBoard] = useState(false);
    const [screenLoc, setScreenLoc] = useState(window.scrollY);

    const getScrollPercentage = (element) => {
        let parent = element.parentNode;
        return Math.ceil((element.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight ) * 100);
    }

    const updateScrollLoc = () => {
        setScreenLoc(window.scrollY);
    }

    window.addEventListener('scroll', updateScrollLoc);

    useEffect(() => {
        let scrollPercentage = getScrollPercentage(window.document.body) || 0;
        if ((location.pathname === "/" && scrollPercentage < 4)) {
            setHideBoard(true);
        } else {
            setHideBoard(false);
        }
    }, [screenLoc, location])

    return (
        <>
            {!hideBoard &&
                <div className={styles.discord_container}>
                    <div className={styles.content}>
                        <div className={styles.title}>{t('SCAN_TO_PLAY')}</div>
                        <div className={styles.store_container}>
                            <img src={CoinbaseCampaignName === window.location.host.split(".")[0] ? QRCoinbaseOthers : QROthers} alt="app-store-qr" className={styles.app_qr} />
                            <div className={styles.store_btns}>
                                <a href={cdhAppStoreLink} target="_blank" rel="noopener noreferrer">
                                    <img src={apple_store_btn} alt="apple-app-store" className={styles.app_store_btn} />
                                </a>
                                <a href={cdhGpLink} target="_blank" rel="noopener noreferrer">
                                    <img src={google_store_btn} alt="google-app-store" className={styles.app_store_btn} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
