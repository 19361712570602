import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import useDebounce from '../useDebounce';
import { Token, TokenAmount, TradeType, Route, Trade, Fetcher, WETH, Percent, ETHER, Pair } from '@uniswap/sdk';
import { Web3Provider } from '@ethersproject/providers';
import { Web3Context } from '../../web3';
import { TokenContext, TYPES } from '../Context';
import { toWei } from 'web3-utils';
import iconEth from '../../assets/icons/icon-eth.svg';
import iconTower from '../../assets/icons/icon-tower.png';
import loadingWheel from '../../assets/imgs/loading_wheel.gif';
import { ReactComponent as Arrow} from '../../assets/icons/icon-arrow-down.svg';
import { useTranslation, Trans } from 'react-i18next';
import './SwapWidget.scss';
import { Web3Environment } from 'ethenv';
import { GeneralModal } from '../../components';
import iconUSDC from '../../assets/icons/icon-usdc.svg';

const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID);
const tokens = {
    usdc: {
        icon: iconUSDC,
        name: "USDC",
        symbol: "USDC",
        decimals: 6,
        4: "0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b",
        1: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
    },
    eth: {
        icon: iconEth,
        name: "ETH",
        symbol: "ETH"
    },
    tower: {
        symbol: "TOWER",
        name: "TOWER",
        decimals: 18,
        4: "0x8329B2Dab2AD937d69327F6868aFa39420e9482b",
        1: "0x1C9922314ED1415c95b9FD453c3818fd41867d0B"
    }
}
const tower = new Token(CHAIN_ID, tokens["tower"][CHAIN_ID], 18, "TOWER", "TOWER");
const usdc = new Token(CHAIN_ID, tokens["usdc"][CHAIN_ID], 6, "USDC", "USDC");
const PROVIDER_URL = process.env.REACT_APP_PROVIDER_URL;
const ROUTER_URL = process.env.REACT_APP_ROUTER_CONTRACT_URL;
const metamaskInstallUrl = "https://metamask.app.link/dapp/crazydefenseheroes.com/";

export function Swap() {
    const [showSwapSuccessModal, setShowSwapSuccessModal] = useState(false);
    const [selected, setSelected] = useState('usdc')
    const [amount, setAmount] = useState('');
    const [estimated, setEstimated] = useState('0.0');
    const [validated, setValidated] = useState(null);
    const [rate, setRate] = useState('- -');
    const [impact, setImpact] = useState(0);
    const { getWeb3Manager, connect, ready, selectedAccount, web3 } = useContext(Web3Context);
    const [state, dispatch] = useContext(TokenContext);
    const fromRef = useRef(null);
    const towerWeth = useRef(null);
    const usdcWeth = useRef(null);
    const [txPending, setTxPending] = useState(false);
    const debouncedAmount = useDebounce(amount, 500);
    const currentRoute = useRef(null);
    const currentTrade = useRef(null);
    const [t] = useTranslation();

    useEffect(() => {
        getPairInfo()
    }, [])

    useEffect(() => {
        getPairInfo();
        setAmount('');
        setEstimated('0.0');
    }, [selected])

    useEffect(() => {
        if(towerWeth.current) {
            try {
                handleUpdate(debouncedAmount, selected)
            } catch(e) {
                setValidated(false)
                setEstimated(0)
                console.error(e)
            }
        }
    }, [debouncedAmount])

    const handleUpdate = async (val, selected) => {
        updateRate(selected)

        if(val && val > 0) {
            handleEstimate(val, selected);
        }
    }

    const getPairInfo = async () => {
        const prov = window.web3 && new Web3Provider(window.web3.currentProvider)

        if (selected === 'usdc') {
            try {
                [towerWeth.current] = await Promise.all([
                    Fetcher.fetchPairData(tower, WETH[CHAIN_ID], prov)
                ]);
    
                [usdcWeth.current] = await Promise.all([
                    Fetcher.fetchPairData(usdc, WETH[CHAIN_ID], prov)
                ]);
    
                let route;
                if(towerWeth.current) {   
                    route = new Route([usdcWeth.current, towerWeth.current], usdc);
                    setRate(route.midPrice.toSignificant(6))
                    currentRoute.current = route;
                }
            } catch(e) {
                console.error(e)
            }
        } else {
            try {
                [towerWeth.current] = await Promise.all([
                    Fetcher.fetchPairData(tower, WETH[CHAIN_ID], prov)
                ]);
    
                let route;
                if(towerWeth.current) {   
                    route = new Route([towerWeth.current], WETH[CHAIN_ID]);
                    setRate(route.midPrice.toSignificant(6))
                    currentRoute.current = route;
                }
            } catch(e) {
                console.error(e)
            }
        }
    }

    const updateRate = async (token) => {
        setRate(currentRoute.current.midPrice.toSignificant(6))
    }

    const handleEstimate = async (val, token) => {
        let amountIn;
        if (selected === 'usdc') {
            amountIn = (val * 10 ** 6).toString();
        } else {
            amountIn = toWei(val);
        }
        let trade;
        if (selected === 'usdc') {
            trade = new Trade(currentRoute.current, new TokenAmount(usdc, amountIn), TradeType.EXACT_INPUT);
            setImpact(trade.priceImpact.toFixed(2));
            setEstimated(trade.outputAmount.toSignificant(6));
        } else {
            console.log(currentRoute.current, new TokenAmount(WETH[CHAIN_ID], amountIn), TradeType.EXACT_INPUT)
            trade = new Trade(currentRoute.current, new TokenAmount(WETH[CHAIN_ID], amountIn), TradeType.EXACT_INPUT)
            setImpact(trade.priceImpact.toFixed(2))
            setEstimated(trade.outputAmount.toSignificant(6))
        }

        currentTrade.current = trade;
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if(fromRef && fromRef.current.value > 0) {
            setTxPending(true)
            exchangeToken(fromRef.current.value).then(_ => {
                setShowSwapSuccessModal(true);
                // Force a refresh of the token amount in TokenContext on successful swap
                dispatch({
                    type: TYPES.SET_CHEST_COUNT_MATIC,
                    payload: state.updateToken + 1,
                });
            }).catch(e => {
                setValidated(false)
                setTxPending(false)
                console.error(e)
            }).finally(_ => {
                setTxPending(false)
            })
        }
    }

    const exchangeToken = async (val) => {
        let tradeData = currentTrade.current;
        await connect({ connector: "metamask" });
        const manager = await getWeb3Manager();
        const routerEnvironment = await Web3Environment.get(PROVIDER_URL, ROUTER_URL);
        const contract = await routerEnvironment.getContract("Router", web3);

        const slippageTolerance = new Percent('50', '10000')
        const amountOutMin = tradeData.minimumAmountOut(slippageTolerance).raw.toString()
        const _amountIn = tradeData.maximumAmountIn(slippageTolerance).raw.toString()
        const to = selectedAccount
        const deadline = Math.floor(Date.now() / 1000) + 60 * 10 // 10 minutes
        const value = tradeData.inputAmount.raw.toString()

        if(selected !== "eth") {
            const path = [tokens[selected][CHAIN_ID], WETH[CHAIN_ID].address, tower.address]
            // approve
            const tokenContract = await manager.getContract("USDC", web3);

            const approve = await web3.eth.sendTransaction({
                    from: selectedAccount,
                    to : tokenContract._address,
                    data : tokenContract.methods.approve(contract.options.address, _amountIn).encodeABI()
                });

            if(!approve) {
                setValidated(false)
                return;
            }
            const res = await contract.methods.swapExactTokensForTokens(_amountIn, amountOutMin, path, to, deadline, 0)
                .send({ from: to })

            setAmount('')
            setValidated(null)
        } else {
            const path = [WETH[CHAIN_ID].address, tower.address]

            const res = await contract.methods.swapExactETHForTokens(amountOutMin, path, to, deadline, 0)
                .send({ from: to, value })

            setAmount('')
            setValidated(null)
        }   
    }

    const handleSelectCurrency = (token) => {
        setSelected(token);
    }

    const handleChange = () => {
        setAmount(fromRef.current.value)
    }

    const handleFocus = () => {
        if(parseInt(fromRef.current.value) === 0) {
            setAmount("")
        }
        setValidated(null);
    }

    const handleConnect = () => {
        connect({ connector: "metamask" }) 
    }

    return <div id="sale-box">
        <div className="sale-header">
            <div className="header1">{t('SWAPPING')}</div>
            <div className="header2">{t('USE_ETH_TO_SWAP')}</div>
        </div>
            <div className="sale-body">
            <Form className="uniswap" validated={validated} onSubmit={handleSubmit}>
                <div className="to-token">
                    <div className="token-field">
                        <span className="from-to">{t('FROM')}</span>
                        <Form.Control 
                            type="number"
                            min="0.0"
                            step=".0000001"
                            placeholder="0.0"
                            required
                            value={amount}
                            ref={fromRef}
                            onChange={handleChange} 
                            onFocus={handleFocus} />
                    </div>
                    <Dropdown onSelect={handleSelectCurrency}>
                        <Dropdown.Toggle variant="token" className="dropdown-item"><img src={tokens[selected].icon} /> <span>{selected.toUpperCase()}</span></Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.keys(tokens).filter(x => x !== "tower").map(key => {
                                return <Dropdown.Item key={key} eventKey={key} active={selected === key} className="dropdown-item"><img src={tokens[key].icon} /> <span>{tokens[key].name}</span></Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div id="down" >
                    <Arrow/>
                </div>
                <div className="from-token">
                    <div className="token-field">
                        <span className="from-to">{t('TO')}</span>
                        <Form.Control type="number" placeholder="0" value={estimated} readOnly />
                    </div>
                    <div className="tower-icon"><img src={iconTower} width="30" height="30" /> <span>{t('TOWER')}</span></div>
                </div>
                <div className="disclaimer">{('PRICE')} {rate} TOWER per {tokens[selected].name}</div>
                {validated === false && <span className="err">{t('ERROR_OCCURRED') + '. ' + t('TRY_AGAIN') + '.'}</span>}
                <div className="btns-container">
                    {(ready && selectedAccount) ?
                        <Button variant="f1" type="submit" disabled={validated === false || parseFloat(amount) <= 0 || txPending || impact >= 15}>
                            {txPending ? <img src={loadingWheel} width="25" height="25" /> : <></>}
                            {
                                impact && impact >= 15
                                ? <b>{t('PRICE_IMPACT_TOO_HIGH')}</b>
                                : <b>{t('SWAP')}</b>
                            }
                        </Button>
                        :
                            typeof window.ethereum === 'undefined' ?
                                <Button variant="f1" onClick={()=>window.open(metamaskInstallUrl, "_blank")}>{t("GET_METAMASK")}</Button>
                            :
                                <Button variant="f1" onClick={handleConnect}>{t("CONNECT_METAMASK")}</Button>
                    }
                </div>
            </Form>
            <div className="footer">
                <div className="add_liquidity">
                    <a href="https://app.sushi.com/add/0x1C9922314ED1415c95b9FD453c3818fd41867d0B/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" target="_blank">{t("ADD_LIQUIDITY")}</a>
                </div>
                <div className="uniswap_footer">
                    <Trans i18nKey="Powered by Sushiswap">
                        {t('POWERED_BY_SUSHISWAP')}
                    </Trans>
                </div>
            </div>
        </div>
        <GeneralModal content={{
                show: showSwapSuccessModal,
                showSetter: setShowSwapSuccessModal,
                title: t('TRANSACTION_SUCCESSFUL'),
                description: t('TRANSACTION_SUCCESSFUL'),
                buttonText: t('OK'),
                buttonCallback: () => setShowSwapSuccessModal(false)
            }} />
    </div>
}
