import React, {useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import i18n from '../../i18n/i18n';

import {Modal, ListGroup, Button, ListGroupItem} from 'react-bootstrap';
import GlobeIcon from '../../assets/icons/icon-language.svg';

import './Language.scss';

function LanguageSwitcher(props) {
    const { callback } = props.content || {};
    const {t} = useTranslation();
    const [cookies, setCookie] = useCookies(["i18next"]);

    const [showModal, setShowModal] = useState(false)
    const [languages, setLanguages] = useState([
        {
            label: 'عربى',
            code: 'ar',
        },
		{
			label: '繁體中文',
			code: 'zh-Hant',
		},
		{
			label: '简体中文',
			code: 'zh-Hans',
		},
        {
            label: 'Duits',
            code: 'nl',
        },
		{
			label: 'ENGLISH',
			code: 'en',
		},
        {
            label: 'Français',
            code: 'fr',
        },
        {
            label: 'Bahasa Indonesia',
            code: 'id',
        },
        {
            label: 'Italiano',
            code: 'it',
        },
		{
			label: '한국인',
			code: 'kr',
		},
        {
            label: 'മലേഷ്യൻ',
            code: 'ms',
        },
        {
            label: 'Português',
            code: 'ptbr',
        },
        {
            label: 'русский',
            code: 'ru',
        },
        {
            label: 'Español',
            code: 'es',
        },
        {
            label: 'ไทย',
            code: 'th',
        },
		{
			label: 'TÜRKÇE',
			code: 'tr',
		},
        {
			label: 'Tiếng Việt',
			code: 'vi',
		},
    ].sort(x => x.label));

    const [currLang, setCurrLang] = useState({
        label: 'ENGLISH',
        code:'en'
    })

    useEffect(() => {
        let language = i18n.language
		if(language == "zh-TW" || language == "zh-HK") {
			language = "zh-Hant";
		}

		if(language == "zh-SG" || language == "zh-CN") {
			language = "zh-Hans";
		}
        
        let cachedLang = languages.slice(0).filter(x => x.code === language)
        if (cachedLang && cachedLang.length) {
            setCurrLang(cachedLang[0])
        }
    }, [])

    const handleButtonClick = () => {
        callback()
        setShowModal(true)
    }
    
    const handleSetLanguage = (lang) => {
        setShowModal(false)
        i18n.changeLanguage(lang.code)

        setCurrLang(lang);
        setCookie("i18next", lang.code, {maxAge: 86400})
    }

    return (
        <>
        <p className="curr-lang" onClick={handleButtonClick}><img src={ GlobeIcon }></img> {currLang.label}</p>
        
        <Modal show={showModal} id="lang-switch" onHide={() => setShowModal(false)} centered>
            <Modal.Body>
                <h4 className="select-lang">{t('Select Language')}</h4>
                <ListGroup variant="flush" style={{ height: "300px", overflow: "auto" }}>
                    {languages.map(lang => { return (
					<ListGroupItem action as="div" active={currLang.code === lang.code} 
						onClick={() => handleSetLanguage(lang)} key={lang.code}>
						{lang.label}
						{currLang.code === lang.code ? <img src={ GlobeIcon }></img> : <></>}
					</ListGroupItem>)})}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cdh-general-blue" className={"cancel_button"} onClick={() => setShowModal(false)}>{t('CANCEL')}</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export {LanguageSwitcher};