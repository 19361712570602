import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import './CrateCard.scss';
import chestRare from './img/comingsoon-rare.jpg';
import chestEpic from './img/comingsoon-epic.jpg';
import chestLegend from './img/comingsoon-legend.jpg';

const imageUrls = {
    "6,000": chestRare,
    "15,120": chestEpic,
    "32,400": chestLegend
}

function CrateCard({description, gem, vip, limit, price}) {
    const [t] = useTranslation();

    return <div className={`crate_card ${gem === "32,400" ? 'legend' : ''}`}>
        <div className='top'>
            <div className='img_container'>
                <img src={imageUrls[gem]} />
            </div>
            <Button variant="cdh-primary" size="sm" className="limits">{t(limit)}</Button>
            <p>{t(description)}</p>
            <p>{gem} {t('Gems')}</p>
            <p>{vip} {t('VIP Points')}</p>
        </div>
        <Button variant="cdh-primary" className='bottom_btn' block>{t(price)}</Button>
    </div>
}

export { CrateCard };