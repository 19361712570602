import React, {useEffect, useState} from "react";
import {retryOnTimeout} from "./retryOnTimeout";

const DEFAULT_GEO_IP_URL = 'https://geoip.animocabrands.com/json';
const DEFAULT_BLOCKED_ISOS = ['RU', 'BY'];

/**
 * @param {{
 *  ComponentAllowed: JSX.ElementClass,
 *  ComponentBlocked: JSX.ElementClass,
 *  errorHandler: Function?,
 * }} props
 */
function GeoIPChecker({ComponentAllowed, ComponentBlocked, errorHandler = null}) {
    const [isBlocked, setIsBlocked] = useState(false);
    const [response, setResponse] = useState();

    useEffect(() => {
        const abortController = new AbortController();
        const abortSignal = abortController.signal;

        (async () => {
            try {
                const url = process.env.REACT_APP_GEO_IP_URL || DEFAULT_GEO_IP_URL;
                const blockedISOs = process.env.REACT_APP_BLOCKED_ISOS?.split(",") || DEFAULT_BLOCKED_ISOS;
                const res = await retryOnTimeout(() => fetch(url), {abortSignal});
                const body = await res.json();
                setResponse(body);
                setIsBlocked(blockedISOs.includes(body.country_iso.toUpperCase()));
            } catch (e) {
                if (errorHandler) {
                    errorHandler(e);
                }
            }
        })();

        return () => abortController.abort();
    }, []);

    const result = isBlocked ? <ComponentBlocked geoIpResponse={response}/> : <ComponentAllowed geoIpResponse={response}/>
    return result || <></>;
}

export {GeoIPChecker}
