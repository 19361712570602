
const baseURL = process.env.REACT_APP_CARDS_API_BASE_URL
const metadataURL = process.env.REACT_APP_CARDS_METADATA_URL;

export async function getCardsForWalletAddress(walletAddress) {
  console.log("walletaddress",walletAddress)
  let result = await fetch(`${baseURL}/${walletAddress}`)
  //let result = await fetch(`https://wallet-stg.f1deltatime.com/wallet/${walletAddress}`)
  let obj = await result.json();
  return Object.keys(obj);
}


export async function getMetadataForTokenId(tokenId) {
    let url = `${metadataURL}/${tokenId}`;
    //let url = `https://nft-test.f1deltatime.com/json/${tokenId}`;
   
  
    const response = await fetch(url)
  
    if (response.status != 200) {
      throw 'error';
    }
    const obj = await response.json();
  
    return obj;
}